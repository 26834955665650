/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AdInsightEntityType } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingGenerateCreditNoteRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingGetEshopCreditRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingGetEshopCreditResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingGetInvoicePdfUrlRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingGetInvoicePdfUrlResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingGetInvoicesRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingGetInvoicesResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingRegenerateInvoiceRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingSetAsCanceledRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAccountingSetAsPaidRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsAddAdminUserPermissionRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsAddAdminUserRoleRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsGetAssignableRolesRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsGetAssignableRolesResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsGetInternalUserRolesResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsGetSubscriptionsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsRemoveAdminUserPermissionRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsRemoveAdminUserRoleRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsSubscribeRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAdminsUnsubscribeRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAuthEshopAccessTokenRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAuthForgottenPasswordRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAuthGetAccessTokenResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAuthGetEshopAccessTokenResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAuthGetPermissionsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAuthLoginRequest } from '../TANGANICA';
// @ts-ignore
import { AdminAuthLoginResponse } from '../TANGANICA';
// @ts-ignore
import { AdminAuthRegisterRequest } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetFeedErrorsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetFeedProcessingInfoRequest } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetFeedProcessingInfoResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetGclidsCsvResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetInvoiceXmlResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetInvoiceZipResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetInvoicesCsvResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetProductsDataRequest } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetProductsDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetProductsDotnetIssuesRequest } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetProductsDotnetIssuesResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetProductsGmcIssuesRequest } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetProductsGmcIssuesResponse } from '../TANGANICA';
// @ts-ignore
import { AdminDTOGetSubaccountsInfoResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailAddAdminTransactionRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailArchiveEshopRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetActiveCampaignFormatsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetActiveCampaignFormatsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetAssetsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetAssetsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetBillingSettingsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetBillingSettingsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetContributionPercentageRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetContributionPercentageResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetCreatedCampaignsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetCreatedCampaignsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetCreditTopupHistoryRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetCreditTopupHistoryResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetDailyContributionStatisticsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetDailyContributionStatisticsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetEshopDetailRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetEshopDetailResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetFeedLogsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetFeedLogsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetFeedUrlRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetFeedUrlResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetGraphDataRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetProductCountRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetProductCountResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetProductsStateRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailGetProductsStateResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailProcessEshopFeedRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailSetContributionPercentageRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailSetFeedUrlRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailSetProductsStateRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailUpdateEshopProductsAdminInfoRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailUpdateEshopProductsStatusRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsDetailUploadEshopProductsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsGetEshopCampaignsStatisticsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsGetEshopCampaignsStatisticsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsGetEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminEshopsGetEshopsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminGoogleCampaignsGetAllCustomerSubAccountsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminGoogleCampaignsGetCampaignsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminGoogleCampaignsGoogleCampaignCreatedRequest } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetActivatedEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetActivatedEshopsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetAdExpensesGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetContributionsGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetContributionsToAdExpensesGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetEshopsWithAdspendGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetGeneratedRevenueGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetGraphDataRequest } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetKpiReportRequest } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetKpiReportResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetMonthlyCampaignStatsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetMonthlyCampaignStatsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetMonthlyReportRequest } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetMonthlyReportResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetNumberOfOrdersGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetRegisteredEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetRegisteredEshopsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminKpiReportGetTotalExpensesGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { AdminLogsGetLogsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminLogsGetLogsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminLogsGetMailLogsRequest } from '../TANGANICA';
// @ts-ignore
import { AdminLogsGetMailLogsResponse } from '../TANGANICA';
// @ts-ignore
import { AdminTopMenuGetActiveEshopsCountResponse } from '../TANGANICA';
// @ts-ignore
import { AdminTopMenuGetEshopsCreditResponse } from '../TANGANICA';
// @ts-ignore
import { AdminUserDetailGetUserAffilInfoRequest } from '../TANGANICA';
// @ts-ignore
import { AdminUserDetailGetUserAffilInfoResponse } from '../TANGANICA';
// @ts-ignore
import { AdminUserDetailGetUserDetailRequest } from '../TANGANICA';
// @ts-ignore
import { AdminUserDetailGetUserDetailResponse } from '../TANGANICA';
// @ts-ignore
import { AdminUserDetailLogoutUserRequest } from '../TANGANICA';
// @ts-ignore
import { AdminUserDetailSetUserAffilRequest } from '../TANGANICA';
// @ts-ignore
import { AdminUsersGetUsersRequest } from '../TANGANICA';
// @ts-ignore
import { AdminUsersGetUsersResponse } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementAd } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementAdAdditionalField } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementAdGroup } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementAdGroupAdditionalField } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementAdType } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementAssetGroup } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementCampaign } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementCampaignAdditionalField } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementCampaignCriterion } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementCampaignCriterionType } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementCampaignType } from '../TANGANICA';
// @ts-ignore
import { CampaignManagementCriterionAdditionalField } from '../TANGANICA';
// @ts-ignore
import { ClientAuthEshopAccessTokenRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthForgottenPasswordRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthGetAccessTokenResponse } from '../TANGANICA';
// @ts-ignore
import { ClientAuthGetEshopAccessTokenResponse } from '../TANGANICA';
// @ts-ignore
import { ClientAuthGetFacebookOAuthUrlRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthGetFacebookOAuthUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientAuthGetGoogleOAuthUrlRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthGetGoogleOAuthUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientAuthLoginRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthLoginResponse } from '../TANGANICA';
// @ts-ignore
import { ClientAuthLogoutRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthRegisterInvitedRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthRegisterInvitedResponse } from '../TANGANICA';
// @ts-ignore
import { ClientAuthRegisterRequest } from '../TANGANICA';
// @ts-ignore
import { ClientAuthRegisterResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCallbacksConfirmEmailChangeRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCallbacksExternalAuthCallbackRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCallbacksExternalAuthCallbackResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCallbacksResetPasswordRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsCheckAuthCodeForBusinessPermissionsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsCheckFBPageLinkResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsDeleteAssetsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsGetAssetsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsGetCampaignStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsGetDailyBudgetResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsGetFBBusinessOauthUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsGetFBLinkedPageInfoResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsGetFBPagesOauthUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsGetStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsSetDailyBudgetRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsSetStatusRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsSetStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsShareFBPageWithTanganicaRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsShareFBPageWithTanganicaResponse } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsUploadAssetsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientCampaignsUploadAssetsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientControllersGetHourlyCostMicrosRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOBankTransferPaymentRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOBankTransferPaymentResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOCreateCheckoutRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOCreateCheckoutResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOCreateSetupRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateBlogContentCtrategyRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateBlogContentCtrategyResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateBlogContentIdeaRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateBlogContentIdeaResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateBlogContentRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateBlogContentResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateEmailContentStrategyRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateEmailContentStrategyResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateNewsletterIdeaRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateNewsletterIdeaResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateNewsletterRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateNewsletterResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentHeadlineRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentHeadlineResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentIdeaRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentIdeaResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentStrategyRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateSocialMediaContentStrategyResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateTextAssetsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGenerateTextAssetsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetAffilContributionAvailableResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetAffilContributionForAllOwnedEshopsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetAffilGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetAffilInfoResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetAffiliateContributionTotalResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetAutomaticCreditRechargeOptionsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetAutomaticCreditRechargeStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCampaignStatsGraphDataRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCampaignStatsGraphDataResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCampaignStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCampaignStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCategoriesDataResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCategoriesResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCompanyInfoForAffilInvoiceResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetCreditResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetEshopTaxRateResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetFeedStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetGmvTrendChartDataRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetGmvTrendChartDataResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetOrderStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetOrderStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetPaymentDefaultAmountResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductCategoryStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductCategoryStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductIssuesRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductIssuesResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetProductsSummaryResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetSessionStatusRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetSessionStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetTrafficStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetTrafficStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetUserFeedLogsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetVisitSourceStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOGetVisitSourceStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOHasSavedCardResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOOptimizeBlogContentRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOOptimizeBlogContentResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDTOSetAutomaticCreditRechargeOptionsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOSetAutomaticCreditRechargeStatusRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOSetCategoryAdvertisementRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOSetMultipleProductsAdvertisementStatusRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDTOSetProductAdvertisementStatusRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardFixFeedUrlRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetActiveCampaignFormatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetFeedStateResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetLastFeedErrorResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetLearningPhaseOrdersResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetPixelMeasuringConditionResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetProductsConditionResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetProductsIssuesSummaryRequest } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetProductsIssuesSummaryResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetRemainingTrialPeriodResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetSetupStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetStatisticsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetSuccessfullyMeasuredCustomersPercentageResponse } from '../TANGANICA';
// @ts-ignore
import { ClientDashboardGetTgncaContributionPercentageResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopListGetEshopResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopListGetEshopsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetCosMaxResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetCreditNotificationLimitResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetEshopDescriptionResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetFeedStateResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetNotificationEmailResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetPixelStatusResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetProductFeedUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsGetUsersWithAccessResponse } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsInviteUserToEshopRequest } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsRemoveUserFromEshopRequest } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsSetCosMaxRequest } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsSetCreditNotificationLimitRequest } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsSetNotificationEmailRequest } from '../TANGANICA';
// @ts-ignore
import { ClientEshopSettingsSetProductFeedUrlRequest } from '../TANGANICA';
// @ts-ignore
import { ClientPaymentHistoryGetBillingSettingsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientPaymentHistoryGetInvoicePdfUrlRequest } from '../TANGANICA';
// @ts-ignore
import { ClientPaymentHistoryGetInvoicePdfUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientPaymentHistoryGetPaymentHistoryResponse } from '../TANGANICA';
// @ts-ignore
import { ClientPaymentHistorySetBillingSettingsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportCampaignStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportCampaignStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportOrderStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportOrderStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportProductCategoryStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportProductCategoryStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportProductStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportProductStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportTrafficStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportTrafficStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportVisitSourceStatsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientReportExportVisitSourceStatsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientSurveySendContactFormRequest } from '../TANGANICA';
// @ts-ignore
import { ClientUserSettingsChangeEmailRequest } from '../TANGANICA';
// @ts-ignore
import { ClientUserSettingsChangePasswordRequest } from '../TANGANICA';
// @ts-ignore
import { ClientUserSettingsGetNameResponse } from '../TANGANICA';
// @ts-ignore
import { ClientUserSettingsGetPhoneNumberResponse } from '../TANGANICA';
// @ts-ignore
import { ClientUserSettingsLocaleRequest } from '../TANGANICA';
// @ts-ignore
import { ClientUserSettingsSetNameRequest } from '../TANGANICA';
// @ts-ignore
import { ClientUserSettingsSetPhoneNumberRequest } from '../TANGANICA';
// @ts-ignore
import { ClientWizardAddEshopRequest } from '../TANGANICA';
// @ts-ignore
import { ClientWizardAddEshopResponse } from '../TANGANICA';
// @ts-ignore
import { ClientWizardCheckPixelResponse } from '../TANGANICA';
// @ts-ignore
import { ClientWizardGetBillingSettingsResponse } from '../TANGANICA';
// @ts-ignore
import { ClientWizardGetEshopResponse } from '../TANGANICA';
// @ts-ignore
import { ClientWizardGetEshopUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientWizardGetProductFeedUrlResponse } from '../TANGANICA';
// @ts-ignore
import { ClientWizardGetRemainingTrialPeriodResponse } from '../TANGANICA';
// @ts-ignore
import { ClientWizardSetBillingSettingsRequest } from '../TANGANICA';
// @ts-ignore
import { ClientWizardSetProductFeedUrlRequest } from '../TANGANICA';
// @ts-ignore
import { ClientWizardUpdateUserRequest } from '../TANGANICA';
// @ts-ignore
import { CronCronFillDemoDataRequest } from '../TANGANICA';
// @ts-ignore
import { CustomerManagementAccountAdditionalField } from '../TANGANICA';
// @ts-ignore
import { EnumsCountryOfAdvertisement } from '../TANGANICA';
// @ts-ignore
import { EnumsMarketingFormat } from '../TANGANICA';
// @ts-ignore
import { EshopTanganicaProductsDTOGetAllProductsRequest } from '../TANGANICA';
// @ts-ignore
import { EshopTanganicaProductsDTOGetAllProductsResponse } from '../TANGANICA';
// @ts-ignore
import { EshopTanganicaProductsDTOGetProductDetailRequest } from '../TANGANICA';
// @ts-ignore
import { EshopTanganicaProductsDTOGetProductDetailResponse } from '../TANGANICA';
// @ts-ignore
import { GlobalModelsErrorResponse } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOChangeOrderContributionRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOCurrencyChangeBackRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTODeleteOrderRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOProcessFeedsForGivenEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOProcessFeedsForManyGivenEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOUploadProductsForGivenEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { MaintenanceDTOUploadProductsForManyGivenEshopsRequest } from '../TANGANICA';
// @ts-ignore
import { ReportingCampaignPerformanceReportRequest } from '../TANGANICA';
// @ts-ignore
import { TestListResponseListResponse } from '../TANGANICA';
// @ts-ignore
import { TestPTestDTOLogCountsRequest } from '../TANGANICA';
// @ts-ignore
import { TestPTestDTOUtcDateValidationRequest } from '../TANGANICA';
/**
 * TANGANICAApi - axios parameter creator
 * @export
 */
export const TANGANICAApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a credit note for final invoice
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingGenerateCreditNoteRequest} [adminAccountingGenerateCreditNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGenerateCreditNote: async (adminAccountingGenerateCreditNoteRequest?: AdminAccountingGenerateCreditNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Accounting/GenerateCreditNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountingGenerateCreditNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets eshop\'s credit.
         * @summary Policies: RequireInternalRole
         * @param {AdminAccountingGetEshopCreditRequest} [adminAccountingGetEshopCreditRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGetEshopCredit: async (adminAccountingGetEshopCreditRequest?: AdminAccountingGetEshopCreditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Accounting/GetEshopCredit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountingGetEshopCreditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the presigned url to the invoice pdf stored in Spaces.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
         * @param {AdminAccountingGetInvoicePdfUrlRequest} [adminAccountingGetInvoicePdfUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGetInvoicePdfUrl: async (adminAccountingGetInvoicePdfUrlRequest?: AdminAccountingGetInvoicePdfUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Accounting/GetInvoicePdfUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountingGetInvoicePdfUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
         * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
         * @param {AdminAccountingGetInvoicesRequest} [adminAccountingGetInvoicesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGetInvoices: async (adminAccountingGetInvoicesRequest?: AdminAccountingGetInvoicesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Accounting/GetInvoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountingGetInvoicesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Regenerates the invoice with data from the database. Updates the regenerated invoice - changes Locale based on input and links latest BillingSetting
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingRegenerateInvoiceRequest} [adminAccountingRegenerateInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingRegenerateInvoice: async (adminAccountingRegenerateInvoiceRequest?: AdminAccountingRegenerateInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Accounting/RegenerateInvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountingRegenerateInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Canceles the invoice.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingSetAsCanceledRequest} [adminAccountingSetAsCanceledRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingSetAsCanceled: async (adminAccountingSetAsCanceledRequest?: AdminAccountingSetAsCanceledRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Accounting/SetAsCanceled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountingSetAsCanceledRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the ProformaInvoice or CreditNote status as paid.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingSetAsPaidRequest} [adminAccountingSetAsPaidRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingSetAsPaid: async (adminAccountingSetAsPaidRequest?: AdminAccountingSetAsPaidRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Accounting/SetAsPaid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAccountingSetAsPaidRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to directly add user permissions to other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
         * @param {AdminAdminsAddAdminUserPermissionRequest} [adminAdminsAddAdminUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsAddAdminUserPermission: async (adminAdminsAddAdminUserPermissionRequest?: AdminAdminsAddAdminUserPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/AddAdminUserPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminsAddAdminUserPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to  add user permissions to other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsAddAdminUserRoleRequest} [adminAdminsAddAdminUserRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsAddAdminUserRole: async (adminAdminsAddAdminUserRoleRequest?: AdminAdminsAddAdminUserRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/AddAdminUserRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminsAddAdminUserRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to get roles they are able to assign to given internal user.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsGetAssignableRolesRequest} [adminAdminsGetAssignableRolesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsGetAssignableRoles: async (adminAdminsGetAssignableRolesRequest?: AdminAdminsGetAssignableRolesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/GetAssignableRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminsGetAssignableRolesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all users with their roles.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsGetInternalUserRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/GetInternalUserRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail sending settings.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsGetSubscriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/GetSubscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove user permissions of other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
         * @param {AdminAdminsRemoveAdminUserPermissionRequest} [adminAdminsRemoveAdminUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsRemoveAdminUserPermission: async (adminAdminsRemoveAdminUserPermissionRequest?: AdminAdminsRemoveAdminUserPermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/RemoveAdminUserPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminsRemoveAdminUserPermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove roles of other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsRemoveAdminUserRoleRequest} [adminAdminsRemoveAdminUserRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsRemoveAdminUserRole: async (adminAdminsRemoveAdminUserRoleRequest?: AdminAdminsRemoveAdminUserRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/RemoveAdminUserRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminsRemoveAdminUserRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
         * @summary Policies: RequireInternalRole
         * @param {AdminAdminsSubscribeRequest} [adminAdminsSubscribeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsSubscribe: async (adminAdminsSubscribeRequest?: AdminAdminsSubscribeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/Subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminsSubscribeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
         * @summary Policies: RequireInternalRole
         * @param {AdminAdminsUnsubscribeRequest} [adminAdminsUnsubscribeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsUnsubscribe: async (adminAdminsUnsubscribeRequest?: AdminAdminsUnsubscribeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Admins/Unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAdminsUnsubscribeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
         * @param {AdminAuthForgottenPasswordRequest} [adminAuthForgottenPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthForgottenPassword: async (adminAuthForgottenPasswordRequest?: AdminAuthForgottenPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Auth/ForgottenPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAuthForgottenPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {AdminAuthEshopAccessTokenRequest} [adminAuthEshopAccessTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthGetEshopAccessToken: async (adminAuthEshopAccessTokenRequest?: AdminAuthEshopAccessTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Auth/GetEshopAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAuthEshopAccessTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint returns list of AdminPermissions current user has.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthGetPermissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Auth/GetPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthGetUserAccessToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Auth/GetUserAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for admin login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
         * @param {AdminAuthLoginRequest} [adminAuthLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthLogin: async (adminAuthLoginRequest?: AdminAuthLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Auth/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAuthLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Auth/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for registering new admin user. The new admin needs to re-log to obtain new access token.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal</li><li><b>Email</b>: Email of an already registered user who should be assigned with Internal role instead of Client role</li></ul>
         * @summary Policies: RequireInternalRole; AdminPermissions: RegisterNewAdmin
         * @param {AdminAuthRegisterRequest} [adminAuthRegisterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthRegister: async (adminAuthRegisterRequest?: AdminAuthRegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Auth/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminAuthRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
         * @summary Policies: RequireResetPasswordToken
         * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCallbacksResetPassword: async (clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Callbacks/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCallbacksResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is tanganica admins or developers to be able logout all users<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin, Internal</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientAuthLogoutAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/ClientAuth/LogoutAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his Email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>Email</b>: valid user Email</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientAuthLogoutUserByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('adminClientAuthLogoutUserByEmail', 'email', email)
            const localVarPath = `/v1/Admin/ClientAuth/LogoutUserByEmail/{Email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientAuthLogoutUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminClientAuthLogoutUserById', 'id', id)
            const localVarPath = `/v1/Admin/ClientAuth/LogoutUserById/{Id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Adds (or removes) given amount of credit to eshop. (Creates appropriate transaction)
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailAddAdminTransactionRequest} [adminEshopsDetailAddAdminTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailAddAdminTransaction: async (adminEshopsDetailAddAdminTransactionRequest?: AdminEshopsDetailAddAdminTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/AddAdminTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailAddAdminTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Sets Eshop.Archived as true.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailArchiveEshopRequest} [adminEshopsDetailArchiveEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailArchiveEshop: async (adminEshopsDetailArchiveEshopRequest?: AdminEshopsDetailArchiveEshopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/ArchiveEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailArchiveEshopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets currently active campaign formats
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetActiveCampaignFormatsRequest} [adminEshopsDetailGetActiveCampaignFormatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetActiveCampaignFormats: async (adminEshopsDetailGetActiveCampaignFormatsRequest?: AdminEshopsDetailGetActiveCampaignFormatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetActiveCampaignFormats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetActiveCampaignFormatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to get assets for {CampaignType} campaigns
         * @summary Policies: RequireInternalRole
         * @param {EnumsMarketingFormat} campaignType 
         * @param {AdminEshopsDetailGetAssetsRequest} [adminEshopsDetailGetAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetAssets: async (campaignType: EnumsMarketingFormat, adminEshopsDetailGetAssetsRequest?: AdminEshopsDetailGetAssetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('adminEshopDetailGetAssets', 'campaignType', campaignType)
            const localVarPath = `/v1/Admin/EshopDetail/GetAssets/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetAssetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets eshop billing info.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetBillingSettingsRequest} [adminEshopsDetailGetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetBillingSettings: async (adminEshopsDetailGetBillingSettingsRequest?: AdminEshopsDetailGetBillingSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetBillingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetBillingSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets all already created campaigns
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetCreatedCampaignsRequest} [adminEshopsDetailGetCreatedCampaignsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetCreatedCampaigns: async (adminEshopsDetailGetCreatedCampaignsRequest?: AdminEshopsDetailGetCreatedCampaignsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetCreatedCampaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetCreatedCampaignsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets history of all transactions where transaction type is not AdSpend or contribution
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetCreditTopupHistoryRequest} [adminEshopsDetailGetCreditTopupHistoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetCreditTopupHistory: async (adminEshopsDetailGetCreditTopupHistoryRequest?: AdminEshopsDetailGetCreditTopupHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetCreditTopupHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetCreditTopupHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets contribution transactions overview.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetDailyContributionStatisticsRequest} [adminEshopsDetailGetDailyContributionStatisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetDailyContributionStatistics: async (adminEshopsDetailGetDailyContributionStatisticsRequest?: AdminEshopsDetailGetDailyContributionStatisticsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetDailyContributionStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetDailyContributionStatisticsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetContributionPercentageRequest} [adminEshopsDetailGetContributionPercentageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetDefaultContributionPercentage: async (adminEshopsDetailGetContributionPercentageRequest?: AdminEshopsDetailGetContributionPercentageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetDefaultContributionPercentage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetContributionPercentageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets basic eshop info for the top part of admin eshop detail page.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetEshopDetailRequest} [adminEshopsDetailGetEshopDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetEshopDetail: async (adminEshopsDetailGetEshopDetailRequest?: AdminEshopsDetailGetEshopDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetEshopDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetEshopDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Get Feed processing logs.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetFeedLogsRequest} [adminEshopsDetailGetFeedLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetFeedLogs: async (adminEshopsDetailGetFeedLogsRequest?: AdminEshopsDetailGetFeedLogsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetFeedLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetFeedLogsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Get Feed url.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetFeedUrlRequest} [adminEshopsDetailGetFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetFeedUrl: async (adminEshopsDetailGetFeedUrlRequest?: AdminEshopsDetailGetFeedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetFeedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetFeedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets data for graphs on the EshopDetail page in admin
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetGraphDataRequest} [adminEshopsDetailGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetGraphData: async (adminEshopsDetailGetGraphDataRequest?: AdminEshopsDetailGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Get current number of products in database.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetProductCountRequest} [adminEshopsDetailGetProductCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetProductCount: async (adminEshopsDetailGetProductCountRequest?: AdminEshopsDetailGetProductCountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetProductCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetProductCountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the state of products advertisement for given platform
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetProductsStateRequest} [adminEshopsDetailGetProductsStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetProductsState: async (adminEshopsDetailGetProductsStateRequest?: AdminEshopsDetailGetProductsStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/GetProductsState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailGetProductsStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Process feed for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailProcessEshopFeedRequest} [adminEshopsDetailProcessEshopFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailProcessEshopFeed: async (adminEshopsDetailProcessEshopFeedRequest?: AdminEshopsDetailProcessEshopFeedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/ProcessEshopFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailProcessEshopFeedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Sets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetContributionPercentageRequest} [adminEshopsDetailSetContributionPercentageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailSetDefaultContributionPercentage: async (adminEshopsDetailSetContributionPercentageRequest?: AdminEshopsDetailSetContributionPercentageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/SetDefaultContributionPercentage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailSetContributionPercentageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Change Feed url.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetFeedUrlRequest} [adminEshopsDetailSetFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailSetFeedUrl: async (adminEshopsDetailSetFeedUrlRequest?: AdminEshopsDetailSetFeedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/SetFeedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailSetFeedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Sets the state of products advertisement for given platform
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetProductsStateRequest} [adminEshopsDetailSetProductsStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailSetProductsState: async (adminEshopsDetailSetProductsStateRequest?: AdminEshopsDetailSetProductsStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/SetProductsState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailSetProductsStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Update products info in admin table for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUpdateEshopProductsAdminInfoRequest} [adminEshopsDetailUpdateEshopProductsAdminInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailUpdateEshopProductsAdminInfo: async (adminEshopsDetailUpdateEshopProductsAdminInfoRequest?: AdminEshopsDetailUpdateEshopProductsAdminInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/UpdateEshopProductsAdminInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailUpdateEshopProductsAdminInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Update products status for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUpdateEshopProductsStatusRequest} [adminEshopsDetailUpdateEshopProductsStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailUpdateEshopProductsStatus: async (adminEshopsDetailUpdateEshopProductsStatusRequest?: AdminEshopsDetailUpdateEshopProductsStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/UpdateEshopProductsStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailUpdateEshopProductsStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Upload products for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUploadEshopProductsRequest} [adminEshopsDetailUploadEshopProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailUploadEshopProducts: async (adminEshopsDetailUploadEshopProductsRequest?: AdminEshopsDetailUploadEshopProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/EshopDetail/UploadEshopProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsDetailUploadEshopProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the data for the campaign statistics sub-table visible after expanding the eshop view in the main eshop table in admin.It is also used on eshop detail.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsGetEshopCampaignsStatisticsRequest} [adminEshopsGetEshopCampaignsStatisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopsGetEshopCampaignsStatistics: async (adminEshopsGetEshopCampaignsStatisticsRequest?: AdminEshopsGetEshopCampaignsStatisticsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Eshops/GetEshopCampaignsStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsGetEshopCampaignsStatisticsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the eshops
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsGetEshopsRequest} [adminEshopsGetEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopsGetEshops: async (adminEshopsGetEshopsRequest?: AdminEshopsGetEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Eshops/GetEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminEshopsGetEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Updates the database view from which data for admin eshops table are queried.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopsRefreshEshopsView: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Eshops/RefreshEshopsView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading a gclid report file
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetGclidsCsvLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Export/GetGclidsCsvLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading csv files with invoices 
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetInvoiceCsvLinks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Export/GetInvoiceCsvLinks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading xml files with invoices 
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetInvoiceXmlLinks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Export/GetInvoiceXmlLinks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading a zip with invoices
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetInvoiceZipLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Export/GetInvoiceZipLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the list of unsuccessful feeds.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeedsGetFeedErrors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Feeds/GetFeedErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the specified feed cron info.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetFeedProcessingInfoRequest} [adminDTOGetFeedProcessingInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeedsGetFeedProcessingInfo: async (adminDTOGetFeedProcessingInfoRequest?: AdminDTOGetFeedProcessingInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Feeds/GetFeedProcessingInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDTOGetFeedProcessingInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Endpoint for getting list of all googleCustomerSubAccounts in TGNCA s.r.o account
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGoogleCampaignsGetAllCustomerSubAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/GoogleCampaigns/GetAllCustomerSubAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view incomplete google campaigns that they need to create on google
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGoogleCampaignsGetCampaigns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/GoogleCampaigns/GetCampaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Endpoint serving as a trigger to match the external id / customerSubAccount id with an empty campaign in database - both single and multiple select
         * @summary Policies: RequireInternalRole; AdminPermissions: SetGoogleCampaigns
         * @param {AdminGoogleCampaignsGoogleCampaignCreatedRequest} [adminGoogleCampaignsGoogleCampaignCreatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGoogleCampaignsGoogleCampaignCreated: async (adminGoogleCampaignsGoogleCampaignCreatedRequest?: AdminGoogleCampaignsGoogleCampaignCreatedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/GoogleCampaigns/GoogleCampaignCreated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminGoogleCampaignsGoogleCampaignCreatedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets list of activated eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportActivatedEshops
         * @param {AdminKpiReportGetActivatedEshopsRequest} [adminKpiReportGetActivatedEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetActivatedEshops: async (adminKpiReportGetActivatedEshopsRequest?: AdminKpiReportGetActivatedEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetActivatedEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetActivatedEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for Advertising expenses graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetAdExpensesGraphData: async (adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetAdExpensesGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for Contributions graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetContributionsGraphData: async (adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetContributionsGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for Contributions/Advertising expenses graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetContributionsToAdExpensesGraphData: async (adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetContributionsToAdExpensesGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for Eshops with spending graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetEshopsWithAdspendGraphData: async (adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetEshopsWithAdspendGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for Turnover (GeneratedRevenue) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetGeneratedRevenueGraphData: async (adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetGeneratedRevenueGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets kpi report data for given time frame.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetKpiReportRequest} [adminKpiReportGetKpiReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetKpiReport: async (adminKpiReportGetKpiReportRequest?: AdminKpiReportGetKpiReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetKpiReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetKpiReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for the campaign table in monthly report section of Kpi report page.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetMonthlyCampaignStatsRequest} [adminKpiReportGetMonthlyCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetMonthlyCampaignStats: async (adminKpiReportGetMonthlyCampaignStatsRequest?: AdminKpiReportGetMonthlyCampaignStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetMonthlyCampaignStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetMonthlyCampaignStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for monthly report section of Kpi report page.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetMonthlyReportRequest} [adminKpiReportGetMonthlyReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetMonthlyReport: async (adminKpiReportGetMonthlyReportRequest?: AdminKpiReportGetMonthlyReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetMonthlyReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetMonthlyReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for Transactions (NumberOfOrders) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetNumberOfOrdersGraphData: async (adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetNumberOfOrdersGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets list of registered eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportRegisteredEshops
         * @param {AdminKpiReportGetRegisteredEshopsRequest} [adminKpiReportGetRegisteredEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetRegisteredEshops: async (adminKpiReportGetRegisteredEshopsRequest?: AdminKpiReportGetRegisteredEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetRegisteredEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetRegisteredEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for Accounting (TotalExpenses) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetTotalExpensesGraphData: async (adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/KpiReport/GetTotalExpensesGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminKpiReportGetGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the user logs
         * @summary Policies: RequireInternalRole
         * @param {AdminLogsGetLogsRequest} [adminLogsGetLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogsGetLogs: async (adminLogsGetLogsRequest?: AdminLogsGetLogsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Logs/GetLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLogsGetLogsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail logs
         * @summary Policies: RequireInternalRole
         * @param {AdminLogsGetMailLogsRequest} [adminLogsGetMailLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogsGetMailLogs: async (adminLogsGetMailLogsRequest?: AdminLogsGetMailLogsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Logs/GetMailLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminLogsGetMailLogsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the data from DB view made for Gmc products data and apply filtering, sorting and paging.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsDataRequest} [adminDTOGetProductsDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetProductsData: async (adminDTOGetProductsDataRequest?: AdminDTOGetProductsDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Products/GetProductsData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDTOGetProductsDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the DOTNET issues for given eshop.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsDotnetIssuesRequest} [adminDTOGetProductsDotnetIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetProductsDotnetIssues: async (adminDTOGetProductsDotnetIssuesRequest?: AdminDTOGetProductsDotnetIssuesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Products/GetProductsDotnetIssues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDTOGetProductsDotnetIssuesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the GMC issues for given eshop.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsGmcIssuesRequest} [adminDTOGetProductsGmcIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetProductsGmcIssues: async (adminDTOGetProductsGmcIssuesRequest?: AdminDTOGetProductsGmcIssuesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Products/GetProductsGmcIssues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDTOGetProductsGmcIssuesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the count and capacity notification of GMC subaccounts.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetSubaccountsInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Products/GetSubaccountsInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Updates the count of GMC subaccounts.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsUpdateGmcSubaccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Products/UpdateGmcSubaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint gets number of eshops with spend for czech and foreign eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuActiveEshopsCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTopMenuGetActiveEshopsCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/TopMenu/GetActiveEshopsCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint gets overall credit for czech and foreign eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuEshopsCredit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTopMenuGetEshopsCredit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/TopMenu/GetEshopsCredit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution - same as in the client app
         * @summary Policies: RequireInternalRole
         * @param {AdminUserDetailGetUserAffilInfoRequest} [adminUserDetailGetUserAffilInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailGetUserAffilInfo: async (adminUserDetailGetUserAffilInfoRequest?: AdminUserDetailGetUserAffilInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/UserDetail/GetUserAffilInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserDetailGetUserAffilInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets basic user info.
         * @summary Policies: RequireInternalRole
         * @param {AdminUserDetailGetUserDetailRequest} [adminUserDetailGetUserDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailGetUserDetail: async (adminUserDetailGetUserDetailRequest?: AdminUserDetailGetUserDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/UserDetail/GetUserDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserDetailGetUserDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID
         * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
         * @param {AdminUserDetailLogoutUserRequest} [adminUserDetailLogoutUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailLogoutUser: async (adminUserDetailLogoutUserRequest?: AdminUserDetailLogoutUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/UserDetail/LogoutUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserDetailLogoutUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Sets user as affiliate.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
         * @param {AdminUserDetailSetUserAffilRequest} [adminUserDetailSetUserAffilRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailSetUserAffil: async (adminUserDetailSetUserAffilRequest?: AdminUserDetailSetUserAffilRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/UserDetail/SetUserAffil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserDetailSetUserAffilRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
         * @summary Policies: RequireInternalRole
         * @param {AdminUsersGetUsersRequest} [adminUsersGetUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGetUsers: async (adminUsersGetUsersRequest?: AdminUsersGetUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Admin/Users/GetUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUsersGetUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> The user requests an affil program - email is sent to admins with users name and id
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportAskForAffil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/AffiliateReport/AskForAffil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the amount of affil contribution available for the eshop to withdraw
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffilContributionAvailable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/AffiliateReport/GetAffilContributionAvailable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffilGraphData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/AffiliateReport/GetAffilGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets all the necessary info about the affiliate program
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffilInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/AffiliateReport/GetAffilInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffiliateContributionTotal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/AffiliateReport/GetAffiliateContributionTotal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateTextAssetsRequest} [clientDTOGenerateTextAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAssetGenerateTextAssets: async (clientDTOGenerateTextAssetsRequest?: ClientDTOGenerateTextAssetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Asset/GenerateTextAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateTextAssetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout when his refresh token is expired
         * @param {ClientAuthLogoutRequest} [clientAuthLogoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthExpiredTokenLogout: async (clientAuthLogoutRequest?: ClientAuthLogoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/ExpiredTokenLogout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthLogoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
         * @param {ClientAuthForgottenPasswordRequest} [clientAuthForgottenPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthForgottenPassword: async (clientAuthForgottenPasswordRequest?: ClientAuthForgottenPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/ForgottenPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthForgottenPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {ClientAuthEshopAccessTokenRequest} [clientAuthEshopAccessTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetEshopAccessToken: async (clientAuthEshopAccessTokenRequest?: ClientAuthEshopAccessTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/GetEshopAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthEshopAccessTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint gets the facebook oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
         * @param {ClientAuthGetFacebookOAuthUrlRequest} [clientAuthGetFacebookOAuthUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetFacebookOAuthUrl: async (clientAuthGetFacebookOAuthUrlRequest?: ClientAuthGetFacebookOAuthUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/GetFacebookOAuthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthGetFacebookOAuthUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint gets the google oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
         * @param {ClientAuthGetGoogleOAuthUrlRequest} [clientAuthGetGoogleOAuthUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetGoogleOAuthUrl: async (clientAuthGetGoogleOAuthUrlRequest?: ClientAuthGetGoogleOAuthUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/GetGoogleOAuthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthGetGoogleOAuthUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetUserAccessToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/GetUserAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for standard user password login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
         * @param {ClientAuthLoginRequest} [clientAuthLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthLogin: async (clientAuthLoginRequest?: ClientAuthLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: UserAccessToken</li></ul>
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint creates new user with client account owner privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal AND Admin</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Email</b>: have to be unique</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
         * @param {ClientAuthRegisterRequest} [clientAuthRegisterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthRegister: async (clientAuthRegisterRequest?: ClientAuthRegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint creates new user with client account owner privileges and given eshop role privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: InviteToken</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
         * @summary Policies: RequireInviteToken
         * @param {ClientAuthRegisterInvitedRequest} [clientAuthRegisterInvitedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthRegisterInvited: async (clientAuthRegisterInvitedRequest?: ClientAuthRegisterInvitedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Auth/RegisterInvited`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientAuthRegisterInvitedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Is called after user clicks on the confirmation link in email. User is then redirected to login page (? TODO)
         * @summary Policies: RequireEmailChangeToken
         * @param {ClientCallbacksConfirmEmailChangeRequest} [clientCallbacksConfirmEmailChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksConfirmEmailChange: async (clientCallbacksConfirmEmailChangeRequest?: ClientCallbacksConfirmEmailChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Callbacks/ConfirmEmailChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCallbacksConfirmEmailChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Called by frontend, which passes through the parameters obtained by external auth provider <h3>Requirements</h3><ul><li><b>Authorization roles</b>: token obtained from state query parameter of redirectUri </li></ul>
         * @summary Policies: RequireOAuthStateToken
         * @param {ClientCallbacksExternalAuthCallbackRequest} [clientCallbacksExternalAuthCallbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksExternalAuthCallback: async (clientCallbacksExternalAuthCallbackRequest?: ClientCallbacksExternalAuthCallbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Callbacks/ExternalAuthCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCallbacksExternalAuthCallbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
         * @summary Policies: RequireResetPasswordToken
         * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksResetPassword: async (clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Callbacks/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCallbacksResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksStripePaymentWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Callbacks/StripePaymentWebhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchanges the auth code for access token and indicates whether it has correct business permissions (true/false)
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest} [clientCampaignsCheckAuthCodeForBusinessPermissionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsCheckAuthCodeForBusinessPermissions: async (clientCampaignsCheckAuthCodeForBusinessPermissionsRequest?: ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/CheckAuthCodeForBusinessPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCampaignsCheckAuthCodeForBusinessPermissionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if FB page is linked to Eshop in DB
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsCheckFBPageLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/CheckFBPageLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsDeleteAssetsRequest} [clientCampaignsDeleteAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsDeleteAssets: async (campaignType: EnumsMarketingFormat, clientCampaignsDeleteAssetsRequest?: ClientCampaignsDeleteAssetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsDeleteAssets', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/DeleteAssets/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCampaignsDeleteAssetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetAssets: async (campaignType: EnumsMarketingFormat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsGetAssets', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/GetAssets/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint gets the information if any campaign with given Format exists for current eshop.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetCampaignStatus: async (campaignType: EnumsMarketingFormat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsGetCampaignStatus', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/GetCampaignStatus/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint gets the value of daily budget as well as minimal budget information for provided marketing format campaign.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetDailyBudget: async (campaignType: EnumsMarketingFormat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsGetDailyBudget', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/GetDailyBudget/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetDashboardStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/GetDashboardStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a link to the FB business sharing interface
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetFBBusinessOauthUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/GetFBBusinessOauthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the name and link of the linked FB page
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetFBLinkedPageInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/GetFBLinkedPageInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a link to the FB page sharing interface
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetFBPagesOauthUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/GetFBPagesOauthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get status for provided {CampaignType} campaign. This does not reflect reality in marketing platform (Google/Facebook) but desired status of the user.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetStatus: async (campaignType: EnumsMarketingFormat, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsGetStatus', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/GetStatus/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint sets the value of daily budget for provided marketing format campaign. First it attempts to set the value using the corresponding platform API (e.g., Google Ads, Facebook Ads). If the API call fails, it throws an error and skip the change in database. In case of success, it updates the value inside the database and returns success.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsSetDailyBudgetRequest} [clientCampaignsSetDailyBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsSetDailyBudget: async (campaignType: EnumsMarketingFormat, clientCampaignsSetDailyBudgetRequest?: ClientCampaignsSetDailyBudgetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsSetDailyBudget', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/SetDailyBudget/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCampaignsSetDailyBudgetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint reverses the status of a marketing campaign in both the database and corresponding marketing platforms (e.g., Google Ads, Facebook Ads) based on the provided MarketingFormat. If the database operation fails, it throws an error and skips the API calls. If a platform API call switch fails, it still returns success and attempts the switch later based on status in the database.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsSetStatusRequest} [clientCampaignsSetStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsSetStatus: async (campaignType: EnumsMarketingFormat, clientCampaignsSetStatusRequest?: ClientCampaignsSetStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsSetStatus', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/SetStatus/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCampaignsSetStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds TGNCA as agency to the clients shared page and notifies admins via email
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientCampaignsShareFBPageWithTanganicaRequest} [clientCampaignsShareFBPageWithTanganicaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsShareFBPageWithTanganica: async (clientCampaignsShareFBPageWithTanganicaRequest?: ClientCampaignsShareFBPageWithTanganicaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/ShareFBPageWithTanganica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCampaignsShareFBPageWithTanganicaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint updates the Bing DSA ad text with updated assets.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsUpdateBingDSACampaign: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/UpdateBingDSACampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint updates the DPA campaign Ad Creatives with updated assets.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsUpdateDPACampaign: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Campaigns/UpdateDPACampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsUploadAssetsRequest} [clientCampaignsUploadAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsUploadAssets: async (campaignType: EnumsMarketingFormat, clientCampaignsUploadAssetsRequest?: ClientCampaignsUploadAssetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignType' is not null or undefined
            assertParamExists('clientCampaignsUploadAssets', 'campaignType', campaignType)
            const localVarPath = `/v1/Client/Campaigns/UploadAssets/{CampaignType}`
                .replace(`{${"CampaignType"}}`, encodeURIComponent(String(campaignType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCampaignsUploadAssetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentRequest} [clientDTOGenerateBlogContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateBlogContent: async (clientDTOGenerateBlogContentRequest?: ClientDTOGenerateBlogContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateBlogContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateBlogContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentIdeaRequest} [clientDTOGenerateBlogContentIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateBlogContentIdea: async (clientDTOGenerateBlogContentIdeaRequest?: ClientDTOGenerateBlogContentIdeaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateBlogContentIdea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateBlogContentIdeaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentCtrategyRequest} [clientDTOGenerateBlogContentCtrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateBlogContentStrategy: async (clientDTOGenerateBlogContentCtrategyRequest?: ClientDTOGenerateBlogContentCtrategyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateBlogContentStrategy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateBlogContentCtrategyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateEmailContentStrategyRequest} [clientDTOGenerateEmailContentStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateEmailContentStrategy: async (clientDTOGenerateEmailContentStrategyRequest?: ClientDTOGenerateEmailContentStrategyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateEmailContentStrategy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateEmailContentStrategyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateNewsletterRequest} [clientDTOGenerateNewsletterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateNewsletter: async (clientDTOGenerateNewsletterRequest?: ClientDTOGenerateNewsletterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateNewsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateNewsletterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateNewsletterIdeaRequest} [clientDTOGenerateNewsletterIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateNewsletterIdea: async (clientDTOGenerateNewsletterIdeaRequest?: ClientDTOGenerateNewsletterIdeaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateNewsletterIdea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateNewsletterIdeaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentRequest} [clientDTOGenerateSocialMediaContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContent: async (clientDTOGenerateSocialMediaContentRequest?: ClientDTOGenerateSocialMediaContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateSocialMediaContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateSocialMediaContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentHeadlineRequest} [clientDTOGenerateSocialMediaContentHeadlineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContentHeadline: async (clientDTOGenerateSocialMediaContentHeadlineRequest?: ClientDTOGenerateSocialMediaContentHeadlineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateSocialMediaContentHeadline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateSocialMediaContentHeadlineRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentIdeaRequest} [clientDTOGenerateSocialMediaContentIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContentIdea: async (clientDTOGenerateSocialMediaContentIdeaRequest?: ClientDTOGenerateSocialMediaContentIdeaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateSocialMediaContentIdea`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateSocialMediaContentIdeaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentStrategyRequest} [clientDTOGenerateSocialMediaContentStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContentStrategy: async (clientDTOGenerateSocialMediaContentStrategyRequest?: ClientDTOGenerateSocialMediaContentStrategyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/GenerateSocialMediaContentStrategy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGenerateSocialMediaContentStrategyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOOptimizeBlogContentRequest} [clientDTOOptimizeBlogContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentOptimizeBlogContent: async (clientDTOOptimizeBlogContentRequest?: ClientDTOOptimizeBlogContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Content/OptimizeBlogContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOOptimizeBlogContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends the feed processing request
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDashboardFixFeedUrlRequest} [clientDashboardFixFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardFixFeedUrl: async (clientDashboardFixFeedUrlRequest?: ClientDashboardFixFeedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/FixFeedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDashboardFixFeedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetActiveCampaignFormats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetActiveCampaignFormats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets last feed state
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetFeedState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetFeedState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the last feed processing error
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetLastFeedError: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetLastFeedError`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets number of learning phase orders. Learning phase orders are orders from all campaigns made in last 30 days.They are located in <b>Overview of your campaigns</b> section of <b>Dashboard</b> page
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetLearningPhaseOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetLearningPhaseOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if all steps of order measuring are set up
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetPixelMeasuringCondition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetPixelMeasuringCondition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the product condition from the DB for given eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetProductsConditionCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetProductsConditionCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the product issues summary given eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDashboardGetProductsIssuesSummaryRequest} [clientDashboardGetProductsIssuesSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetProductsIssuesSummary: async (clientDashboardGetProductsIssuesSummaryRequest?: ClientDashboardGetProductsIssuesSummaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetProductsIssuesSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDashboardGetProductsIssuesSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets remaining trial period for eshop with eshop ID provided in JWT
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetRemainingTrialPeriod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetRemainingTrialPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetSetupStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetSetupStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets campaign statistic data for last 30 days that are located in section basic statistics in dashboard.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the percentage of successfully measured customers of the eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetSuccessfullyMeasuredCustomersPercentage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetSuccessfullyMeasuredCustomersPercentage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current tanganica contribution percentage for the eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetTgncaContributionPercentage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Dashboard/GetTgncaContributionPercentage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Returns only one eshop with same data structure as in GetEshops above. Designed to be used for admin LoginAs functionality only.
         * @summary Policies: RequireInternalEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopListGetEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopList/GetEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Returns a overview of eshops the user has. Including his roles in the eshops. Designed to be used for active eshop selection.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopListGetEshops: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopList/GetEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsCreateEshopDescription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/CreateEshopDescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetCosMax: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetCosMax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetCreditNotificationLimit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetCreditNotificationLimit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetEshopDescription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetEshopDescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetFeedState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetFeedState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetNotificationEmail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetNotificationEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets pixel status of current eshop.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetPixelStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetPixelStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetProductFeedUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetProductFeedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetUsersWithAccess: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/GetUsersWithAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> If invited user is alread registered he is linked to given eshop with given role - (only notification email is sent (?) ).If the invited email is not in the database, mail is sent to the given email address with link containing special token. This link redirects user to FE for registration, where email input is already set from the token<h3>Requirements</h3><ul><li><b>redirectUrl</b>: url to redirect user to with InviteToken - the url is sent to user by email - form on this url will call the RegisterInvited endpoint. The provided url will be appended with JWT token as follows: \"{redirectUrl}?InviteToken={token}\"</li></ul>
         * @summary Policies: , RequireEshopAccessToken
         * @param {ClientEshopSettingsInviteUserToEshopRequest} [clientEshopSettingsInviteUserToEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsInviteUser: async (clientEshopSettingsInviteUserToEshopRequest?: ClientEshopSettingsInviteUserToEshopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/InviteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientEshopSettingsInviteUserToEshopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsRemoveUserFromEshopRequest} [clientEshopSettingsRemoveUserFromEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsRemoveUserFromEshop: async (clientEshopSettingsRemoveUserFromEshopRequest?: ClientEshopSettingsRemoveUserFromEshopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/RemoveUserFromEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientEshopSettingsRemoveUserFromEshopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetCosMaxRequest} [clientEshopSettingsSetCosMaxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetCosMax: async (clientEshopSettingsSetCosMaxRequest?: ClientEshopSettingsSetCosMaxRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/SetCosMax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientEshopSettingsSetCosMaxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetCreditNotificationLimitRequest} [clientEshopSettingsSetCreditNotificationLimitRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetCreditNotificationLimit: async (clientEshopSettingsSetCreditNotificationLimitRequest?: ClientEshopSettingsSetCreditNotificationLimitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/SetCreditNotificationLimit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientEshopSettingsSetCreditNotificationLimitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetNotificationEmailRequest} [clientEshopSettingsSetNotificationEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetNotificationEmail: async (clientEshopSettingsSetNotificationEmailRequest?: ClientEshopSettingsSetNotificationEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/SetNotificationEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientEshopSettingsSetNotificationEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetProductFeedUrlRequest} [clientEshopSettingsSetProductFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetProductFeedUrl: async (clientEshopSettingsSetProductFeedUrlRequest?: ClientEshopSettingsSetProductFeedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/EshopSettings/SetProductFeedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientEshopSettingsSetProductFeedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Feed processing logs
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLogsGetUserFeedLogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Logs/GetUserFeedLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets billing info for form prefill.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistoryGetBillingSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/PaymentHistory/GetBillingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the presigned url to the invoice pdf stored in Spaces.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientPaymentHistoryGetInvoicePdfUrlRequest} [clientPaymentHistoryGetInvoicePdfUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistoryGetInvoicePdfUrl: async (clientPaymentHistoryGetInvoicePdfUrlRequest?: ClientPaymentHistoryGetInvoicePdfUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/PaymentHistory/GetInvoicePdfUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientPaymentHistoryGetInvoicePdfUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of all invoice documents of eshop in database.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistoryGetPaymentHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/PaymentHistory/GetPaymentHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Used to save/update billing data.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientPaymentHistorySetBillingSettingsRequest} [clientPaymentHistorySetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistorySetBillingSettings: async (clientPaymentHistorySetBillingSettingsRequest?: ClientPaymentHistorySetBillingSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/PaymentHistory/SetBillingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientPaymentHistorySetBillingSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Loads all categories for eshop products
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/GetCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all Categories and their information
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetCategoriesData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/GetCategoriesData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get status of Feed and Products
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetFeedStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/GetFeedStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Loads all issues for given product in current Eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductIssuesRequest} [clientDTOGetProductIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetProductIssues: async (clientDTOGetProductIssuesRequest?: ClientDTOGetProductIssuesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/GetProductIssues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetProductIssuesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Loads all products for Eshop according to given sort and filters
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductsRequest} [clientDTOGetProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetProducts: async (clientDTOGetProductsRequest?: ClientDTOGetProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/GetProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the summary of products and their status
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetProductsSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/GetProductsSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the advertisement for given Category and changes Category attributes
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetCategoryAdvertisementRequest} [clientDTOSetCategoryAdvertisementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsSetCategoryAdvertisement: async (clientDTOSetCategoryAdvertisementRequest?: ClientDTOSetCategoryAdvertisementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/SetCategoryAdvertisement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOSetCategoryAdvertisementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user sets whether the category should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetMultipleProductsAdvertisementStatusRequest} [clientDTOSetMultipleProductsAdvertisementStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsSetMultipleProductsAdvertisementStatus: async (clientDTOSetMultipleProductsAdvertisementStatusRequest?: ClientDTOSetMultipleProductsAdvertisementStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/SetMultipleProductsAdvertisementStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOSetMultipleProductsAdvertisementStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user sets whether the product should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetProductAdvertisementStatusRequest} [clientDTOSetProductAdvertisementStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsSetProductAdvertisementStatus: async (clientDTOSetProductAdvertisementStatusRequest?: ClientDTOSetProductAdvertisementStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Products/SetProductAdvertisementStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOSetProductAdvertisementStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates a bank transfer payment process within the application. When called, this endpoint does not perform a real financial transaction but generates a proforma invoice based on the user\'s selected credit amount. The response includes a presigned URL to access the generated invoice and a QR code string for payment facilitation.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOBankTransferPaymentRequest} [clientDTOBankTransferPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditBankTransferPayment: async (clientDTOBankTransferPaymentRequest?: ClientDTOBankTransferPaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/BankTransferPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOBankTransferPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new checkout session for processing payments. Session is returned as clientSecret that have to be used to get the session through Stripe API.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOCreateCheckoutRequest} [clientDTOCreateCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditCreateCheckout: async (clientDTOCreateCheckoutRequest?: ClientDTOCreateCheckoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/CreateCheckout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOCreateCheckoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new checkout setup session for saving cards. Session is returned as clientSecret that have to be used to get the session through Stripe API.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOCreateSetupRequest} [clientDTOCreateSetupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditCreateSetup: async (clientDTOCreateSetupRequest?: ClientDTOCreateSetupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/CreateSetup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOCreateSetupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetAutomaticCreditRechargeOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/GetAutomaticCreditRechargeOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetAutomaticCreditRechargeStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/GetAutomaticCreditRechargeStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetEshopTaxRate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/GetEshopTaxRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns default amount for payments with currency of provided eshop in Token.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetPaymentDefaultAmount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/GetPaymentDefaultAmount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets checkout sessions response status and email of customer specified by provided session ID.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetSessionStatusRequest} [clientDTOGetSessionStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetSessionStatus: async (clientDTOGetSessionStatusRequest?: ClientDTOGetSessionStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/GetSessionStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetSessionStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns true if user present in Token has saved card.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditHasSavedCard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/HasSavedCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetAutomaticCreditRechargeOptionsRequest} [clientDTOSetAutomaticCreditRechargeOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditSetAutomaticCreditRechargeOptions: async (clientDTOSetAutomaticCreditRechargeOptionsRequest?: ClientDTOSetAutomaticCreditRechargeOptionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/SetAutomaticCreditRechargeOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOSetAutomaticCreditRechargeOptionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetAutomaticCreditRechargeStatusRequest} [clientDTOSetAutomaticCreditRechargeStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditSetAutomaticCreditRechargeStatus: async (clientDTOSetAutomaticCreditRechargeStatusRequest?: ClientDTOSetAutomaticCreditRechargeStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/RechargeCredit/SetAutomaticCreditRechargeStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOSetAutomaticCreditRechargeStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportCampaignStatsRequest} [clientReportExportCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportCampaignStats: async (clientReportExportCampaignStatsRequest?: ClientReportExportCampaignStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Report/ExportCampaignStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReportExportCampaignStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportOrderStatsRequest} [clientReportExportOrderStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportOrderStats: async (clientReportExportOrderStatsRequest?: ClientReportExportOrderStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Report/ExportOrderStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReportExportOrderStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportProductCategoryStatsRequest} [clientReportExportProductCategoryStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportProductCategoryStats: async (clientReportExportProductCategoryStatsRequest?: ClientReportExportProductCategoryStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Report/ExportProductCategoryStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReportExportProductCategoryStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportProductStatsRequest} [clientReportExportProductStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportProductStats: async (clientReportExportProductStatsRequest?: ClientReportExportProductStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Report/ExportProductStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReportExportProductStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportTrafficStatsRequest} [clientReportExportTrafficStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportTrafficStats: async (clientReportExportTrafficStatsRequest?: ClientReportExportTrafficStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Report/ExportTrafficStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReportExportTrafficStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportVisitSourceStatsRequest} [clientReportExportVisitSourceStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportVisitSourceStats: async (clientReportExportVisitSourceStatsRequest?: ClientReportExportVisitSourceStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Report/ExportVisitSourceStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReportExportVisitSourceStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets data for client/report page in app.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetCampaignStatsRequest} [clientDTOGetCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetCampaignStats: async (clientDTOGetCampaignStatsRequest?: ClientDTOGetCampaignStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetCampaignStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetCampaignStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetCampaignStatsGraphDataRequest} [clientDTOGetCampaignStatsGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetCampaignStatsGraphData: async (clientDTOGetCampaignStatsGraphDataRequest?: ClientDTOGetCampaignStatsGraphDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetCampaignStatsGraphData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetCampaignStatsGraphDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetGmvTrendChartDataRequest} [clientDTOGetGmvTrendChartDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetGmvTrendChartData: async (clientDTOGetGmvTrendChartDataRequest?: ClientDTOGetGmvTrendChartDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetGmvTrendChartData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetGmvTrendChartDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetOrderStatsRequest} [clientDTOGetOrderStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetOrderStats: async (clientDTOGetOrderStatsRequest?: ClientDTOGetOrderStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetOrderStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetOrderStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductCategoryStatsRequest} [clientDTOGetProductCategoryStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetProductCategoryStats: async (clientDTOGetProductCategoryStatsRequest?: ClientDTOGetProductCategoryStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetProductCategoryStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetProductCategoryStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductStatsRequest} [clientDTOGetProductStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetProductStats: async (clientDTOGetProductStatsRequest?: ClientDTOGetProductStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetProductStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetProductStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetTrafficStatsRequest} [clientDTOGetTrafficStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetTrafficStats: async (clientDTOGetTrafficStatsRequest?: ClientDTOGetTrafficStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetTrafficStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetTrafficStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetVisitSourceStatsRequest} [clientDTOGetVisitSourceStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetVisitSourceStats: async (clientDTOGetVisitSourceStatsRequest?: ClientDTOGetVisitSourceStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Statistics/GetVisitSourceStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTOGetVisitSourceStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Endpoint for Contact Us tab of the app
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientSurveySendContactFormRequest} [clientSurveySendContactFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSurveySendContactForm: async (clientSurveySendContactFormRequest?: ClientSurveySendContactFormRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Survey/SendContactForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientSurveySendContactFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the current credit for a specific e-shop using the provided e-shop ID from access token.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientTopMenuGetCredit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/TopMenu/GetCredit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserAffiliateGetAffilContributionForAllOwnedEshops: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserAffiliate/GetAffilContributionForAllOwnedEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserAffiliateGetCompanyInfoForAffilInvoice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserAffiliate/GetCompanyInfoForAffilInvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Sets the user\'s email to what is in the input. Sends an email with confirmation link. Confirmation link calls the ConfirmEmailChange enspoint in CallbacksController
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsChangeEmailRequest} [clientUserSettingsChangeEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsChangeEmail: async (clientUserSettingsChangeEmailRequest?: ClientUserSettingsChangeEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserSettings/ChangeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUserSettingsChangeEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> This endpoint allows authenticated users to change their password. It requires the user to provide their current (old) password, along with the desired new password and a confirmation of the new password. The endpoint verifies the old password, and if it matches the user\'s current password and the new password and confirmation match, the user\'s password will be updated.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsChangePasswordRequest} [clientUserSettingsChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsChangePassword: async (clientUserSettingsChangePasswordRequest?: ClientUserSettingsChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserSettings/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUserSettingsChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets user\'s name.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsGetName: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserSettings/GetName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets user\'s phone number.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsGetPhoneNumber: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserSettings/GetPhoneNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> The user\'s location will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsLocaleRequest} [clientUserSettingsLocaleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsSetLocale: async (clientUserSettingsLocaleRequest?: ClientUserSettingsLocaleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserSettings/SetLocale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUserSettingsLocaleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> The user\'s name will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsSetNameRequest} [clientUserSettingsSetNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsSetName: async (clientUserSettingsSetNameRequest?: ClientUserSettingsSetNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserSettings/SetName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUserSettingsSetNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> The user\'s phone number will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsSetPhoneNumberRequest} [clientUserSettingsSetPhoneNumberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsSetPhoneNumber: async (clientUserSettingsSetPhoneNumberRequest?: ClientUserSettingsSetPhoneNumberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/UserSettings/SetPhoneNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUserSettingsSetPhoneNumberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Creates new eshop in the database and links it with user. Given user has role EshopOwner in created eshop.Returns Id of newly created eshop. Use it to request eshop specific access token 😉.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientWizardAddEshopRequest} [clientWizardAddEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardAddEshop: async (clientWizardAddEshopRequest?: ClientWizardAddEshopRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/AddEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientWizardAddEshopRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Provides the pixel status. Return only true for testing. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardCheckPixel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/CheckPixel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets billing info for form prefill. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetBillingSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/GetBillingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets basic eshop info for AddEshop form prefill in wizard. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/GetEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the Url of the eshop - used for redirection while pixel verifing process
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetEshopUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/GetEshopUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Gets the eshop\'s product feed url from db. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetProductFeedUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/GetProductFeedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets remaining trial period for eshop with eshop ID provided in JWT
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetRemainingTrialPeriod: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/GetRemainingTrialPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Create the neccessities for Eshop to function and process its data.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardInitEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/InitEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Used to save/update billing data. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientWizardSetBillingSettingsRequest} [clientWizardSetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardSetBillingSettings: async (clientWizardSetBillingSettingsRequest?: ClientWizardSetBillingSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/SetBillingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientWizardSetBillingSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Used to set the url for the product feed. Given feed link is checked and if correct, saved to DB.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientWizardSetProductFeedUrlRequest} [clientWizardSetProductFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardSetProductFeedUrl: async (clientWizardSetProductFeedUrlRequest?: ClientWizardSetProductFeedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/SetProductFeedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientWizardSetProductFeedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <h3>Description</h3> Updates users Name and PhoneNumber. This endpoint is called when user adds his first eshop right after he registers into the app.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientWizardUpdateUserRequest} [clientWizardUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardUpdateUser: async (clientWizardUpdateUserRequest?: ClientWizardUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Client/Wizard/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientWizardUpdateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronAutomaticCreditTopUp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/AutomaticCreditTopUp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronBackupLogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/BackupLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCampaignDependenciesCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/CampaignDependenciesCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCampaignStatusesComparison: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/CampaignStatusesComparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCheckForMissingAppImports: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/CheckForMissingAppImports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCleanProductCronLogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/CleanProductCronLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCountGmcSubaccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/CountGmcSubaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronEshopsContributionList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/EshopsContributionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportCampaignHashes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportCampaignHashes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportCampaignPerformanceData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportCampaignPerformanceData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopMarketingInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportEshopMarketingInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopsActivityDuration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportEshopsActivityDuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportEshopsList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopsProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportEshopsProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportInvoicesCsv: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportInvoicesCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportInvoicesXml: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportInvoicesXml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportInvoicesZip: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportInvoicesZip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportPartnershipReportLastMonth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportPartnershipReportLastMonth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportPartnershipReportThisMonth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ExportPartnershipReportThisMonth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {CronCronFillDemoDataRequest} [cronCronFillDemoDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronFillDemoData: async (cronCronFillDemoDataRequest?: CronCronFillDemoDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/FillDemoData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cronCronFillDemoDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronGCLIDsExport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/GCLIDsExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportCampaignStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ImportCampaignStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportExchangeRates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ImportExchangeRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ImportOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportOrdersYesterday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ImportOrdersYesterday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportPixelEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ImportPixelEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportPseudoUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ImportPseudoUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportSessions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ImportSessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronProcessFeeds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ProcessFeeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronProcessProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/ProcessProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronSendNoCreditSequenceMails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/SendNoCreditSequenceMails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronSendWelcomeSequenceMails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/SendWelcomeSequenceMails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateEshopsDashboardStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/UpdateEshopsDashboardStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateEshopsLastTwoWeeksCos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/UpdateEshopsLastTwoWeeksCos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateEshopsTable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/UpdateEshopsTable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateProductsStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/UpdateProductsStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUploadProductsToPlatforms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Cron/Cron/UploadProductsToPlatforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EshopTanganicaProductsDTOGetAllProductsRequest} [eshopTanganicaProductsDTOGetAllProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopTanganicaProductsGetAllProducts: async (eshopTanganicaProductsDTOGetAllProductsRequest?: EshopTanganicaProductsDTOGetAllProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/EshopTanganica/Products/GetAllProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eshopTanganicaProductsDTOGetAllProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EshopTanganicaProductsDTOGetProductDetailRequest} [eshopTanganicaProductsDTOGetProductDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopTanganicaProductsGetProductDetail: async (eshopTanganicaProductsDTOGetProductDetailRequest?: EshopTanganicaProductsDTOGetProductDetailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/EshopTanganica/Products/GetProductDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eshopTanganicaProductsDTOGetProductDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualProcessAllAvailableFeeds: async (useProxy?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/FeedsManual/ProcessAllAvailableFeeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (useProxy !== undefined) {
                localVarQueryParameter['useProxy'] = useProxy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {MaintenanceDTOProcessFeedsForGivenEshopsRequest} [maintenanceDTOProcessFeedsForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualProcessFeedsForGivenEshops: async (useProxy?: boolean, maintenanceDTOProcessFeedsForGivenEshopsRequest?: MaintenanceDTOProcessFeedsForGivenEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/FeedsManual/ProcessFeedsForGivenEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (useProxy !== undefined) {
                localVarQueryParameter['useProxy'] = useProxy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOProcessFeedsForGivenEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {MaintenanceDTOProcessFeedsForManyGivenEshopsRequest} [maintenanceDTOProcessFeedsForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualProcessFeedsForManyGivenEshops: async (useProxy?: boolean, maintenanceDTOProcessFeedsForManyGivenEshopsRequest?: MaintenanceDTOProcessFeedsForManyGivenEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/FeedsManual/ProcessFeedsForManyGivenEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (useProxy !== undefined) {
                localVarQueryParameter['useProxy'] = useProxy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOProcessFeedsForManyGivenEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualSynchronizeFeedProductsForEshop: async (eshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/FeedsManual/SynchronizeFeedProductsForEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eshopId !== undefined) {
                localVarQueryParameter['eshopId'] = eshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceAnonymizeUser: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/AnonymizeUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOChangeOrderContributionRequest} [maintenanceDTOChangeOrderContributionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceChangeOrderContribution: async (maintenanceDTOChangeOrderContributionRequest?: MaintenanceDTOChangeOrderContributionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/ChangeOrderContribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOChangeOrderContributionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingFBCatalogsNew: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/CreateMissingFBCatalogsNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingGoogleSubaccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/CreateMissingGoogleSubaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingGoogleSubaccountsNew: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/CreateMissingGoogleSubaccountsNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/CreateMissingSubaccountsForMarketingIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOCurrencyChangeBackRequest} [maintenanceDTOCurrencyChangeBackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCurrencyChangeBack: async (maintenanceDTOCurrencyChangeBackRequest?: MaintenanceDTOCurrencyChangeBackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/CurrencyChangeBack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOCurrencyChangeBackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTODeleteOrderRequest} [maintenanceDTODeleteOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceDeleteOrder: async (maintenanceDTODeleteOrderRequest?: MaintenanceDTODeleteOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/DeleteOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTODeleteOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceEliminateSubaccountDuplicates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/EliminateSubaccountDuplicates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceGetCronToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/GetCronToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceGetDevToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/GetDevToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceImportExchangeRates: async (date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/ImportExchangeRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceMigrateExchangeRates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/MigrateExchangeRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceSwitchFBCampaignsIds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/SwitchFBCampaignsIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceTestLog: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/Maintenance/TestLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUpdateProductsStatusForGivenEshops: async (maintenanceDTOUpdateProductsStatusForGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/ProductsManual/UpdateProductsStatusForGivenEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOUpdateProductsStatusForGivenEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUpdateProductsStatusForManyGivenEshops: async (maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/ProductsManual/UpdateProductsStatusForManyGivenEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUploadProductsForManyGivenEshopsRequest} [maintenanceDTOUploadProductsForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUploadProductsForManyGivenEshops: async (maintenanceDTOUploadProductsForManyGivenEshopsRequest?: MaintenanceDTOUploadProductsForManyGivenEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/ProductsManual/UploadProductsForManyGivenEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOUploadProductsForManyGivenEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUploadProductsForGivenEshopsRequest} [maintenanceDTOUploadProductsForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUploadProductsGivenEshops: async (maintenanceDTOUploadProductsForGivenEshopsRequest?: MaintenanceDTOUploadProductsForGivenEshopsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Maintenance/ProductsManual/UploadProductsGivenEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDTOUploadProductsForGivenEshopsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestFillMissingMicrosoftSettingsTables: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/FillMissingMicrosoftSettingsTables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestMiskoTemp: async (eshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/MiskoTemp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eshopId !== undefined) {
                localVarQueryParameter['eshopId'] = eshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accountName] 
         * @param {number} [parentCustomerId] 
         * @param {number} [superAdminId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAccount: async (accountName?: string, parentCustomerId?: number, superAdminId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsAddAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountName !== undefined) {
                localVarQueryParameter['accountName'] = accountName;
            }

            if (parentCustomerId !== undefined) {
                localVarQueryParameter['parentCustomerId'] = parentCustomerId;
            }

            if (superAdminId !== undefined) {
                localVarQueryParameter['superAdminId'] = superAdminId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [adGroupId] 
         * @param {CampaignManagementAd} [campaignManagementAd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAd: async (accountId?: number, adGroupId?: number, campaignManagementAd?: CampaignManagementAd, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsAddAd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (adGroupId !== undefined) {
                localVarQueryParameter['adGroupId'] = adGroupId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignManagementAd, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {boolean} [returnInheritedBidStrategyTypes] 
         * @param {CampaignManagementAdGroup} [campaignManagementAdGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAdGroup: async (accountId?: number, campaignId?: number, returnInheritedBidStrategyTypes?: boolean, campaignManagementAdGroup?: CampaignManagementAdGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsAddAdGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (returnInheritedBidStrategyTypes !== undefined) {
                localVarQueryParameter['returnInheritedBidStrategyTypes'] = returnInheritedBidStrategyTypes;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignManagementAdGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementAssetGroup} [campaignManagementAssetGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAssetGroup: async (accountId?: number, campaignId?: number, campaignManagementAssetGroup?: CampaignManagementAssetGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsAddAssetGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignManagementAssetGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddCampaign: async (accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsAddCampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignManagementCampaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaignCriterionType} [criterionType] 
         * @param {Array<CampaignManagementCampaignCriterion>} [campaignManagementCampaignCriterion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddCampaignCriterions: async (accountId?: number, criterionType?: CampaignManagementCampaignCriterionType, campaignManagementCampaignCriterion?: Array<CampaignManagementCampaignCriterion>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsAddCampaignCriterions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (criterionType !== undefined) {
                localVarQueryParameter['criterionType'] = criterionType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignManagementCampaignCriterion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [imagePath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddMedia: async (accountId?: number, imagePath?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsAddMedia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (imagePath !== undefined) {
                localVarQueryParameter['imagePath'] = imagePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {string} [countryCode] 
         * @param {string} [description1] 
         * @param {string} [description2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsCreateDSA: async (accountId?: number, marketingId?: number, domainName?: string, countryCode?: string, description1?: string, description2?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsCreateDSA`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (marketingId !== undefined) {
                localVarQueryParameter['marketingId'] = marketingId;
            }

            if (domainName !== undefined) {
                localVarQueryParameter['domainName'] = domainName;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (description1 !== undefined) {
                localVarQueryParameter['description1'] = description1;
            }

            if (description2 !== undefined) {
                localVarQueryParameter['description2'] = description2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [countryCode] 
         * @param {number} [storeId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsCreatePLA: async (accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsCreatePLA`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (marketingId !== undefined) {
                localVarQueryParameter['marketingId'] = marketingId;
            }

            if (domainName !== undefined) {
                localVarQueryParameter['domainName'] = domainName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [countryCode] 
         * @param {number} [storeId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {string} [businessName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsCreatePRX: async (accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, businessName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsCreatePRX`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (marketingId !== undefined) {
                localVarQueryParameter['marketingId'] = marketingId;
            }

            if (domainName !== undefined) {
                localVarQueryParameter['domainName'] = domainName;
            }

            if (businessName !== undefined) {
                localVarQueryParameter['businessName'] = businessName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsDeleteAccount: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsDeleteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsDeleteCampaign: async (accountId?: number, campaignId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsDeleteCampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CustomerManagementAccountAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetAccount: async (accountId?: number, returnAdditionalFields?: CustomerManagementAccountAdditionalField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (returnAdditionalFields !== undefined) {
                localVarQueryParameter['returnAdditionalFields'] = returnAdditionalFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementAdGroupAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetAdGroupsByCampaignId: async (accountId?: number, campaignId?: number, returnAdditionalFields?: CampaignManagementAdGroupAdditionalField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetAdGroupsByCampaignId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (returnAdditionalFields !== undefined) {
                localVarQueryParameter['returnAdditionalFields'] = returnAdditionalFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [adGroupId] 
         * @param {CampaignManagementAdType} [adType] 
         * @param {CampaignManagementAdAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetAdsByAdGroupId: async (accountId?: number, adGroupId?: number, adType?: CampaignManagementAdType, returnAdditionalFields?: CampaignManagementAdAdditionalField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetAdsByAdGroupId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (adGroupId !== undefined) {
                localVarQueryParameter['adGroupId'] = adGroupId;
            }

            if (adType !== undefined) {
                localVarQueryParameter['adType'] = adType;
            }

            if (returnAdditionalFields !== undefined) {
                localVarQueryParameter['returnAdditionalFields'] = returnAdditionalFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementCampaignType} [campaignType] 
         * @param {CampaignManagementCampaignAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetCampaign: async (accountId?: number, campaignId?: number, campaignType?: CampaignManagementCampaignType, returnAdditionalFields?: CampaignManagementCampaignAdditionalField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetCampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (campaignType !== undefined) {
                localVarQueryParameter['campaignType'] = campaignType;
            }

            if (returnAdditionalFields !== undefined) {
                localVarQueryParameter['returnAdditionalFields'] = returnAdditionalFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementCampaignCriterionType} [criterionType] 
         * @param {CampaignManagementCriterionAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetCampaignCriterionsByIds: async (accountId?: number, campaignId?: number, criterionType?: CampaignManagementCampaignCriterionType, returnAdditionalFields?: CampaignManagementCriterionAdditionalField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetCampaignCriterionsByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (criterionType !== undefined) {
                localVarQueryParameter['criterionType'] = criterionType;
            }

            if (returnAdditionalFields !== undefined) {
                localVarQueryParameter['returnAdditionalFields'] = returnAdditionalFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaignType} [campaignType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetCampaignsByAccountId: async (accountId?: number, campaignType?: CampaignManagementCampaignType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetCampaignsByAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (campaignType !== undefined) {
                localVarQueryParameter['campaignType'] = campaignType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetGeoLocationsFileUrl: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetGeoLocationsFileUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {AdInsightEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId: async (accountId?: number, entityType?: AdInsightEntityType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetPerformanceInsightsDetailDataByAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsGetUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsSearchAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsSearchAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportingCampaignPerformanceReportRequest} [reportingCampaignPerformanceReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsSubmitGenerateReport: async (reportingCampaignPerformanceReportRequest?: ReportingCampaignPerformanceReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsSubmitGenerateReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportingCampaignPerformanceReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [newName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsUpdateAccount: async (accountId?: number, newName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsUpdateAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (newName !== undefined) {
                localVarQueryParameter['newName'] = newName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsUpdateCampaign: async (accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingAdsUpdateCampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignManagementCampaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingGetCampaignReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestBingGetCampaignReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiBatchProducts: async (merchantId?: number, catalogId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiBatchProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiCreateCatalog: async (merchantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiCreateCatalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [storeName] 
         * @param {string} [storeUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiCreateStore: async (storeName?: string, storeUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiCreateStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeName !== undefined) {
                localVarQueryParameter['storeName'] = storeName;
            }

            if (storeUrl !== undefined) {
                localVarQueryParameter['storeUrl'] = storeUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiDeleteCatalog: async (merchantId?: number, catalogId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiDeleteCatalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiDeleteProduct: async (merchantId?: number, productId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiDeleteProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetCatalog: async (merchantId?: number, catalogId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetCatalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetCatalogs: async (merchantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetCatalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProduct: async (merchantId?: number, productId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProducts: async (merchantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProductsStatuses: async (merchantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetProductsStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProductsStatusesSummary: async (merchantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetProductsStatusesSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetStore: async (merchantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetStores: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiGetStores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {string} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiInsertProduct: async (merchantId?: number, catalogId?: number, offerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiInsertProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (offerId !== undefined) {
                localVarQueryParameter['offerId'] = offerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {boolean} [isPublishingEnabled] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiUpdateCatalog: async (merchantId?: number, catalogId?: number, isPublishingEnabled?: boolean, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/TestContentApiUpdateCatalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (isPublishingEnabled !== undefined) {
                localVarQueryParameter['isPublishingEnabled'] = isPublishingEnabled;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestUpdateCategoryAdvertiseMicrosoft: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/UpdateCategoryAdvertiseMicrosoft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestUpdateProductMicrosoftStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/MTest/UpdateProductMicrosoftStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAddAdminUser: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/AddAdminUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAdminMailsToResx: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/AdminMailsToResx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAffilContrComparison: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/AffilContrComparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAnyCompensation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/AnyCompensation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAssignDeletedOwner: async (guid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/AssignDeletedOwner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (guid !== undefined) {
                localVarQueryParameter['guid'] = guid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestBillingSettingsExporting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/BillingSettingsExporting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCHECKSTRIPECUSTOMERS: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CHECK_STRIPE_CUSTOMERS`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCheckCreditTesting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CheckCreditTesting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCheckOwners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CheckOwners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCheckTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CheckTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreateMissingTriggerFunctionsInTenants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CreateMissingTriggerFunctionsInTenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditComparison: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CreditComparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditComparisonGivenEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CreditComparisonGivenEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditComparisonInternal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CreditComparisonInternal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditPerEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CreditPerEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditTenantComparison: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/CreditTenantComparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetAllActiveBillingSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/GetAllActiveBillingSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetEshopsIsPayerOfTaxes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/GetEshopsIsPayerOfTaxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetInvalidBsEshops: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/GetInvalidBsEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetInvalidInvoices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/GetInvalidInvoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetSentMailNumbers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/GetSentMailNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestInvoiceGenTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/InvoiceGenTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestLocalizerTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/LocalizerTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestPTestDTOLogCountsRequest} [testPTestDTOLogCountsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestLogCounts: async (testPTestDTOLogCountsRequest?: TestPTestDTOLogCountsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/LogCounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testPTestDTOLogCountsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestLogsToFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/LogsToFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestMailTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/MailTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Policies: ; AdminPermissions: GetKpiReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestPermissionAuthTesting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/PermissionAuthTesting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestPositiveAffilContrPerEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/PositiveAffilContrPerEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestRegenerateFuckedInvoices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/RegenerateFuckedInvoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestRoleClaims: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/RoleClaims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendAllAdminEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SendAllAdminEmails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendAllClientEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SendAllClientEmails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendMail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SendMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendMailWithAttachment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SendMailWithAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetCreditNoteAmountsNegative: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SetCreditNoteAmountsNegative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetNullMailLogAdminMailTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SetNullMailLogAdminMailTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetNullMailLogClientMailTypesNew: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SetNullMailLogClientMailTypesNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetNullMailLogClientMailTypesOld: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SetNullMailLogClientMailTypesOld`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetPassword: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetUserAdmin: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SetUserAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSyncInvoiceIssueDatesWithBs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/SyncInvoiceIssueDatesWithBs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestTestMailsRedesign: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/TestMailsRedesign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestTestUploadFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/TestUploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestUPDATESTRIPECUSTOMERS: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/UPDATE_STRIPE_CUSTOMERS`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestUserClaimsRetrieval: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/UserClaimsRetrieval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestPTestDTOUtcDateValidationRequest} [testPTestDTOUtcDateValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestUtcDateTimeValidation: async (testPTestDTOUtcDateValidationRequest?: TestPTestDTOUtcDateValidationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/PTest/UtcDateTimeValidation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testPTestDTOUtcDateValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundAssignAdminRole: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/AssignAdminRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundAssignAffilRole: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/AssignAffilRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundAssignTestRole: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/AssignTestRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [batchSize] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundBulkInsertTest: async (batchSize?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/BulkInsertTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (batchSize !== undefined) {
                localVarQueryParameter['batchSize'] = batchSize;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCampaignStatisticsDataRepair: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CampaignStatisticsDataRepair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ChangeFacebookCampaignToHistoricAndDeleteForMktIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCheckFBCampaignsCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CheckFBCampaignsCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCheckPixel: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CheckPixel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCleanSpacesBucket: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CleanSpacesBucket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCorrectUtmsManuallyForGivenMktIds: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CorrectUtmsManuallyForGivenMktIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateAllFbCatalogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CreateAllFbCatalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateFacebookCampaignTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CreateFacebookCampaignTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateFbCampaigns: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CreateFbCampaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [folderPath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateFolderOnDO: async (folderPath?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CreateFolderOnDO`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (folderPath !== undefined) {
                localVarQueryParameter['folderPath'] = folderPath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateMissingGoogleCampaigns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CreateMissingGoogleCampaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateMissingGoogleSubaccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/CreateMissingGoogleSubaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDataComparingTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DataComparingTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDataComparingTransactionsPerEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DataComparingTransactionsPerEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDbViewTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DbViewTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteAdCreative: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteAdCreative`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteAllAssistedOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteAllAssistedOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteBills: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteBills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteDPACampaignByDbId: async (eshopId?: string, campaignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteDPACampaignByDbId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eshopId !== undefined) {
                localVarQueryParameter['eshopId'] = eshopId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteGmcProductsForEshops: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteGmcProductsForEshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteOrdersFromBeforeMigration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteOrdersFromBeforeMigration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteOrdersFromDay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteOrdersFromDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteOrdersWithPseudoUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteOrdersWithPseudoUserId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteStuff: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteStuff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteTransactionsAndBills: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeleteTransactionsAndBills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeserializeTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DeserializeTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDownloadImageToLocal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/DownloadImageToLocal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundEshopCreditUpdateTriggerToAllSchemas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/EshopCreditUpdateTriggerToAllSchemas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundEshopIdTestWrite: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/EshopIdTestWrite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundEshopSubaccountsCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/EshopSubaccountsCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFBDeleteWhatever: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FBDeleteWhatever`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFBItemStatus: async (catalogId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FBItemStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFBRenameCatalogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FBRenameCatalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFacebookCampaignStructureV3: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FacebookCampaignStructureV3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFbCampaignStatisticsFacebook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FbCampaignStatisticsFacebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFbCampaignsRestructure: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FbCampaignsRestructure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFbDeleteAdsetsOld: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FbDeleteAdsetsOld`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFillAllAllowProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FillAllAllowProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFillDailyBudgets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FillDailyBudgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFillFbCampaingMarketingFormat: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FillFbCampaingMarketingFormat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindAllGmcProductErrors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FindAllGmcProductErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindEshopsWithMissingSubaccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FindEshopsWithMissingSubaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [useCzkAdAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindFbAdsUtmDuplicates: async (useCzkAdAccount?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FindFbAdsUtmDuplicates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (useCzkAdAccount !== undefined) {
                localVarQueryParameter['useCzkAdAccount'] = useCzkAdAccount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindFbCampaignsInfo: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FindFbCampaignsInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindMarketingIds: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FindMarketingIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindMissingFBAds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FindMissingFBAds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [logOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixFbAdStatuses: async (logOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FixFbAdStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (logOnly !== undefined) {
                localVarQueryParameter['logOnly'] = logOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [marketingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixFbAdUtms: async (marketingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FixFbAdUtms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (marketingId !== undefined) {
                localVarQueryParameter['marketingId'] = marketingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixGoogleCampaignStatisticsTimezone: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FixGoogleCampaignStatisticsTimezone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixTaxRatesAndVariableSymbols: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FixTaxRatesAndVariableSymbols`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FixTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFuckIIIIIIIIIIIIT: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/FuckIIIIIIIIIIIIT`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [productCount] 
         * @param {number} [feedCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGCTest: async (productCount?: number, feedCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GCTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productCount !== undefined) {
                localVarQueryParameter['productCount'] = productCount;
            }

            if (feedCount !== undefined) {
                localVarQueryParameter['feedCount'] = feedCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [feedCount] 
         * @param {number} [taskCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGCTestParallelRealData: async (feedCount?: number, taskCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GCTestParallelRealData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (feedCount !== undefined) {
                localVarQueryParameter['feedCount'] = feedCount;
            }

            if (taskCount !== undefined) {
                localVarQueryParameter['taskCount'] = taskCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGarbageCollectorGeneration2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GarbageCollectorGeneration2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGenerateEshopSpecificAccessToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GenerateEshopSpecificAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetActiveEshopsAndProductsCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetActiveEshopsAndProductsCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [adAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAdCreatives: async (adAccountId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAdCreatives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (adAccountId !== undefined) {
                localVarQueryParameter['adAccountId'] = adAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAdspendsPerCampaign: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAdspendsPerCampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllAdExpensesForDay: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAllAdExpensesForDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllCampaignsGoogle: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAllCampaignsGoogle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllFbCatalogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAllFbCatalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllGmcSubaccountsCz: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAllGmcSubaccountsCz`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllGmcSubaccountsEu: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAllGmcSubaccountsEu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllUnknown: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetAllUnknown`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCampaignStatsTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetCampaignStatsTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCampaignsWithMissingProductSets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetCampaignsWithMissingProductSets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCreditDifference: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetCreditDifference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCreditExpensesDifference: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetCreditExpensesDifference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCreditPerEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetCreditPerEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetDesiredStatusIssues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetDesiredStatusIssues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetEshopIdsFromMktIds: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetEshopIdsFromMktIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetEshopsInfo: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetEshopsInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetExchangeRate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetExchangeRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetFileFormatByHeader: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetFileFormatByHeader`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGmcLimits: async (tanganicaEu?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetGmcLimits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tanganicaEu !== undefined) {
                localVarQueryParameter['tanganicaEu'] = tanganicaEu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {EnumsCountryOfAdvertisement} [country] 
         * @param {number} [merchantId] 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGmcProductIssues: async (productId?: string, country?: EnumsCountryOfAdvertisement, merchantId?: number, tanganicaEu?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetGmcProductIssues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (tanganicaEu !== undefined) {
                localVarQueryParameter['tanganicaEu'] = tanganicaEu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGmcSubaccountsCount: async (tanganicaEu?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetGmcSubaccountsCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tanganicaEu !== undefined) {
                localVarQueryParameter['tanganicaEu'] = tanganicaEu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [campaignId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGoogleCampaignFormatTest: async (campaignId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetGoogleCampaignFormatTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientControllersGetHourlyCostMicrosRequest} [clientControllersGetHourlyCostMicrosRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetHourlyCostMicros: async (clientControllersGetHourlyCostMicrosRequest?: ClientControllersGetHourlyCostMicrosRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetHourlyCostMicros`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientControllersGetHourlyCostMicrosRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetLookalikeAudienceByOriginAudienceId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetLookalikeAudienceByOriginAudienceId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetMktIdsToProcessFeedsAtLeastOnce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetOrderDataTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetOrderDataTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetOrdersPerCampaignsPerEshop: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetOrdersPerCampaignsPerEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetSubaccountIdForEshop: async (eshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GetSubaccountIdForEshop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eshopId !== undefined) {
                localVarQueryParameter['eshopId'] = eshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [campaignId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGoogleAdsStatsTest: async (customerId?: number, campaignId?: number, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/GoogleAdsStatsTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundHttpClientTest: async (url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/HttpClientTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportAppImportsRecords: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ImportAppImportsRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportDataFroNewlyMigratedCampaigns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ImportDataFroNewlyMigratedCampaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportOrdersExactDay: async (date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ImportOrdersExactDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportOrdersExactTime: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ImportOrdersExactTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportStatisticsExactDay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ImportStatisticsExactDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundListAllTgncaGlobalFbCatalogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ListAllTgncaGlobalFbCatalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundListResponseTypeSwagger: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ListResponseTypeSwagger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundLogInsideBQ: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/LogInsideBQ`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundMeasureFeedSize: async (url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/MeasureFeedSize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundMigrateProductCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/MigrateProductCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundMigrationTest: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/MigrationTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundPreloadController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/PreloadController`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundPrintPageViewPathFromJson: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/PrintPageViewPathFromJson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRegexSplitTest: async (input?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/RegexSplitTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRemoveGmcFeedFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/RemoveGmcFeedFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRepairCampaignDesiredStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/RepairCampaignDesiredStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRepairCampaigns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/RepairCampaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundReuploadProductsGmc: async (eshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/ReuploadProductsGmc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eshopId !== undefined) {
                localVarQueryParameter['eshopId'] = eshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundSetAllProductsGmcUpdateToTrue: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/SetAllProductsGmcUpdateToTrue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {number} [budget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundSetFbCampaignBudget: async (campaignId?: string, budget?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/SetFbCampaignBudget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (budget !== undefined) {
                localVarQueryParameter['budget'] = budget;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundSetGmcProductsToReulpoad: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/SetGmcProductsToReulpoad`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTagMapSerializationTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TagMapSerializationTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestClearSheet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestClearSheet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [hour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestDateTimeCreation: async (date?: string, hour?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestDateTimeCreation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (hour !== undefined) {
                localVarQueryParameter['hour'] = hour;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestEshopBlacklist: async (eshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestEshopBlacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eshopId !== undefined) {
                localVarQueryParameter['eshopId'] = eshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [adCreativeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestFbAdCreativeUtm: async (adCreativeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestFbAdCreativeUtm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (adCreativeId !== undefined) {
                localVarQueryParameter['adCreativeId'] = adCreativeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestFbCatalogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestFbCatalogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestFbStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestFbStatuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestIOptionsInjection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestIOptionsInjection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestLinkUtmRemoving: async (input?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestLinkUtmRemoving`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestLog: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestMultiInitPerformance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestMultiInitPerformance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestParsingPrice: async (input?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestParsingPrice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestProductIssuesBlacklist: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestProductIssuesBlacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestReadDate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestReadDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestStatsImport: async (eshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestStatsImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eshopId !== undefined) {
                localVarQueryParameter['eshopId'] = eshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestStatusFromGads: async (campaignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestStatusFromGads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestTriggerFunctionsFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestTriggerFunctionsFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestikBlastik: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TestikBlastik`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [pid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTransactionWebhookTest: async (pid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TransactionWebhookTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pid !== undefined) {
                localVarQueryParameter['pid'] = pid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTurnOffFbAds: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/TurnOffFbAds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundimportSessionsTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/importSessionsTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundtestImportCampaignStatisticsBack: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/testImportCampaignStatisticsBack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [productCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundtestMemory: async (productCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/Test/Playground/testMemory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productCount !== undefined) {
                localVarQueryParameter['productCount'] = productCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TANGANICAApi - functional programming interface
 * @export
 */
export const TANGANICAApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TANGANICAApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a credit note for final invoice
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingGenerateCreditNoteRequest} [adminAccountingGenerateCreditNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountingGenerateCreditNote(adminAccountingGenerateCreditNoteRequest?: AdminAccountingGenerateCreditNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountingGenerateCreditNote(adminAccountingGenerateCreditNoteRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAccountingGenerateCreditNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets eshop\'s credit.
         * @summary Policies: RequireInternalRole
         * @param {AdminAccountingGetEshopCreditRequest} [adminAccountingGetEshopCreditRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountingGetEshopCredit(adminAccountingGetEshopCreditRequest?: AdminAccountingGetEshopCreditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountingGetEshopCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountingGetEshopCredit(adminAccountingGetEshopCreditRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAccountingGetEshopCredit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets the presigned url to the invoice pdf stored in Spaces.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
         * @param {AdminAccountingGetInvoicePdfUrlRequest} [adminAccountingGetInvoicePdfUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountingGetInvoicePdfUrl(adminAccountingGetInvoicePdfUrlRequest?: AdminAccountingGetInvoicePdfUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountingGetInvoicePdfUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountingGetInvoicePdfUrl(adminAccountingGetInvoicePdfUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAccountingGetInvoicePdfUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
         * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
         * @param {AdminAccountingGetInvoicesRequest} [adminAccountingGetInvoicesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountingGetInvoices(adminAccountingGetInvoicesRequest?: AdminAccountingGetInvoicesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountingGetInvoicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountingGetInvoices(adminAccountingGetInvoicesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAccountingGetInvoices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Regenerates the invoice with data from the database. Updates the regenerated invoice - changes Locale based on input and links latest BillingSetting
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingRegenerateInvoiceRequest} [adminAccountingRegenerateInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountingRegenerateInvoice(adminAccountingRegenerateInvoiceRequest?: AdminAccountingRegenerateInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountingRegenerateInvoice(adminAccountingRegenerateInvoiceRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAccountingRegenerateInvoice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Canceles the invoice.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingSetAsCanceledRequest} [adminAccountingSetAsCanceledRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountingSetAsCanceled(adminAccountingSetAsCanceledRequest?: AdminAccountingSetAsCanceledRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountingSetAsCanceled(adminAccountingSetAsCanceledRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAccountingSetAsCanceled']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Sets the ProformaInvoice or CreditNote status as paid.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingSetAsPaidRequest} [adminAccountingSetAsPaidRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAccountingSetAsPaid(adminAccountingSetAsPaidRequest?: AdminAccountingSetAsPaidRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAccountingSetAsPaid(adminAccountingSetAsPaidRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAccountingSetAsPaid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to directly add user permissions to other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
         * @param {AdminAdminsAddAdminUserPermissionRequest} [adminAdminsAddAdminUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsAddAdminUserPermission(adminAdminsAddAdminUserPermissionRequest?: AdminAdminsAddAdminUserPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsAddAdminUserPermission(adminAdminsAddAdminUserPermissionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsAddAdminUserPermission']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to  add user permissions to other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsAddAdminUserRoleRequest} [adminAdminsAddAdminUserRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsAddAdminUserRole(adminAdminsAddAdminUserRoleRequest?: AdminAdminsAddAdminUserRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsAddAdminUserRole(adminAdminsAddAdminUserRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsAddAdminUserRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to get roles they are able to assign to given internal user.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsGetAssignableRolesRequest} [adminAdminsGetAssignableRolesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsGetAssignableRoles(adminAdminsGetAssignableRolesRequest?: AdminAdminsGetAssignableRolesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAdminsGetAssignableRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsGetAssignableRoles(adminAdminsGetAssignableRolesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsGetAssignableRoles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all users with their roles.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsGetInternalUserRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAdminsGetInternalUserRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsGetInternalUserRoles(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsGetInternalUserRoles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail sending settings.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsGetSubscriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAdminsGetSubscriptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsGetSubscriptions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsGetSubscriptions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove user permissions of other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
         * @param {AdminAdminsRemoveAdminUserPermissionRequest} [adminAdminsRemoveAdminUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsRemoveAdminUserPermission(adminAdminsRemoveAdminUserPermissionRequest?: AdminAdminsRemoveAdminUserPermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsRemoveAdminUserPermission(adminAdminsRemoveAdminUserPermissionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsRemoveAdminUserPermission']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove roles of other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsRemoveAdminUserRoleRequest} [adminAdminsRemoveAdminUserRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsRemoveAdminUserRole(adminAdminsRemoveAdminUserRoleRequest?: AdminAdminsRemoveAdminUserRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsRemoveAdminUserRole(adminAdminsRemoveAdminUserRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsRemoveAdminUserRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
         * @summary Policies: RequireInternalRole
         * @param {AdminAdminsSubscribeRequest} [adminAdminsSubscribeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsSubscribe(adminAdminsSubscribeRequest?: AdminAdminsSubscribeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsSubscribe(adminAdminsSubscribeRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsSubscribe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
         * @summary Policies: RequireInternalRole
         * @param {AdminAdminsUnsubscribeRequest} [adminAdminsUnsubscribeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAdminsUnsubscribe(adminAdminsUnsubscribeRequest?: AdminAdminsUnsubscribeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAdminsUnsubscribe(adminAdminsUnsubscribeRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAdminsUnsubscribe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
         * @param {AdminAuthForgottenPasswordRequest} [adminAuthForgottenPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthForgottenPassword(adminAuthForgottenPasswordRequest?: AdminAuthForgottenPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthForgottenPassword(adminAuthForgottenPasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAuthForgottenPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {AdminAuthEshopAccessTokenRequest} [adminAuthEshopAccessTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthGetEshopAccessToken(adminAuthEshopAccessTokenRequest?: AdminAuthEshopAccessTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthGetEshopAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthGetEshopAccessToken(adminAuthEshopAccessTokenRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAuthGetEshopAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint returns list of AdminPermissions current user has.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthGetPermissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthGetPermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthGetPermissions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAuthGetPermissions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthGetUserAccessToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthGetAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthGetUserAccessToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAuthGetUserAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for admin login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
         * @param {AdminAuthLoginRequest} [adminAuthLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthLogin(adminAuthLoginRequest?: AdminAuthLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAuthLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthLogin(adminAuthLoginRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAuthLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthLogout(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAuthLogout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for registering new admin user. The new admin needs to re-log to obtain new access token.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal</li><li><b>Email</b>: Email of an already registered user who should be assigned with Internal role instead of Client role</li></ul>
         * @summary Policies: RequireInternalRole; AdminPermissions: RegisterNewAdmin
         * @param {AdminAuthRegisterRequest} [adminAuthRegisterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuthRegister(adminAuthRegisterRequest?: AdminAuthRegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuthRegister(adminAuthRegisterRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminAuthRegister']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
         * @summary Policies: RequireResetPasswordToken
         * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCallbacksResetPassword(clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCallbacksResetPassword(clientCallbacksResetPasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminCallbacksResetPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is tanganica admins or developers to be able logout all users<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin, Internal</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientAuthLogoutAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientAuthLogoutAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminClientAuthLogoutAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his Email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>Email</b>: valid user Email</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientAuthLogoutUserByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientAuthLogoutUserByEmail(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminClientAuthLogoutUserByEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminClientAuthLogoutUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminClientAuthLogoutUserById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminClientAuthLogoutUserById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Adds (or removes) given amount of credit to eshop. (Creates appropriate transaction)
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailAddAdminTransactionRequest} [adminEshopsDetailAddAdminTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailAddAdminTransaction(adminEshopsDetailAddAdminTransactionRequest?: AdminEshopsDetailAddAdminTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetEshopDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailAddAdminTransaction(adminEshopsDetailAddAdminTransactionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailAddAdminTransaction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Sets Eshop.Archived as true.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailArchiveEshopRequest} [adminEshopsDetailArchiveEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailArchiveEshop(adminEshopsDetailArchiveEshopRequest?: AdminEshopsDetailArchiveEshopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailArchiveEshop(adminEshopsDetailArchiveEshopRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailArchiveEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets currently active campaign formats
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetActiveCampaignFormatsRequest} [adminEshopsDetailGetActiveCampaignFormatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetActiveCampaignFormats(adminEshopsDetailGetActiveCampaignFormatsRequest?: AdminEshopsDetailGetActiveCampaignFormatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetActiveCampaignFormatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetActiveCampaignFormats(adminEshopsDetailGetActiveCampaignFormatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetActiveCampaignFormats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Used to get assets for {CampaignType} campaigns
         * @summary Policies: RequireInternalRole
         * @param {EnumsMarketingFormat} campaignType 
         * @param {AdminEshopsDetailGetAssetsRequest} [adminEshopsDetailGetAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetAssets(campaignType: EnumsMarketingFormat, adminEshopsDetailGetAssetsRequest?: AdminEshopsDetailGetAssetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetAssetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetAssets(campaignType, adminEshopsDetailGetAssetsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetAssets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets eshop billing info.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetBillingSettingsRequest} [adminEshopsDetailGetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetBillingSettings(adminEshopsDetailGetBillingSettingsRequest?: AdminEshopsDetailGetBillingSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetBillingSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetBillingSettings(adminEshopsDetailGetBillingSettingsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetBillingSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets all already created campaigns
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetCreatedCampaignsRequest} [adminEshopsDetailGetCreatedCampaignsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetCreatedCampaigns(adminEshopsDetailGetCreatedCampaignsRequest?: AdminEshopsDetailGetCreatedCampaignsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetCreatedCampaignsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetCreatedCampaigns(adminEshopsDetailGetCreatedCampaignsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetCreatedCampaigns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets history of all transactions where transaction type is not AdSpend or contribution
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetCreditTopupHistoryRequest} [adminEshopsDetailGetCreditTopupHistoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetCreditTopupHistory(adminEshopsDetailGetCreditTopupHistoryRequest?: AdminEshopsDetailGetCreditTopupHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetCreditTopupHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetCreditTopupHistory(adminEshopsDetailGetCreditTopupHistoryRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetCreditTopupHistory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets contribution transactions overview.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetDailyContributionStatisticsRequest} [adminEshopsDetailGetDailyContributionStatisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetDailyContributionStatistics(adminEshopsDetailGetDailyContributionStatisticsRequest?: AdminEshopsDetailGetDailyContributionStatisticsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetDailyContributionStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetDailyContributionStatistics(adminEshopsDetailGetDailyContributionStatisticsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetDailyContributionStatistics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetContributionPercentageRequest} [adminEshopsDetailGetContributionPercentageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetDefaultContributionPercentage(adminEshopsDetailGetContributionPercentageRequest?: AdminEshopsDetailGetContributionPercentageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetContributionPercentageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetDefaultContributionPercentage(adminEshopsDetailGetContributionPercentageRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetDefaultContributionPercentage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets basic eshop info for the top part of admin eshop detail page.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetEshopDetailRequest} [adminEshopsDetailGetEshopDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetEshopDetail(adminEshopsDetailGetEshopDetailRequest?: AdminEshopsDetailGetEshopDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetEshopDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetEshopDetail(adminEshopsDetailGetEshopDetailRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetEshopDetail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Get Feed processing logs.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetFeedLogsRequest} [adminEshopsDetailGetFeedLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetFeedLogs(adminEshopsDetailGetFeedLogsRequest?: AdminEshopsDetailGetFeedLogsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetFeedLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetFeedLogs(adminEshopsDetailGetFeedLogsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetFeedLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Get Feed url.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetFeedUrlRequest} [adminEshopsDetailGetFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetFeedUrl(adminEshopsDetailGetFeedUrlRequest?: AdminEshopsDetailGetFeedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetFeedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetFeedUrl(adminEshopsDetailGetFeedUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetFeedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets data for graphs on the EshopDetail page in admin
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetGraphDataRequest} [adminEshopsDetailGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetGraphData(adminEshopsDetailGetGraphDataRequest?: AdminEshopsDetailGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetGraphData(adminEshopsDetailGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Get current number of products in database.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetProductCountRequest} [adminEshopsDetailGetProductCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetProductCount(adminEshopsDetailGetProductCountRequest?: AdminEshopsDetailGetProductCountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetProductCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetProductCount(adminEshopsDetailGetProductCountRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetProductCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the state of products advertisement for given platform
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetProductsStateRequest} [adminEshopsDetailGetProductsStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailGetProductsState(adminEshopsDetailGetProductsStateRequest?: AdminEshopsDetailGetProductsStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsDetailGetProductsStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailGetProductsState(adminEshopsDetailGetProductsStateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailGetProductsState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Process feed for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailProcessEshopFeedRequest} [adminEshopsDetailProcessEshopFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailProcessEshopFeed(adminEshopsDetailProcessEshopFeedRequest?: AdminEshopsDetailProcessEshopFeedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailProcessEshopFeed(adminEshopsDetailProcessEshopFeedRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailProcessEshopFeed']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Sets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetContributionPercentageRequest} [adminEshopsDetailSetContributionPercentageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailSetDefaultContributionPercentage(adminEshopsDetailSetContributionPercentageRequest?: AdminEshopsDetailSetContributionPercentageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailSetDefaultContributionPercentage(adminEshopsDetailSetContributionPercentageRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailSetDefaultContributionPercentage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Change Feed url.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetFeedUrlRequest} [adminEshopsDetailSetFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailSetFeedUrl(adminEshopsDetailSetFeedUrlRequest?: AdminEshopsDetailSetFeedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailSetFeedUrl(adminEshopsDetailSetFeedUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailSetFeedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Sets the state of products advertisement for given platform
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetProductsStateRequest} [adminEshopsDetailSetProductsStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailSetProductsState(adminEshopsDetailSetProductsStateRequest?: AdminEshopsDetailSetProductsStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailSetProductsState(adminEshopsDetailSetProductsStateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailSetProductsState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Update products info in admin table for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUpdateEshopProductsAdminInfoRequest} [adminEshopsDetailUpdateEshopProductsAdminInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailUpdateEshopProductsAdminInfo(adminEshopsDetailUpdateEshopProductsAdminInfoRequest?: AdminEshopsDetailUpdateEshopProductsAdminInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailUpdateEshopProductsAdminInfo(adminEshopsDetailUpdateEshopProductsAdminInfoRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailUpdateEshopProductsAdminInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Update products status for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUpdateEshopProductsStatusRequest} [adminEshopsDetailUpdateEshopProductsStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailUpdateEshopProductsStatus(adminEshopsDetailUpdateEshopProductsStatusRequest?: AdminEshopsDetailUpdateEshopProductsStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailUpdateEshopProductsStatus(adminEshopsDetailUpdateEshopProductsStatusRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailUpdateEshopProductsStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Upload products for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUploadEshopProductsRequest} [adminEshopsDetailUploadEshopProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopDetailUploadEshopProducts(adminEshopsDetailUploadEshopProductsRequest?: AdminEshopsDetailUploadEshopProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopDetailUploadEshopProducts(adminEshopsDetailUploadEshopProductsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopDetailUploadEshopProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the data for the campaign statistics sub-table visible after expanding the eshop view in the main eshop table in admin.It is also used on eshop detail.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsGetEshopCampaignsStatisticsRequest} [adminEshopsGetEshopCampaignsStatisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopsGetEshopCampaignsStatistics(adminEshopsGetEshopCampaignsStatisticsRequest?: AdminEshopsGetEshopCampaignsStatisticsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsGetEshopCampaignsStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopsGetEshopCampaignsStatistics(adminEshopsGetEshopCampaignsStatisticsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopsGetEshopCampaignsStatistics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the eshops
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsGetEshopsRequest} [adminEshopsGetEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopsGetEshops(adminEshopsGetEshopsRequest?: AdminEshopsGetEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminEshopsGetEshopsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopsGetEshops(adminEshopsGetEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopsGetEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Updates the database view from which data for admin eshops table are queried.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEshopsRefreshEshopsView(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEshopsRefreshEshopsView(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminEshopsRefreshEshopsView']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading a gclid report file
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExportGetGclidsCsvLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetGclidsCsvResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExportGetGclidsCsvLink(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminExportGetGclidsCsvLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading csv files with invoices 
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExportGetInvoiceCsvLinks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetInvoicesCsvResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExportGetInvoiceCsvLinks(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminExportGetInvoiceCsvLinks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading xml files with invoices 
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExportGetInvoiceXmlLinks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetInvoiceXmlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExportGetInvoiceXmlLinks(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminExportGetInvoiceXmlLinks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading a zip with invoices
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExportGetInvoiceZipLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetInvoiceZipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExportGetInvoiceZipLink(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminExportGetInvoiceZipLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the list of unsuccessful feeds.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFeedsGetFeedErrors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetFeedErrorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFeedsGetFeedErrors(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminFeedsGetFeedErrors']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the specified feed cron info.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetFeedProcessingInfoRequest} [adminDTOGetFeedProcessingInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFeedsGetFeedProcessingInfo(adminDTOGetFeedProcessingInfoRequest?: AdminDTOGetFeedProcessingInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetFeedProcessingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFeedsGetFeedProcessingInfo(adminDTOGetFeedProcessingInfoRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminFeedsGetFeedProcessingInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Endpoint for getting list of all googleCustomerSubAccounts in TGNCA s.r.o account
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGoogleCampaignsGetAllCustomerSubAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGoogleCampaignsGetAllCustomerSubAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGoogleCampaignsGetAllCustomerSubAccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminGoogleCampaignsGetAllCustomerSubAccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view incomplete google campaigns that they need to create on google
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGoogleCampaignsGetCampaigns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGoogleCampaignsGetCampaignsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGoogleCampaignsGetCampaigns(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminGoogleCampaignsGetCampaigns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Endpoint serving as a trigger to match the external id / customerSubAccount id with an empty campaign in database - both single and multiple select
         * @summary Policies: RequireInternalRole; AdminPermissions: SetGoogleCampaigns
         * @param {AdminGoogleCampaignsGoogleCampaignCreatedRequest} [adminGoogleCampaignsGoogleCampaignCreatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGoogleCampaignsGoogleCampaignCreated(adminGoogleCampaignsGoogleCampaignCreatedRequest?: AdminGoogleCampaignsGoogleCampaignCreatedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGoogleCampaignsGoogleCampaignCreated(adminGoogleCampaignsGoogleCampaignCreatedRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminGoogleCampaignsGoogleCampaignCreated']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets list of activated eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportActivatedEshops
         * @param {AdminKpiReportGetActivatedEshopsRequest} [adminKpiReportGetActivatedEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetActivatedEshops(adminKpiReportGetActivatedEshopsRequest?: AdminKpiReportGetActivatedEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetActivatedEshopsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetActivatedEshops(adminKpiReportGetActivatedEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetActivatedEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for Advertising expenses graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetAdExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetAdExpensesGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetAdExpensesGraphData(adminKpiReportGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetAdExpensesGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for Contributions graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetContributionsGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetContributionsGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetContributionsGraphData(adminKpiReportGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetContributionsGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for Contributions/Advertising expenses graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetContributionsToAdExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetContributionsToAdExpensesGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetContributionsToAdExpensesGraphData(adminKpiReportGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetContributionsToAdExpensesGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for Eshops with spending graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetEshopsWithAdspendGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetEshopsWithAdspendGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetEshopsWithAdspendGraphData(adminKpiReportGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetEshopsWithAdspendGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for Turnover (GeneratedRevenue) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetGeneratedRevenueGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetGeneratedRevenueGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetGeneratedRevenueGraphData(adminKpiReportGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetGeneratedRevenueGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets kpi report data for given time frame.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetKpiReportRequest} [adminKpiReportGetKpiReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetKpiReport(adminKpiReportGetKpiReportRequest?: AdminKpiReportGetKpiReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetKpiReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetKpiReport(adminKpiReportGetKpiReportRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetKpiReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for the campaign table in monthly report section of Kpi report page.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetMonthlyCampaignStatsRequest} [adminKpiReportGetMonthlyCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetMonthlyCampaignStats(adminKpiReportGetMonthlyCampaignStatsRequest?: AdminKpiReportGetMonthlyCampaignStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetMonthlyCampaignStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetMonthlyCampaignStats(adminKpiReportGetMonthlyCampaignStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetMonthlyCampaignStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for monthly report section of Kpi report page.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetMonthlyReportRequest} [adminKpiReportGetMonthlyReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetMonthlyReport(adminKpiReportGetMonthlyReportRequest?: AdminKpiReportGetMonthlyReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetMonthlyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetMonthlyReport(adminKpiReportGetMonthlyReportRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetMonthlyReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for Transactions (NumberOfOrders) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetNumberOfOrdersGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetNumberOfOrdersGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetNumberOfOrdersGraphData(adminKpiReportGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetNumberOfOrdersGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets list of registered eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportRegisteredEshops
         * @param {AdminKpiReportGetRegisteredEshopsRequest} [adminKpiReportGetRegisteredEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetRegisteredEshops(adminKpiReportGetRegisteredEshopsRequest?: AdminKpiReportGetRegisteredEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetRegisteredEshopsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetRegisteredEshops(adminKpiReportGetRegisteredEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetRegisteredEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for Accounting (TotalExpenses) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminKpiReportGetTotalExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminKpiReportGetTotalExpensesGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminKpiReportGetTotalExpensesGraphData(adminKpiReportGetGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminKpiReportGetTotalExpensesGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the user logs
         * @summary Policies: RequireInternalRole
         * @param {AdminLogsGetLogsRequest} [adminLogsGetLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLogsGetLogs(adminLogsGetLogsRequest?: AdminLogsGetLogsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLogsGetLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLogsGetLogs(adminLogsGetLogsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminLogsGetLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail logs
         * @summary Policies: RequireInternalRole
         * @param {AdminLogsGetMailLogsRequest} [adminLogsGetMailLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLogsGetMailLogs(adminLogsGetMailLogsRequest?: AdminLogsGetMailLogsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminLogsGetMailLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLogsGetMailLogs(adminLogsGetMailLogsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminLogsGetMailLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the data from DB view made for Gmc products data and apply filtering, sorting and paging.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsDataRequest} [adminDTOGetProductsDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsGetProductsData(adminDTOGetProductsDataRequest?: AdminDTOGetProductsDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetProductsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsGetProductsData(adminDTOGetProductsDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminProductsGetProductsData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the DOTNET issues for given eshop.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsDotnetIssuesRequest} [adminDTOGetProductsDotnetIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsGetProductsDotnetIssues(adminDTOGetProductsDotnetIssuesRequest?: AdminDTOGetProductsDotnetIssuesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetProductsDotnetIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsGetProductsDotnetIssues(adminDTOGetProductsDotnetIssuesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminProductsGetProductsDotnetIssues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the GMC issues for given eshop.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsGmcIssuesRequest} [adminDTOGetProductsGmcIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsGetProductsGmcIssues(adminDTOGetProductsGmcIssuesRequest?: AdminDTOGetProductsGmcIssuesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetProductsGmcIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsGetProductsGmcIssues(adminDTOGetProductsGmcIssuesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminProductsGetProductsGmcIssues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the count and capacity notification of GMC subaccounts.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsGetSubaccountsInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDTOGetSubaccountsInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsGetSubaccountsInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminProductsGetSubaccountsInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Updates the count of GMC subaccounts.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsUpdateGmcSubaccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsUpdateGmcSubaccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminProductsUpdateGmcSubaccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint gets number of eshops with spend for czech and foreign eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuActiveEshopsCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTopMenuGetActiveEshopsCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTopMenuGetActiveEshopsCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTopMenuGetActiveEshopsCount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminTopMenuGetActiveEshopsCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint gets overall credit for czech and foreign eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuEshopsCredit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTopMenuGetEshopsCredit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTopMenuGetEshopsCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTopMenuGetEshopsCredit(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminTopMenuGetEshopsCredit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution - same as in the client app
         * @summary Policies: RequireInternalRole
         * @param {AdminUserDetailGetUserAffilInfoRequest} [adminUserDetailGetUserAffilInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDetailGetUserAffilInfo(adminUserDetailGetUserAffilInfoRequest?: AdminUserDetailGetUserAffilInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDetailGetUserAffilInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDetailGetUserAffilInfo(adminUserDetailGetUserAffilInfoRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminUserDetailGetUserAffilInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets basic user info.
         * @summary Policies: RequireInternalRole
         * @param {AdminUserDetailGetUserDetailRequest} [adminUserDetailGetUserDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDetailGetUserDetail(adminUserDetailGetUserDetailRequest?: AdminUserDetailGetUserDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDetailGetUserDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDetailGetUserDetail(adminUserDetailGetUserDetailRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminUserDetailGetUserDetail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID
         * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
         * @param {AdminUserDetailLogoutUserRequest} [adminUserDetailLogoutUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDetailLogoutUser(adminUserDetailLogoutUserRequest?: AdminUserDetailLogoutUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDetailLogoutUser(adminUserDetailLogoutUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminUserDetailLogoutUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Sets user as affiliate.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
         * @param {AdminUserDetailSetUserAffilRequest} [adminUserDetailSetUserAffilRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDetailSetUserAffil(adminUserDetailSetUserAffilRequest?: AdminUserDetailSetUserAffilRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDetailSetUserAffil(adminUserDetailSetUserAffilRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminUserDetailSetUserAffil']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
         * @summary Policies: RequireInternalRole
         * @param {AdminUsersGetUsersRequest} [adminUsersGetUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersGetUsers(adminUsersGetUsersRequest?: AdminUsersGetUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUsersGetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersGetUsers(adminUsersGetUsersRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.adminUsersGetUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> The user requests an affil program - email is sent to admins with users name and id
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAffiliateReportAskForAffil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAffiliateReportAskForAffil(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAffiliateReportAskForAffil']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the amount of affil contribution available for the eshop to withdraw
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAffiliateReportGetAffilContributionAvailable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetAffilContributionAvailableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAffiliateReportGetAffilContributionAvailable(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAffiliateReportGetAffilContributionAvailable']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAffiliateReportGetAffilGraphData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetAffilGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAffiliateReportGetAffilGraphData(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAffiliateReportGetAffilGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets all the necessary info about the affiliate program
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAffiliateReportGetAffilInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetAffilInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAffiliateReportGetAffilInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAffiliateReportGetAffilInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAffiliateReportGetAffiliateContributionTotal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetAffiliateContributionTotalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAffiliateReportGetAffiliateContributionTotal(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAffiliateReportGetAffiliateContributionTotal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateTextAssetsRequest} [clientDTOGenerateTextAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAssetGenerateTextAssets(clientDTOGenerateTextAssetsRequest?: ClientDTOGenerateTextAssetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateTextAssetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAssetGenerateTextAssets(clientDTOGenerateTextAssetsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAssetGenerateTextAssets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout when his refresh token is expired
         * @param {ClientAuthLogoutRequest} [clientAuthLogoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthExpiredTokenLogout(clientAuthLogoutRequest?: ClientAuthLogoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthExpiredTokenLogout(clientAuthLogoutRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthExpiredTokenLogout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
         * @param {ClientAuthForgottenPasswordRequest} [clientAuthForgottenPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthForgottenPassword(clientAuthForgottenPasswordRequest?: ClientAuthForgottenPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthForgottenPassword(clientAuthForgottenPasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthForgottenPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {ClientAuthEshopAccessTokenRequest} [clientAuthEshopAccessTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetEshopAccessToken(clientAuthEshopAccessTokenRequest?: ClientAuthEshopAccessTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthGetEshopAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetEshopAccessToken(clientAuthEshopAccessTokenRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthGetEshopAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint gets the facebook oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
         * @param {ClientAuthGetFacebookOAuthUrlRequest} [clientAuthGetFacebookOAuthUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetFacebookOAuthUrl(clientAuthGetFacebookOAuthUrlRequest?: ClientAuthGetFacebookOAuthUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetFacebookOAuthUrl(clientAuthGetFacebookOAuthUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthGetFacebookOAuthUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint gets the google oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
         * @param {ClientAuthGetGoogleOAuthUrlRequest} [clientAuthGetGoogleOAuthUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetGoogleOAuthUrl(clientAuthGetGoogleOAuthUrlRequest?: ClientAuthGetGoogleOAuthUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthGetGoogleOAuthUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetGoogleOAuthUrl(clientAuthGetGoogleOAuthUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthGetGoogleOAuthUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetUserAccessToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthGetAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetUserAccessToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthGetUserAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for standard user password login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
         * @param {ClientAuthLoginRequest} [clientAuthLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthLogin(clientAuthLoginRequest?: ClientAuthLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthLogin(clientAuthLoginRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: UserAccessToken</li></ul>
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthLogout(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthLogout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint creates new user with client account owner privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal AND Admin</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Email</b>: have to be unique</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
         * @param {ClientAuthRegisterRequest} [clientAuthRegisterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthRegister(clientAuthRegisterRequest?: ClientAuthRegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthRegister(clientAuthRegisterRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthRegister']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint creates new user with client account owner privileges and given eshop role privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: InviteToken</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
         * @summary Policies: RequireInviteToken
         * @param {ClientAuthRegisterInvitedRequest} [clientAuthRegisterInvitedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthRegisterInvited(clientAuthRegisterInvitedRequest?: ClientAuthRegisterInvitedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthRegisterInvitedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthRegisterInvited(clientAuthRegisterInvitedRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientAuthRegisterInvited']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Is called after user clicks on the confirmation link in email. User is then redirected to login page (? TODO)
         * @summary Policies: RequireEmailChangeToken
         * @param {ClientCallbacksConfirmEmailChangeRequest} [clientCallbacksConfirmEmailChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCallbacksConfirmEmailChange(clientCallbacksConfirmEmailChangeRequest?: ClientCallbacksConfirmEmailChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCallbacksConfirmEmailChange(clientCallbacksConfirmEmailChangeRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCallbacksConfirmEmailChange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Called by frontend, which passes through the parameters obtained by external auth provider <h3>Requirements</h3><ul><li><b>Authorization roles</b>: token obtained from state query parameter of redirectUri </li></ul>
         * @summary Policies: RequireOAuthStateToken
         * @param {ClientCallbacksExternalAuthCallbackRequest} [clientCallbacksExternalAuthCallbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCallbacksExternalAuthCallback(clientCallbacksExternalAuthCallbackRequest?: ClientCallbacksExternalAuthCallbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCallbacksExternalAuthCallbackResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCallbacksExternalAuthCallback(clientCallbacksExternalAuthCallbackRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCallbacksExternalAuthCallback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
         * @summary Policies: RequireResetPasswordToken
         * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCallbacksResetPassword(clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCallbacksResetPassword(clientCallbacksResetPasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCallbacksResetPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCallbacksStripePaymentWebhook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCallbacksStripePaymentWebhook(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCallbacksStripePaymentWebhook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Exchanges the auth code for access token and indicates whether it has correct business permissions (true/false)
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest} [clientCampaignsCheckAuthCodeForBusinessPermissionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsCheckAuthCodeForBusinessPermissions(clientCampaignsCheckAuthCodeForBusinessPermissionsRequest?: ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsCheckAuthCodeForBusinessPermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsCheckAuthCodeForBusinessPermissions(clientCampaignsCheckAuthCodeForBusinessPermissionsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsCheckAuthCodeForBusinessPermissions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Checks if FB page is linked to Eshop in DB
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsCheckFBPageLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsCheckFBPageLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsCheckFBPageLink(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsCheckFBPageLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsDeleteAssetsRequest} [clientCampaignsDeleteAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsDeleteAssets(campaignType: EnumsMarketingFormat, clientCampaignsDeleteAssetsRequest?: ClientCampaignsDeleteAssetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsDeleteAssets(campaignType, clientCampaignsDeleteAssetsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsDeleteAssets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetAssets(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetAssetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetAssets(campaignType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetAssets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * This endpoint gets the information if any campaign with given Format exists for current eshop.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetCampaignStatus(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetCampaignStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetCampaignStatus(campaignType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetCampaignStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * This endpoint gets the value of daily budget as well as minimal budget information for provided marketing format campaign.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetDailyBudget(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetDailyBudgetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetDailyBudget(campaignType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetDailyBudget']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetDashboardStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetDashboardStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetDashboardStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a link to the FB business sharing interface
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetFBBusinessOauthUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetFBBusinessOauthUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetFBBusinessOauthUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetFBBusinessOauthUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns the name and link of the linked FB page
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetFBLinkedPageInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetFBLinkedPageInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetFBLinkedPageInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetFBLinkedPageInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a link to the FB page sharing interface
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetFBPagesOauthUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetFBPagesOauthUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetFBPagesOauthUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetFBPagesOauthUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get status for provided {CampaignType} campaign. This does not reflect reality in marketing platform (Google/Facebook) but desired status of the user.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsGetStatus(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsGetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsGetStatus(campaignType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsGetStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * This endpoint sets the value of daily budget for provided marketing format campaign. First it attempts to set the value using the corresponding platform API (e.g., Google Ads, Facebook Ads). If the API call fails, it throws an error and skip the change in database. In case of success, it updates the value inside the database and returns success.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsSetDailyBudgetRequest} [clientCampaignsSetDailyBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsSetDailyBudget(campaignType: EnumsMarketingFormat, clientCampaignsSetDailyBudgetRequest?: ClientCampaignsSetDailyBudgetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsSetDailyBudget(campaignType, clientCampaignsSetDailyBudgetRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsSetDailyBudget']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * This endpoint reverses the status of a marketing campaign in both the database and corresponding marketing platforms (e.g., Google Ads, Facebook Ads) based on the provided MarketingFormat. If the database operation fails, it throws an error and skips the API calls. If a platform API call switch fails, it still returns success and attempts the switch later based on status in the database.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsSetStatusRequest} [clientCampaignsSetStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsSetStatus(campaignType: EnumsMarketingFormat, clientCampaignsSetStatusRequest?: ClientCampaignsSetStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsSetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsSetStatus(campaignType, clientCampaignsSetStatusRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsSetStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Adds TGNCA as agency to the clients shared page and notifies admins via email
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientCampaignsShareFBPageWithTanganicaRequest} [clientCampaignsShareFBPageWithTanganicaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsShareFBPageWithTanganica(clientCampaignsShareFBPageWithTanganicaRequest?: ClientCampaignsShareFBPageWithTanganicaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsShareFBPageWithTanganicaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsShareFBPageWithTanganica(clientCampaignsShareFBPageWithTanganicaRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsShareFBPageWithTanganica']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * This endpoint updates the Bing DSA ad text with updated assets.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsUpdateBingDSACampaign(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsUpdateBingDSACampaign(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsUpdateBingDSACampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * This endpoint updates the DPA campaign Ad Creatives with updated assets.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsUpdateDPACampaign(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsUpdateDPACampaign(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsUpdateDPACampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsUploadAssetsRequest} [clientCampaignsUploadAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCampaignsUploadAssets(campaignType: EnumsMarketingFormat, clientCampaignsUploadAssetsRequest?: ClientCampaignsUploadAssetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCampaignsUploadAssetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCampaignsUploadAssets(campaignType, clientCampaignsUploadAssetsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientCampaignsUploadAssets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentRequest} [clientDTOGenerateBlogContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateBlogContent(clientDTOGenerateBlogContentRequest?: ClientDTOGenerateBlogContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateBlogContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateBlogContent(clientDTOGenerateBlogContentRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateBlogContent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentIdeaRequest} [clientDTOGenerateBlogContentIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateBlogContentIdea(clientDTOGenerateBlogContentIdeaRequest?: ClientDTOGenerateBlogContentIdeaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateBlogContentIdeaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateBlogContentIdea(clientDTOGenerateBlogContentIdeaRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateBlogContentIdea']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentCtrategyRequest} [clientDTOGenerateBlogContentCtrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateBlogContentStrategy(clientDTOGenerateBlogContentCtrategyRequest?: ClientDTOGenerateBlogContentCtrategyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateBlogContentCtrategyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateBlogContentStrategy(clientDTOGenerateBlogContentCtrategyRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateBlogContentStrategy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateEmailContentStrategyRequest} [clientDTOGenerateEmailContentStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateEmailContentStrategy(clientDTOGenerateEmailContentStrategyRequest?: ClientDTOGenerateEmailContentStrategyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateEmailContentStrategyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateEmailContentStrategy(clientDTOGenerateEmailContentStrategyRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateEmailContentStrategy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateNewsletterRequest} [clientDTOGenerateNewsletterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateNewsletter(clientDTOGenerateNewsletterRequest?: ClientDTOGenerateNewsletterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateNewsletterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateNewsletter(clientDTOGenerateNewsletterRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateNewsletter']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateNewsletterIdeaRequest} [clientDTOGenerateNewsletterIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateNewsletterIdea(clientDTOGenerateNewsletterIdeaRequest?: ClientDTOGenerateNewsletterIdeaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateNewsletterIdeaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateNewsletterIdea(clientDTOGenerateNewsletterIdeaRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateNewsletterIdea']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentRequest} [clientDTOGenerateSocialMediaContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateSocialMediaContent(clientDTOGenerateSocialMediaContentRequest?: ClientDTOGenerateSocialMediaContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateSocialMediaContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateSocialMediaContent(clientDTOGenerateSocialMediaContentRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateSocialMediaContent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentHeadlineRequest} [clientDTOGenerateSocialMediaContentHeadlineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateSocialMediaContentHeadline(clientDTOGenerateSocialMediaContentHeadlineRequest?: ClientDTOGenerateSocialMediaContentHeadlineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateSocialMediaContentHeadlineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateSocialMediaContentHeadline(clientDTOGenerateSocialMediaContentHeadlineRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateSocialMediaContentHeadline']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentIdeaRequest} [clientDTOGenerateSocialMediaContentIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateSocialMediaContentIdea(clientDTOGenerateSocialMediaContentIdeaRequest?: ClientDTOGenerateSocialMediaContentIdeaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateSocialMediaContentIdeaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateSocialMediaContentIdea(clientDTOGenerateSocialMediaContentIdeaRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateSocialMediaContentIdea']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentStrategyRequest} [clientDTOGenerateSocialMediaContentStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentGenerateSocialMediaContentStrategy(clientDTOGenerateSocialMediaContentStrategyRequest?: ClientDTOGenerateSocialMediaContentStrategyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGenerateSocialMediaContentStrategyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentGenerateSocialMediaContentStrategy(clientDTOGenerateSocialMediaContentStrategyRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentGenerateSocialMediaContentStrategy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOOptimizeBlogContentRequest} [clientDTOOptimizeBlogContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientContentOptimizeBlogContent(clientDTOOptimizeBlogContentRequest?: ClientDTOOptimizeBlogContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOOptimizeBlogContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientContentOptimizeBlogContent(clientDTOOptimizeBlogContentRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientContentOptimizeBlogContent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Sends the feed processing request
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDashboardFixFeedUrlRequest} [clientDashboardFixFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardFixFeedUrl(clientDashboardFixFeedUrlRequest?: ClientDashboardFixFeedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardFixFeedUrl(clientDashboardFixFeedUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardFixFeedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetActiveCampaignFormats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetActiveCampaignFormatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetActiveCampaignFormats(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetActiveCampaignFormats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets last feed state
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetFeedState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetFeedStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetFeedState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetFeedState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets the last feed processing error
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetLastFeedError(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetLastFeedErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetLastFeedError(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetLastFeedError']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets number of learning phase orders. Learning phase orders are orders from all campaigns made in last 30 days.They are located in <b>Overview of your campaigns</b> section of <b>Dashboard</b> page
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetLearningPhaseOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetLearningPhaseOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetLearningPhaseOrders(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetLearningPhaseOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Checks if all steps of order measuring are set up
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetPixelMeasuringCondition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetPixelMeasuringConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetPixelMeasuringCondition(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetPixelMeasuringCondition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets the product condition from the DB for given eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetProductsConditionCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetProductsConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetProductsConditionCount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetProductsConditionCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets the product issues summary given eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDashboardGetProductsIssuesSummaryRequest} [clientDashboardGetProductsIssuesSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetProductsIssuesSummary(clientDashboardGetProductsIssuesSummaryRequest?: ClientDashboardGetProductsIssuesSummaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetProductsIssuesSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetProductsIssuesSummary(clientDashboardGetProductsIssuesSummaryRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetProductsIssuesSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets remaining trial period for eshop with eshop ID provided in JWT
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetRemainingTrialPeriod(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetRemainingTrialPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetRemainingTrialPeriod(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetRemainingTrialPeriod']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetSetupStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetSetupStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetSetupStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetSetupStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets campaign statistic data for last 30 days that are located in section basic statistics in dashboard.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetStatistics(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetStatistics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get the percentage of successfully measured customers of the eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetSuccessfullyMeasuredCustomersPercentage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetSuccessfullyMeasuredCustomersPercentageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetSuccessfullyMeasuredCustomersPercentage(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetSuccessfullyMeasuredCustomersPercentage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the current tanganica contribution percentage for the eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDashboardGetTgncaContributionPercentage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDashboardGetTgncaContributionPercentageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDashboardGetTgncaContributionPercentage(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientDashboardGetTgncaContributionPercentage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Returns only one eshop with same data structure as in GetEshops above. Designed to be used for admin LoginAs functionality only.
         * @summary Policies: RequireInternalEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopListGetEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopListGetEshopResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopListGetEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopListGetEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Returns a overview of eshops the user has. Including his roles in the eshops. Designed to be used for active eshop selection.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopListGetEshops(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopListGetEshopsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopListGetEshops(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopListGetEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsCreateEshopDescription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsCreateEshopDescription(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsCreateEshopDescription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetCosMax(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetCosMaxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetCosMax(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetCosMax']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetCreditNotificationLimit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetCreditNotificationLimitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetCreditNotificationLimit(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetCreditNotificationLimit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetEshopDescription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetEshopDescriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetEshopDescription(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetEshopDescription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetFeedState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetFeedStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetFeedState(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetFeedState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetNotificationEmail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetNotificationEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetNotificationEmail(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetNotificationEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets pixel status of current eshop.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetPixelStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetPixelStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetPixelStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetPixelStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetProductFeedUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetProductFeedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetProductFeedUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetProductFeedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsGetUsersWithAccess(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientEshopSettingsGetUsersWithAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsGetUsersWithAccess(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsGetUsersWithAccess']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> If invited user is alread registered he is linked to given eshop with given role - (only notification email is sent (?) ).If the invited email is not in the database, mail is sent to the given email address with link containing special token. This link redirects user to FE for registration, where email input is already set from the token<h3>Requirements</h3><ul><li><b>redirectUrl</b>: url to redirect user to with InviteToken - the url is sent to user by email - form on this url will call the RegisterInvited endpoint. The provided url will be appended with JWT token as follows: \"{redirectUrl}?InviteToken={token}\"</li></ul>
         * @summary Policies: , RequireEshopAccessToken
         * @param {ClientEshopSettingsInviteUserToEshopRequest} [clientEshopSettingsInviteUserToEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsInviteUser(clientEshopSettingsInviteUserToEshopRequest?: ClientEshopSettingsInviteUserToEshopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsInviteUser(clientEshopSettingsInviteUserToEshopRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsInviteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsRemoveUserFromEshopRequest} [clientEshopSettingsRemoveUserFromEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsRemoveUserFromEshop(clientEshopSettingsRemoveUserFromEshopRequest?: ClientEshopSettingsRemoveUserFromEshopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsRemoveUserFromEshop(clientEshopSettingsRemoveUserFromEshopRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsRemoveUserFromEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetCosMaxRequest} [clientEshopSettingsSetCosMaxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsSetCosMax(clientEshopSettingsSetCosMaxRequest?: ClientEshopSettingsSetCosMaxRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsSetCosMax(clientEshopSettingsSetCosMaxRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsSetCosMax']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetCreditNotificationLimitRequest} [clientEshopSettingsSetCreditNotificationLimitRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsSetCreditNotificationLimit(clientEshopSettingsSetCreditNotificationLimitRequest?: ClientEshopSettingsSetCreditNotificationLimitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsSetCreditNotificationLimit(clientEshopSettingsSetCreditNotificationLimitRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsSetCreditNotificationLimit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetNotificationEmailRequest} [clientEshopSettingsSetNotificationEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsSetNotificationEmail(clientEshopSettingsSetNotificationEmailRequest?: ClientEshopSettingsSetNotificationEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsSetNotificationEmail(clientEshopSettingsSetNotificationEmailRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsSetNotificationEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetProductFeedUrlRequest} [clientEshopSettingsSetProductFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientEshopSettingsSetProductFeedUrl(clientEshopSettingsSetProductFeedUrlRequest?: ClientEshopSettingsSetProductFeedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientEshopSettingsSetProductFeedUrl(clientEshopSettingsSetProductFeedUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientEshopSettingsSetProductFeedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the Feed processing logs
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientLogsGetUserFeedLogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetUserFeedLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientLogsGetUserFeedLogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientLogsGetUserFeedLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets billing info for form prefill.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPaymentHistoryGetBillingSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientPaymentHistoryGetBillingSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPaymentHistoryGetBillingSettings(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientPaymentHistoryGetBillingSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets the presigned url to the invoice pdf stored in Spaces.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientPaymentHistoryGetInvoicePdfUrlRequest} [clientPaymentHistoryGetInvoicePdfUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPaymentHistoryGetInvoicePdfUrl(clientPaymentHistoryGetInvoicePdfUrlRequest?: ClientPaymentHistoryGetInvoicePdfUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientPaymentHistoryGetInvoicePdfUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPaymentHistoryGetInvoicePdfUrl(clientPaymentHistoryGetInvoicePdfUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientPaymentHistoryGetInvoicePdfUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets the list of all invoice documents of eshop in database.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPaymentHistoryGetPaymentHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientPaymentHistoryGetPaymentHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPaymentHistoryGetPaymentHistory(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientPaymentHistoryGetPaymentHistory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Used to save/update billing data.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientPaymentHistorySetBillingSettingsRequest} [clientPaymentHistorySetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPaymentHistorySetBillingSettings(clientPaymentHistorySetBillingSettingsRequest?: ClientPaymentHistorySetBillingSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPaymentHistorySetBillingSettings(clientPaymentHistorySetBillingSettingsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientPaymentHistorySetBillingSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Loads all categories for eshop products
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsGetCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsGetCategories(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsGetCategories']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets all Categories and their information
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsGetCategoriesData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetCategoriesDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsGetCategoriesData(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsGetCategoriesData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get status of Feed and Products
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsGetFeedStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetFeedStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsGetFeedStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsGetFeedStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Loads all issues for given product in current Eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductIssuesRequest} [clientDTOGetProductIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsGetProductIssues(clientDTOGetProductIssuesRequest?: ClientDTOGetProductIssuesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetProductIssuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsGetProductIssues(clientDTOGetProductIssuesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsGetProductIssues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Loads all products for Eshop according to given sort and filters
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductsRequest} [clientDTOGetProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsGetProducts(clientDTOGetProductsRequest?: ClientDTOGetProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsGetProducts(clientDTOGetProductsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsGetProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Get the summary of products and their status
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsGetProductsSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetProductsSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsGetProductsSummary(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsGetProductsSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Sets the advertisement for given Category and changes Category attributes
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetCategoryAdvertisementRequest} [clientDTOSetCategoryAdvertisementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsSetCategoryAdvertisement(clientDTOSetCategoryAdvertisementRequest?: ClientDTOSetCategoryAdvertisementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsSetCategoryAdvertisement(clientDTOSetCategoryAdvertisementRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsSetCategoryAdvertisement']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * The user sets whether the category should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetMultipleProductsAdvertisementStatusRequest} [clientDTOSetMultipleProductsAdvertisementStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsSetMultipleProductsAdvertisementStatus(clientDTOSetMultipleProductsAdvertisementStatusRequest?: ClientDTOSetMultipleProductsAdvertisementStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsSetMultipleProductsAdvertisementStatus(clientDTOSetMultipleProductsAdvertisementStatusRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsSetMultipleProductsAdvertisementStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * The user sets whether the product should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetProductAdvertisementStatusRequest} [clientDTOSetProductAdvertisementStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientProductsSetProductAdvertisementStatus(clientDTOSetProductAdvertisementStatusRequest?: ClientDTOSetProductAdvertisementStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientProductsSetProductAdvertisementStatus(clientDTOSetProductAdvertisementStatusRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientProductsSetProductAdvertisementStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Initiates a bank transfer payment process within the application. When called, this endpoint does not perform a real financial transaction but generates a proforma invoice based on the user\'s selected credit amount. The response includes a presigned URL to access the generated invoice and a QR code string for payment facilitation.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOBankTransferPaymentRequest} [clientDTOBankTransferPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditBankTransferPayment(clientDTOBankTransferPaymentRequest?: ClientDTOBankTransferPaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOBankTransferPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditBankTransferPayment(clientDTOBankTransferPaymentRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditBankTransferPayment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates a new checkout session for processing payments. Session is returned as clientSecret that have to be used to get the session through Stripe API.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOCreateCheckoutRequest} [clientDTOCreateCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditCreateCheckout(clientDTOCreateCheckoutRequest?: ClientDTOCreateCheckoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOCreateCheckoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditCreateCheckout(clientDTOCreateCheckoutRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditCreateCheckout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates a new checkout setup session for saving cards. Session is returned as clientSecret that have to be used to get the session through Stripe API.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOCreateSetupRequest} [clientDTOCreateSetupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditCreateSetup(clientDTOCreateSetupRequest?: ClientDTOCreateSetupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOCreateCheckoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditCreateSetup(clientDTOCreateSetupRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditCreateSetup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditGetAutomaticCreditRechargeOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetAutomaticCreditRechargeOptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditGetAutomaticCreditRechargeOptions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditGetAutomaticCreditRechargeOptions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditGetAutomaticCreditRechargeStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetAutomaticCreditRechargeStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditGetAutomaticCreditRechargeStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditGetAutomaticCreditRechargeStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditGetEshopTaxRate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetEshopTaxRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditGetEshopTaxRate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditGetEshopTaxRate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns default amount for payments with currency of provided eshop in Token.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditGetPaymentDefaultAmount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetPaymentDefaultAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditGetPaymentDefaultAmount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditGetPaymentDefaultAmount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets checkout sessions response status and email of customer specified by provided session ID.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetSessionStatusRequest} [clientDTOGetSessionStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditGetSessionStatus(clientDTOGetSessionStatusRequest?: ClientDTOGetSessionStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetSessionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditGetSessionStatus(clientDTOGetSessionStatusRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditGetSessionStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns true if user present in Token has saved card.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditHasSavedCard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOHasSavedCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditHasSavedCard(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditHasSavedCard']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetAutomaticCreditRechargeOptionsRequest} [clientDTOSetAutomaticCreditRechargeOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditSetAutomaticCreditRechargeOptions(clientDTOSetAutomaticCreditRechargeOptionsRequest?: ClientDTOSetAutomaticCreditRechargeOptionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditSetAutomaticCreditRechargeOptions(clientDTOSetAutomaticCreditRechargeOptionsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditSetAutomaticCreditRechargeOptions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetAutomaticCreditRechargeStatusRequest} [clientDTOSetAutomaticCreditRechargeStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRechargeCreditSetAutomaticCreditRechargeStatus(clientDTOSetAutomaticCreditRechargeStatusRequest?: ClientDTOSetAutomaticCreditRechargeStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRechargeCreditSetAutomaticCreditRechargeStatus(clientDTOSetAutomaticCreditRechargeStatusRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientRechargeCreditSetAutomaticCreditRechargeStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportCampaignStatsRequest} [clientReportExportCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientReportExportCampaignStats(clientReportExportCampaignStatsRequest?: ClientReportExportCampaignStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReportExportCampaignStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientReportExportCampaignStats(clientReportExportCampaignStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientReportExportCampaignStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportOrderStatsRequest} [clientReportExportOrderStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientReportExportOrderStats(clientReportExportOrderStatsRequest?: ClientReportExportOrderStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReportExportOrderStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientReportExportOrderStats(clientReportExportOrderStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientReportExportOrderStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportProductCategoryStatsRequest} [clientReportExportProductCategoryStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientReportExportProductCategoryStats(clientReportExportProductCategoryStatsRequest?: ClientReportExportProductCategoryStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReportExportProductCategoryStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientReportExportProductCategoryStats(clientReportExportProductCategoryStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientReportExportProductCategoryStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportProductStatsRequest} [clientReportExportProductStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientReportExportProductStats(clientReportExportProductStatsRequest?: ClientReportExportProductStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReportExportProductStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientReportExportProductStats(clientReportExportProductStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientReportExportProductStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportTrafficStatsRequest} [clientReportExportTrafficStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientReportExportTrafficStats(clientReportExportTrafficStatsRequest?: ClientReportExportTrafficStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReportExportTrafficStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientReportExportTrafficStats(clientReportExportTrafficStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientReportExportTrafficStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportVisitSourceStatsRequest} [clientReportExportVisitSourceStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientReportExportVisitSourceStats(clientReportExportVisitSourceStatsRequest?: ClientReportExportVisitSourceStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReportExportVisitSourceStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientReportExportVisitSourceStats(clientReportExportVisitSourceStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientReportExportVisitSourceStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets data for client/report page in app.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetCampaignStatsRequest} [clientDTOGetCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetCampaignStats(clientDTOGetCampaignStatsRequest?: ClientDTOGetCampaignStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetCampaignStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetCampaignStats(clientDTOGetCampaignStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetCampaignStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetCampaignStatsGraphDataRequest} [clientDTOGetCampaignStatsGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetCampaignStatsGraphData(clientDTOGetCampaignStatsGraphDataRequest?: ClientDTOGetCampaignStatsGraphDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetCampaignStatsGraphDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetCampaignStatsGraphData(clientDTOGetCampaignStatsGraphDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetCampaignStatsGraphData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetGmvTrendChartDataRequest} [clientDTOGetGmvTrendChartDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetGmvTrendChartData(clientDTOGetGmvTrendChartDataRequest?: ClientDTOGetGmvTrendChartDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetGmvTrendChartDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetGmvTrendChartData(clientDTOGetGmvTrendChartDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetGmvTrendChartData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetOrderStatsRequest} [clientDTOGetOrderStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetOrderStats(clientDTOGetOrderStatsRequest?: ClientDTOGetOrderStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetOrderStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetOrderStats(clientDTOGetOrderStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetOrderStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductCategoryStatsRequest} [clientDTOGetProductCategoryStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetProductCategoryStats(clientDTOGetProductCategoryStatsRequest?: ClientDTOGetProductCategoryStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetProductCategoryStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetProductCategoryStats(clientDTOGetProductCategoryStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetProductCategoryStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductStatsRequest} [clientDTOGetProductStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetProductStats(clientDTOGetProductStatsRequest?: ClientDTOGetProductStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetProductStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetProductStats(clientDTOGetProductStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetProductStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetTrafficStatsRequest} [clientDTOGetTrafficStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetTrafficStats(clientDTOGetTrafficStatsRequest?: ClientDTOGetTrafficStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetTrafficStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetTrafficStats(clientDTOGetTrafficStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetTrafficStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetVisitSourceStatsRequest} [clientDTOGetVisitSourceStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientStatisticsGetVisitSourceStats(clientDTOGetVisitSourceStatsRequest?: ClientDTOGetVisitSourceStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetVisitSourceStatsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientStatisticsGetVisitSourceStats(clientDTOGetVisitSourceStatsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientStatisticsGetVisitSourceStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Endpoint for Contact Us tab of the app
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientSurveySendContactFormRequest} [clientSurveySendContactFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientSurveySendContactForm(clientSurveySendContactFormRequest?: ClientSurveySendContactFormRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientSurveySendContactForm(clientSurveySendContactFormRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientSurveySendContactForm']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves the current credit for a specific e-shop using the provided e-shop ID from access token.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientTopMenuGetCredit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetCreditResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientTopMenuGetCredit(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientTopMenuGetCredit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserAffiliateGetAffilContributionForAllOwnedEshops(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetAffilContributionForAllOwnedEshopsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserAffiliateGetAffilContributionForAllOwnedEshops(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserAffiliateGetAffilContributionForAllOwnedEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserAffiliateGetCompanyInfoForAffilInvoice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTOGetCompanyInfoForAffilInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserAffiliateGetCompanyInfoForAffilInvoice(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserAffiliateGetCompanyInfoForAffilInvoice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Sets the user\'s email to what is in the input. Sends an email with confirmation link. Confirmation link calls the ConfirmEmailChange enspoint in CallbacksController
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsChangeEmailRequest} [clientUserSettingsChangeEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserSettingsChangeEmail(clientUserSettingsChangeEmailRequest?: ClientUserSettingsChangeEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserSettingsChangeEmail(clientUserSettingsChangeEmailRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserSettingsChangeEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> This endpoint allows authenticated users to change their password. It requires the user to provide their current (old) password, along with the desired new password and a confirmation of the new password. The endpoint verifies the old password, and if it matches the user\'s current password and the new password and confirmation match, the user\'s password will be updated.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsChangePasswordRequest} [clientUserSettingsChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserSettingsChangePassword(clientUserSettingsChangePasswordRequest?: ClientUserSettingsChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserSettingsChangePassword(clientUserSettingsChangePasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserSettingsChangePassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets user\'s name.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserSettingsGetName(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserSettingsGetNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserSettingsGetName(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserSettingsGetName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets user\'s phone number.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserSettingsGetPhoneNumber(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserSettingsGetPhoneNumberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserSettingsGetPhoneNumber(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserSettingsGetPhoneNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> The user\'s location will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsLocaleRequest} [clientUserSettingsLocaleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserSettingsSetLocale(clientUserSettingsLocaleRequest?: ClientUserSettingsLocaleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserSettingsSetLocale(clientUserSettingsLocaleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserSettingsSetLocale']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> The user\'s name will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsSetNameRequest} [clientUserSettingsSetNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserSettingsSetName(clientUserSettingsSetNameRequest?: ClientUserSettingsSetNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserSettingsSetName(clientUserSettingsSetNameRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserSettingsSetName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> The user\'s phone number will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsSetPhoneNumberRequest} [clientUserSettingsSetPhoneNumberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUserSettingsSetPhoneNumber(clientUserSettingsSetPhoneNumberRequest?: ClientUserSettingsSetPhoneNumberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUserSettingsSetPhoneNumber(clientUserSettingsSetPhoneNumberRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientUserSettingsSetPhoneNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Creates new eshop in the database and links it with user. Given user has role EshopOwner in created eshop.Returns Id of newly created eshop. Use it to request eshop specific access token 😉.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientWizardAddEshopRequest} [clientWizardAddEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardAddEshop(clientWizardAddEshopRequest?: ClientWizardAddEshopRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWizardAddEshopResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardAddEshop(clientWizardAddEshopRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardAddEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Provides the pixel status. Return only true for testing. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardCheckPixel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWizardCheckPixelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardCheckPixel(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardCheckPixel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets billing info for form prefill. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardGetBillingSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWizardGetBillingSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardGetBillingSettings(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardGetBillingSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets basic eshop info for AddEshop form prefill in wizard. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardGetEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWizardGetEshopResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardGetEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardGetEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the Url of the eshop - used for redirection while pixel verifing process
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardGetEshopUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWizardGetEshopUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardGetEshopUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardGetEshopUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Gets the eshop\'s product feed url from db. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardGetProductFeedUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWizardGetProductFeedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardGetProductFeedUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardGetProductFeedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Gets remaining trial period for eshop with eshop ID provided in JWT
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardGetRemainingTrialPeriod(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientWizardGetRemainingTrialPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardGetRemainingTrialPeriod(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardGetRemainingTrialPeriod']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Create the neccessities for Eshop to function and process its data.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardInitEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardInitEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardInitEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Used to save/update billing data. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientWizardSetBillingSettingsRequest} [clientWizardSetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardSetBillingSettings(clientWizardSetBillingSettingsRequest?: ClientWizardSetBillingSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardSetBillingSettings(clientWizardSetBillingSettingsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardSetBillingSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Used to set the url for the product feed. Given feed link is checked and if correct, saved to DB.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientWizardSetProductFeedUrlRequest} [clientWizardSetProductFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardSetProductFeedUrl(clientWizardSetProductFeedUrlRequest?: ClientWizardSetProductFeedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardSetProductFeedUrl(clientWizardSetProductFeedUrlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardSetProductFeedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * <h3>Description</h3> Updates users Name and PhoneNumber. This endpoint is called when user adds his first eshop right after he registers into the app.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientWizardUpdateUserRequest} [clientWizardUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientWizardUpdateUser(clientWizardUpdateUserRequest?: ClientWizardUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientWizardUpdateUser(clientWizardUpdateUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.clientWizardUpdateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronAutomaticCreditTopUp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronAutomaticCreditTopUp(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronAutomaticCreditTopUp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronBackupLogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronBackupLogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronBackupLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronCampaignDependenciesCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronCampaignDependenciesCheck(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronCampaignDependenciesCheck']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronCampaignStatusesComparison(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronCampaignStatusesComparison(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronCampaignStatusesComparison']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronCheckForMissingAppImports(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronCheckForMissingAppImports(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronCheckForMissingAppImports']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronCleanProductCronLogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronCleanProductCronLogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronCleanProductCronLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronCountGmcSubaccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronCountGmcSubaccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronCountGmcSubaccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronEshopsContributionList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronEshopsContributionList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronEshopsContributionList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportCampaignHashes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportCampaignHashes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportCampaignHashes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportCampaignPerformanceData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportCampaignPerformanceData(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportCampaignPerformanceData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportEshopMarketingInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportEshopMarketingInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportEshopMarketingInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportEshopsActivityDuration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportEshopsActivityDuration(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportEshopsActivityDuration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportEshopsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportEshopsList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportEshopsList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportEshopsProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportEshopsProducts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportEshopsProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportInvoicesCsv(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportInvoicesCsv(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportInvoicesCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportInvoicesXml(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportInvoicesXml(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportInvoicesXml']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportInvoicesZip(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportInvoicesZip(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportInvoicesZip']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportPartnershipReportLastMonth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportPartnershipReportLastMonth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportPartnershipReportLastMonth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronExportPartnershipReportThisMonth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronExportPartnershipReportThisMonth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronExportPartnershipReportThisMonth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {CronCronFillDemoDataRequest} [cronCronFillDemoDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronFillDemoData(cronCronFillDemoDataRequest?: CronCronFillDemoDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronFillDemoData(cronCronFillDemoDataRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronFillDemoData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronGCLIDsExport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronGCLIDsExport(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronGCLIDsExport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronImportCampaignStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronImportCampaignStatistics(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronImportCampaignStatistics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronImportExchangeRates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronImportExchangeRates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronImportExchangeRates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronImportOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronImportOrders(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronImportOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronImportOrdersYesterday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronImportOrdersYesterday(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronImportOrdersYesterday']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronImportPixelEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronImportPixelEvents(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronImportPixelEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronImportPseudoUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronImportPseudoUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronImportPseudoUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronImportSessions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronImportSessions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronImportSessions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronProcessFeeds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronProcessFeeds(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronProcessFeeds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronProcessProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronProcessProducts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronProcessProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronSendNoCreditSequenceMails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronSendNoCreditSequenceMails(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronSendNoCreditSequenceMails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronSendWelcomeSequenceMails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronSendWelcomeSequenceMails(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronSendWelcomeSequenceMails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronUpdateEshopsDashboardStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronUpdateEshopsDashboardStatuses(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronUpdateEshopsDashboardStatuses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronUpdateEshopsLastTwoWeeksCos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronUpdateEshopsLastTwoWeeksCos(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronUpdateEshopsLastTwoWeeksCos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronUpdateEshopsTable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronUpdateEshopsTable(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronUpdateEshopsTable']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronUpdateProductsStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronUpdateProductsStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronUpdateProductsStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cronCronUploadProductsToPlatforms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cronCronUploadProductsToPlatforms(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.cronCronUploadProductsToPlatforms']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {EshopTanganicaProductsDTOGetAllProductsRequest} [eshopTanganicaProductsDTOGetAllProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopTanganicaProductsGetAllProducts(eshopTanganicaProductsDTOGetAllProductsRequest?: EshopTanganicaProductsDTOGetAllProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EshopTanganicaProductsDTOGetAllProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopTanganicaProductsGetAllProducts(eshopTanganicaProductsDTOGetAllProductsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.eshopTanganicaProductsGetAllProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {EshopTanganicaProductsDTOGetProductDetailRequest} [eshopTanganicaProductsDTOGetProductDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eshopTanganicaProductsGetProductDetail(eshopTanganicaProductsDTOGetProductDetailRequest?: EshopTanganicaProductsDTOGetProductDetailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EshopTanganicaProductsDTOGetProductDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eshopTanganicaProductsGetProductDetail(eshopTanganicaProductsDTOGetProductDetailRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.eshopTanganicaProductsGetProductDetail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceFeedsManualProcessAllAvailableFeeds(useProxy?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceFeedsManualProcessAllAvailableFeeds(useProxy, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceFeedsManualProcessAllAvailableFeeds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {MaintenanceDTOProcessFeedsForGivenEshopsRequest} [maintenanceDTOProcessFeedsForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceFeedsManualProcessFeedsForGivenEshops(useProxy?: boolean, maintenanceDTOProcessFeedsForGivenEshopsRequest?: MaintenanceDTOProcessFeedsForGivenEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceFeedsManualProcessFeedsForGivenEshops(useProxy, maintenanceDTOProcessFeedsForGivenEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceFeedsManualProcessFeedsForGivenEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {MaintenanceDTOProcessFeedsForManyGivenEshopsRequest} [maintenanceDTOProcessFeedsForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceFeedsManualProcessFeedsForManyGivenEshops(useProxy?: boolean, maintenanceDTOProcessFeedsForManyGivenEshopsRequest?: MaintenanceDTOProcessFeedsForManyGivenEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceFeedsManualProcessFeedsForManyGivenEshops(useProxy, maintenanceDTOProcessFeedsForManyGivenEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceFeedsManualProcessFeedsForManyGivenEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceFeedsManualSynchronizeFeedProductsForEshop(eshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceFeedsManualSynchronizeFeedProductsForEshop(eshopId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceFeedsManualSynchronizeFeedProductsForEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceAnonymizeUser(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceAnonymizeUser(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceAnonymizeUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOChangeOrderContributionRequest} [maintenanceDTOChangeOrderContributionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceChangeOrderContribution(maintenanceDTOChangeOrderContributionRequest?: MaintenanceDTOChangeOrderContributionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceChangeOrderContribution(maintenanceDTOChangeOrderContributionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceChangeOrderContribution']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceCreateMissingFBCatalogsNew(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceCreateMissingFBCatalogsNew(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceCreateMissingFBCatalogsNew']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceCreateMissingGoogleSubaccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceCreateMissingGoogleSubaccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceCreateMissingGoogleSubaccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceCreateMissingGoogleSubaccountsNew(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceCreateMissingGoogleSubaccountsNew(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceCreateMissingGoogleSubaccountsNew']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOCurrencyChangeBackRequest} [maintenanceDTOCurrencyChangeBackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceCurrencyChangeBack(maintenanceDTOCurrencyChangeBackRequest?: MaintenanceDTOCurrencyChangeBackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceCurrencyChangeBack(maintenanceDTOCurrencyChangeBackRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceCurrencyChangeBack']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTODeleteOrderRequest} [maintenanceDTODeleteOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceDeleteOrder(maintenanceDTODeleteOrderRequest?: MaintenanceDTODeleteOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceDeleteOrder(maintenanceDTODeleteOrderRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceDeleteOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceEliminateSubaccountDuplicates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceEliminateSubaccountDuplicates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceEliminateSubaccountDuplicates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceGetCronToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceGetCronToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceGetCronToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceGetDevToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceGetDevToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceGetDevToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceImportExchangeRates(date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceImportExchangeRates(date, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceImportExchangeRates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceMigrateExchangeRates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceMigrateExchangeRates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceMigrateExchangeRates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceSwitchFBCampaignsIds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceSwitchFBCampaignsIds(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceSwitchFBCampaignsIds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMaintenanceTestLog(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMaintenanceTestLog(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceMaintenanceTestLog']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceProductsManualUpdateProductsStatusForGivenEshops(maintenanceDTOUpdateProductsStatusForGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceProductsManualUpdateProductsStatusForGivenEshops(maintenanceDTOUpdateProductsStatusForGivenEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceProductsManualUpdateProductsStatusForGivenEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceProductsManualUpdateProductsStatusForManyGivenEshops(maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceProductsManualUpdateProductsStatusForManyGivenEshops(maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceProductsManualUpdateProductsStatusForManyGivenEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUploadProductsForManyGivenEshopsRequest} [maintenanceDTOUploadProductsForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceProductsManualUploadProductsForManyGivenEshops(maintenanceDTOUploadProductsForManyGivenEshopsRequest?: MaintenanceDTOUploadProductsForManyGivenEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceProductsManualUploadProductsForManyGivenEshops(maintenanceDTOUploadProductsForManyGivenEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceProductsManualUploadProductsForManyGivenEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUploadProductsForGivenEshopsRequest} [maintenanceDTOUploadProductsForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceProductsManualUploadProductsGivenEshops(maintenanceDTOUploadProductsForGivenEshopsRequest?: MaintenanceDTOUploadProductsForGivenEshopsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceProductsManualUploadProductsGivenEshops(maintenanceDTOUploadProductsForGivenEshopsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.maintenanceProductsManualUploadProductsGivenEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestFillMissingMicrosoftSettingsTables(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestFillMissingMicrosoftSettingsTables(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestFillMissingMicrosoftSettingsTables']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestMiskoTemp(eshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestMiskoTemp(eshopId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestMiskoTemp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [accountName] 
         * @param {number} [parentCustomerId] 
         * @param {number} [superAdminId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsAddAccount(accountName?: string, parentCustomerId?: number, superAdminId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsAddAccount(accountName, parentCustomerId, superAdminId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsAddAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [adGroupId] 
         * @param {CampaignManagementAd} [campaignManagementAd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsAddAd(accountId?: number, adGroupId?: number, campaignManagementAd?: CampaignManagementAd, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsAddAd(accountId, adGroupId, campaignManagementAd, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsAddAd']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {boolean} [returnInheritedBidStrategyTypes] 
         * @param {CampaignManagementAdGroup} [campaignManagementAdGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsAddAdGroup(accountId?: number, campaignId?: number, returnInheritedBidStrategyTypes?: boolean, campaignManagementAdGroup?: CampaignManagementAdGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsAddAdGroup(accountId, campaignId, returnInheritedBidStrategyTypes, campaignManagementAdGroup, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsAddAdGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementAssetGroup} [campaignManagementAssetGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsAddAssetGroup(accountId?: number, campaignId?: number, campaignManagementAssetGroup?: CampaignManagementAssetGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsAddAssetGroup(accountId, campaignId, campaignManagementAssetGroup, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsAddAssetGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsAddCampaign(accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsAddCampaign(accountId, campaignManagementCampaign, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsAddCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaignCriterionType} [criterionType] 
         * @param {Array<CampaignManagementCampaignCriterion>} [campaignManagementCampaignCriterion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsAddCampaignCriterions(accountId?: number, criterionType?: CampaignManagementCampaignCriterionType, campaignManagementCampaignCriterion?: Array<CampaignManagementCampaignCriterion>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsAddCampaignCriterions(accountId, criterionType, campaignManagementCampaignCriterion, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsAddCampaignCriterions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [imagePath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsAddMedia(accountId?: number, imagePath?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsAddMedia(accountId, imagePath, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsAddMedia']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {string} [countryCode] 
         * @param {string} [description1] 
         * @param {string} [description2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsCreateDSA(accountId?: number, marketingId?: number, domainName?: string, countryCode?: string, description1?: string, description2?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsCreateDSA(accountId, marketingId, domainName, countryCode, description1, description2, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsCreateDSA']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [countryCode] 
         * @param {number} [storeId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsCreatePLA(accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsCreatePLA(accountId, countryCode, storeId, marketingId, domainName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsCreatePLA']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [countryCode] 
         * @param {number} [storeId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {string} [businessName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsCreatePRX(accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, businessName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsCreatePRX(accountId, countryCode, storeId, marketingId, domainName, businessName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsCreatePRX']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsDeleteAccount(accountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsDeleteAccount(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsDeleteAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsDeleteCampaign(accountId?: number, campaignId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsDeleteCampaign(accountId, campaignId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsDeleteCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CustomerManagementAccountAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetAccount(accountId?: number, returnAdditionalFields?: CustomerManagementAccountAdditionalField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetAccount(accountId, returnAdditionalFields, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementAdGroupAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetAdGroupsByCampaignId(accountId?: number, campaignId?: number, returnAdditionalFields?: CampaignManagementAdGroupAdditionalField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetAdGroupsByCampaignId(accountId, campaignId, returnAdditionalFields, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetAdGroupsByCampaignId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [adGroupId] 
         * @param {CampaignManagementAdType} [adType] 
         * @param {CampaignManagementAdAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetAdsByAdGroupId(accountId?: number, adGroupId?: number, adType?: CampaignManagementAdType, returnAdditionalFields?: CampaignManagementAdAdditionalField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetAdsByAdGroupId(accountId, adGroupId, adType, returnAdditionalFields, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetAdsByAdGroupId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementCampaignType} [campaignType] 
         * @param {CampaignManagementCampaignAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetCampaign(accountId?: number, campaignId?: number, campaignType?: CampaignManagementCampaignType, returnAdditionalFields?: CampaignManagementCampaignAdditionalField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetCampaign(accountId, campaignId, campaignType, returnAdditionalFields, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementCampaignCriterionType} [criterionType] 
         * @param {CampaignManagementCriterionAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetCampaignCriterionsByIds(accountId?: number, campaignId?: number, criterionType?: CampaignManagementCampaignCriterionType, returnAdditionalFields?: CampaignManagementCriterionAdditionalField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetCampaignCriterionsByIds(accountId, campaignId, criterionType, returnAdditionalFields, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetCampaignCriterionsByIds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaignType} [campaignType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetCampaignsByAccountId(accountId?: number, campaignType?: CampaignManagementCampaignType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetCampaignsByAccountId(accountId, campaignType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetCampaignsByAccountId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetGeoLocationsFileUrl(accountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetGeoLocationsFileUrl(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetGeoLocationsFileUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {AdInsightEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId(accountId?: number, entityType?: AdInsightEntityType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId(accountId, entityType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsGetUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsGetUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsGetUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsSearchAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsSearchAccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsSearchAccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ReportingCampaignPerformanceReportRequest} [reportingCampaignPerformanceReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsSubmitGenerateReport(reportingCampaignPerformanceReportRequest?: ReportingCampaignPerformanceReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsSubmitGenerateReport(reportingCampaignPerformanceReportRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsSubmitGenerateReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [newName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsUpdateAccount(accountId?: number, newName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsUpdateAccount(accountId, newName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsUpdateAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingAdsUpdateCampaign(accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingAdsUpdateCampaign(accountId, campaignManagementCampaign, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingAdsUpdateCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestBingGetCampaignReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestBingGetCampaignReport(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestBingGetCampaignReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiBatchProducts(merchantId?: number, catalogId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiBatchProducts(merchantId, catalogId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiBatchProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiCreateCatalog(merchantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiCreateCatalog(merchantId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiCreateCatalog']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [storeName] 
         * @param {string} [storeUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiCreateStore(storeName?: string, storeUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiCreateStore(storeName, storeUrl, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiCreateStore']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiDeleteCatalog(merchantId?: number, catalogId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiDeleteCatalog(merchantId, catalogId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiDeleteCatalog']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiDeleteProduct(merchantId?: number, productId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiDeleteProduct(merchantId, productId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiDeleteProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetCatalog(merchantId?: number, catalogId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetCatalog(merchantId, catalogId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetCatalog']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetCatalogs(merchantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetCatalogs(merchantId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetCatalogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetProduct(merchantId?: number, productId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetProduct(merchantId, productId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetProducts(merchantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetProducts(merchantId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetProductsStatuses(merchantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetProductsStatuses(merchantId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetProductsStatuses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetProductsStatusesSummary(merchantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetProductsStatusesSummary(merchantId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetProductsStatusesSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetStore(merchantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetStore(merchantId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetStore']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiGetStores(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiGetStores(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiGetStores']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {string} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiInsertProduct(merchantId?: number, catalogId?: number, offerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiInsertProduct(merchantId, catalogId, offerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiInsertProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {boolean} [isPublishingEnabled] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestTestContentApiUpdateCatalog(merchantId?: number, catalogId?: number, isPublishingEnabled?: boolean, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestTestContentApiUpdateCatalog(merchantId, catalogId, isPublishingEnabled, name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestTestContentApiUpdateCatalog']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestUpdateCategoryAdvertiseMicrosoft(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestUpdateCategoryAdvertiseMicrosoft(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestUpdateCategoryAdvertiseMicrosoft']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testMTestUpdateProductMicrosoftStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testMTestUpdateProductMicrosoftStatus(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testMTestUpdateProductMicrosoftStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestAddAdminUser(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestAddAdminUser(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestAddAdminUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestAdminMailsToResx(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestAdminMailsToResx(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestAdminMailsToResx']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestAffilContrComparison(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestAffilContrComparison(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestAffilContrComparison']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestAnyCompensation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestAnyCompensation(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestAnyCompensation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestAssignDeletedOwner(guid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestAssignDeletedOwner(guid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestAssignDeletedOwner']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestBillingSettingsExporting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestBillingSettingsExporting(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestBillingSettingsExporting']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCHECKSTRIPECUSTOMERS(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCHECKSTRIPECUSTOMERS(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCHECKSTRIPECUSTOMERS']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCheckCreditTesting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCheckCreditTesting(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCheckCreditTesting']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCheckOwners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCheckOwners(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCheckOwners']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCheckTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCheckTransactions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCheckTransactions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCreateMissingTriggerFunctionsInTenants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCreateMissingTriggerFunctionsInTenants(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCreateMissingTriggerFunctionsInTenants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCreditComparison(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCreditComparison(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCreditComparison']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCreditComparisonGivenEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCreditComparisonGivenEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCreditComparisonGivenEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCreditComparisonInternal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCreditComparisonInternal(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCreditComparisonInternal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCreditPerEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCreditPerEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCreditPerEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestCreditTenantComparison(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestCreditTenantComparison(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestCreditTenantComparison']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestGetAllActiveBillingSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestGetAllActiveBillingSettings(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestGetAllActiveBillingSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestGetEshopsIsPayerOfTaxes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestGetEshopsIsPayerOfTaxes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestGetEshopsIsPayerOfTaxes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestGetInvalidBsEshops(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestGetInvalidBsEshops(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestGetInvalidBsEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestGetInvalidInvoices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestGetInvalidInvoices(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestGetInvalidInvoices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestGetSentMailNumbers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestGetSentMailNumbers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestGetSentMailNumbers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestInvoiceGenTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestInvoiceGenTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestInvoiceGenTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestLocalizerTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestLocalizerTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestLocalizerTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {TestPTestDTOLogCountsRequest} [testPTestDTOLogCountsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestLogCounts(testPTestDTOLogCountsRequest?: TestPTestDTOLogCountsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestLogCounts(testPTestDTOLogCountsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestLogCounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestLogsToFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestLogsToFiles(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestLogsToFiles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestMailTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestMailTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestMailTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Policies: ; AdminPermissions: GetKpiReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestPermissionAuthTesting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestPermissionAuthTesting(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestPermissionAuthTesting']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestPositiveAffilContrPerEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestPositiveAffilContrPerEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestPositiveAffilContrPerEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestRegenerateFuckedInvoices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestRegenerateFuckedInvoices(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestRegenerateFuckedInvoices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestRoleClaims(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestRoleClaims(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestRoleClaims']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSendAllAdminEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSendAllAdminEmails(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSendAllAdminEmails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSendAllClientEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSendAllClientEmails(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSendAllClientEmails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSendMail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSendMail(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSendMail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSendMailWithAttachment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSendMailWithAttachment(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSendMailWithAttachment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSetCreditNoteAmountsNegative(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSetCreditNoteAmountsNegative(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSetCreditNoteAmountsNegative']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSetNullMailLogAdminMailTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSetNullMailLogAdminMailTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSetNullMailLogAdminMailTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSetNullMailLogClientMailTypesNew(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSetNullMailLogClientMailTypesNew(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSetNullMailLogClientMailTypesNew']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSetNullMailLogClientMailTypesOld(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSetNullMailLogClientMailTypesOld(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSetNullMailLogClientMailTypesOld']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSetPassword(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSetPassword(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSetPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSetUserAdmin(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSetUserAdmin(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSetUserAdmin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestSyncInvoiceIssueDatesWithBs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestSyncInvoiceIssueDatesWithBs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestSyncInvoiceIssueDatesWithBs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestTestMailsRedesign(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestTestMailsRedesign(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestTestMailsRedesign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestTestUploadFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestTestUploadFile(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestTestUploadFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestUPDATESTRIPECUSTOMERS(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestUPDATESTRIPECUSTOMERS(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestUPDATESTRIPECUSTOMERS']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestUserClaimsRetrieval(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestUserClaimsRetrieval(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestUserClaimsRetrieval']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {TestPTestDTOUtcDateValidationRequest} [testPTestDTOUtcDateValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPTestUtcDateTimeValidation(testPTestDTOUtcDateValidationRequest?: TestPTestDTOUtcDateValidationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPTestUtcDateTimeValidation(testPTestDTOUtcDateValidationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPTestUtcDateTimeValidation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundAssignAdminRole(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundAssignAdminRole(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundAssignAdminRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundAssignAffilRole(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundAssignAffilRole(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundAssignAffilRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundAssignTestRole(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundAssignTestRole(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundAssignTestRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [batchSize] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundBulkInsertTest(batchSize?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundBulkInsertTest(batchSize, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundBulkInsertTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCampaignStatisticsDataRepair(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCampaignStatisticsDataRepair(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCampaignStatisticsDataRepair']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCheckFBCampaignsCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCheckFBCampaignsCount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCheckFBCampaignsCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCheckPixel(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCheckPixel(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCheckPixel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCleanSpacesBucket(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCleanSpacesBucket(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCleanSpacesBucket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCorrectUtmsManuallyForGivenMktIds(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCorrectUtmsManuallyForGivenMktIds(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCorrectUtmsManuallyForGivenMktIds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCreateAllFbCatalogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCreateAllFbCatalogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCreateAllFbCatalogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCreateFacebookCampaignTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCreateFacebookCampaignTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCreateFacebookCampaignTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCreateFbCampaigns(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCreateFbCampaigns(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCreateFbCampaigns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [folderPath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCreateFolderOnDO(folderPath?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCreateFolderOnDO(folderPath, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCreateFolderOnDO']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCreateMissingGoogleCampaigns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCreateMissingGoogleCampaigns(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCreateMissingGoogleCampaigns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundCreateMissingGoogleSubaccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundCreateMissingGoogleSubaccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundCreateMissingGoogleSubaccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDataComparingTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDataComparingTransactions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDataComparingTransactions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDataComparingTransactionsPerEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDataComparingTransactionsPerEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDataComparingTransactionsPerEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDbViewTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDbViewTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDbViewTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteAdCreative(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteAdCreative(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteAdCreative']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteAllAssistedOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteAllAssistedOrders(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteAllAssistedOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteBills(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteBills(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteBills']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteDPACampaignByDbId(eshopId?: string, campaignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteDPACampaignByDbId(eshopId, campaignId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteDPACampaignByDbId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteGmcProductsForEshops(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteGmcProductsForEshops(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteGmcProductsForEshops']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteOrdersFromBeforeMigration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteOrdersFromBeforeMigration(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteOrdersFromBeforeMigration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteOrdersFromDay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteOrdersFromDay(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteOrdersFromDay']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteOrdersWithPseudoUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteOrdersWithPseudoUserId(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteOrdersWithPseudoUserId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteStuff(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteStuff(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteStuff']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeleteTransactionsAndBills(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeleteTransactionsAndBills(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeleteTransactionsAndBills']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDeserializeTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDeserializeTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDeserializeTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundDownloadImageToLocal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundDownloadImageToLocal(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundDownloadImageToLocal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundEshopCreditUpdateTriggerToAllSchemas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundEshopCreditUpdateTriggerToAllSchemas(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundEshopCreditUpdateTriggerToAllSchemas']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundEshopIdTestWrite(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundEshopIdTestWrite(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundEshopIdTestWrite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundEshopSubaccountsCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundEshopSubaccountsCheck(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundEshopSubaccountsCheck']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFBDeleteWhatever(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFBDeleteWhatever(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFBDeleteWhatever']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFBItemStatus(catalogId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFBItemStatus(catalogId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFBItemStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFBRenameCatalogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFBRenameCatalogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFBRenameCatalogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFacebookCampaignStructureV3(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFacebookCampaignStructureV3(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFacebookCampaignStructureV3']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFbCampaignStatisticsFacebook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFbCampaignStatisticsFacebook(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFbCampaignStatisticsFacebook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFbCampaignsRestructure(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFbCampaignsRestructure(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFbCampaignsRestructure']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFbDeleteAdsetsOld(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFbDeleteAdsetsOld(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFbDeleteAdsetsOld']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFillAllAllowProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFillAllAllowProducts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFillAllAllowProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFillDailyBudgets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFillDailyBudgets(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFillDailyBudgets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFillFbCampaingMarketingFormat(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFillFbCampaingMarketingFormat(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFillFbCampaingMarketingFormat']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFindAllGmcProductErrors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFindAllGmcProductErrors(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFindAllGmcProductErrors']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFindEshopsWithMissingSubaccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFindEshopsWithMissingSubaccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFindEshopsWithMissingSubaccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [useCzkAdAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFindFbAdsUtmDuplicates(useCzkAdAccount?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFindFbAdsUtmDuplicates(useCzkAdAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFindFbAdsUtmDuplicates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFindFbCampaignsInfo(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFindFbCampaignsInfo(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFindFbCampaignsInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFindMarketingIds(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFindMarketingIds(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFindMarketingIds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFindMissingFBAds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFindMissingFBAds(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFindMissingFBAds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [logOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFixFbAdStatuses(logOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFixFbAdStatuses(logOnly, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFixFbAdStatuses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [marketingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFixFbAdUtms(marketingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFixFbAdUtms(marketingId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFixFbAdUtms']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFixGoogleCampaignStatisticsTimezone(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFixGoogleCampaignStatisticsTimezone(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFixGoogleCampaignStatisticsTimezone']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFixTaxRatesAndVariableSymbols(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFixTaxRatesAndVariableSymbols(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFixTaxRatesAndVariableSymbols']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFixTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFixTransactions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFixTransactions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundFuckIIIIIIIIIIIIT(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundFuckIIIIIIIIIIIIT(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundFuckIIIIIIIIIIIIT']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [productCount] 
         * @param {number} [feedCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGCTest(productCount?: number, feedCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGCTest(productCount, feedCount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGCTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [feedCount] 
         * @param {number} [taskCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGCTestParallelRealData(feedCount?: number, taskCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGCTestParallelRealData(feedCount, taskCount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGCTestParallelRealData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGarbageCollectorGeneration2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGarbageCollectorGeneration2(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGarbageCollectorGeneration2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGenerateEshopSpecificAccessToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGenerateEshopSpecificAccessToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGenerateEshopSpecificAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetActiveEshopsAndProductsCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetActiveEshopsAndProductsCount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetActiveEshopsAndProductsCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [adAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAdCreatives(adAccountId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAdCreatives(adAccountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAdCreatives']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAdspendsPerCampaign(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAdspendsPerCampaign(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAdspendsPerCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAllAdExpensesForDay(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAllAdExpensesForDay(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAllAdExpensesForDay']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAllCampaignsGoogle(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAllCampaignsGoogle(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAllCampaignsGoogle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAllFbCatalogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAllFbCatalogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAllFbCatalogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAllGmcSubaccountsCz(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAllGmcSubaccountsCz(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAllGmcSubaccountsCz']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAllGmcSubaccountsEu(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAllGmcSubaccountsEu(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAllGmcSubaccountsEu']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetAllUnknown(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetAllUnknown(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetAllUnknown']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetCampaignStatsTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetCampaignStatsTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetCampaignStatsTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetCampaignsWithMissingProductSets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetCampaignsWithMissingProductSets(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetCampaignsWithMissingProductSets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetCreditDifference(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetCreditDifference(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetCreditDifference']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetCreditExpensesDifference(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetCreditExpensesDifference(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetCreditExpensesDifference']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetCreditPerEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetCreditPerEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetCreditPerEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetDesiredStatusIssues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetDesiredStatusIssues(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetDesiredStatusIssues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetEshopIdsFromMktIds(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetEshopIdsFromMktIds(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetEshopIdsFromMktIds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetEshopsInfo(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetEshopsInfo(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetEshopsInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetExchangeRate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetExchangeRate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetExchangeRate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetFileFormatByHeader(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetFileFormatByHeader(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetFileFormatByHeader']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetGmcLimits(tanganicaEu?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetGmcLimits(tanganicaEu, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetGmcLimits']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {EnumsCountryOfAdvertisement} [country] 
         * @param {number} [merchantId] 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetGmcProductIssues(productId?: string, country?: EnumsCountryOfAdvertisement, merchantId?: number, tanganicaEu?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetGmcProductIssues(productId, country, merchantId, tanganicaEu, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetGmcProductIssues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetGmcSubaccountsCount(tanganicaEu?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetGmcSubaccountsCount(tanganicaEu, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetGmcSubaccountsCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [campaignId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetGoogleCampaignFormatTest(campaignId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetGoogleCampaignFormatTest(campaignId, customerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetGoogleCampaignFormatTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ClientControllersGetHourlyCostMicrosRequest} [clientControllersGetHourlyCostMicrosRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetHourlyCostMicros(clientControllersGetHourlyCostMicrosRequest?: ClientControllersGetHourlyCostMicrosRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetHourlyCostMicros(clientControllersGetHourlyCostMicrosRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetHourlyCostMicros']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetLookalikeAudienceByOriginAudienceId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetLookalikeAudienceByOriginAudienceId(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetLookalikeAudienceByOriginAudienceId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetOrderDataTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetOrderDataTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetOrderDataTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetOrdersPerCampaignsPerEshop(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetOrdersPerCampaignsPerEshop(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetOrdersPerCampaignsPerEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGetSubaccountIdForEshop(eshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGetSubaccountIdForEshop(eshopId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGetSubaccountIdForEshop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [campaignId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundGoogleAdsStatsTest(customerId?: number, campaignId?: number, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundGoogleAdsStatsTest(customerId, campaignId, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundGoogleAdsStatsTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundHttpClientTest(url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundHttpClientTest(url, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundHttpClientTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundImportAppImportsRecords(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundImportAppImportsRecords(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundImportAppImportsRecords']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundImportDataFroNewlyMigratedCampaigns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundImportDataFroNewlyMigratedCampaigns(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundImportDataFroNewlyMigratedCampaigns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundImportOrdersExactDay(date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundImportOrdersExactDay(date, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundImportOrdersExactDay']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundImportOrdersExactTime(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundImportOrdersExactTime(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundImportOrdersExactTime']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundImportStatisticsExactDay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundImportStatisticsExactDay(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundImportStatisticsExactDay']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundListAllTgncaGlobalFbCatalogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundListAllTgncaGlobalFbCatalogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundListAllTgncaGlobalFbCatalogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundListResponseTypeSwagger(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestListResponseListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundListResponseTypeSwagger(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundListResponseTypeSwagger']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundLogInsideBQ(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundLogInsideBQ(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundLogInsideBQ']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundMeasureFeedSize(url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundMeasureFeedSize(url, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundMeasureFeedSize']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundMigrateProductCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundMigrateProductCategories(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundMigrateProductCategories']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundMigrationTest(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundMigrationTest(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundMigrationTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundPreloadController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundPreloadController(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundPreloadController']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundPrintPageViewPathFromJson(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundPrintPageViewPathFromJson(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundPrintPageViewPathFromJson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundRegexSplitTest(input?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundRegexSplitTest(input, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundRegexSplitTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundRemoveGmcFeedFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundRemoveGmcFeedFile(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundRemoveGmcFeedFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundRepairCampaignDesiredStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundRepairCampaignDesiredStatuses(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundRepairCampaignDesiredStatuses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundRepairCampaigns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundRepairCampaigns(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundRepairCampaigns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundReuploadProductsGmc(eshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundReuploadProductsGmc(eshopId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundReuploadProductsGmc']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundSetAllProductsGmcUpdateToTrue(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundSetAllProductsGmcUpdateToTrue(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundSetAllProductsGmcUpdateToTrue']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {number} [budget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundSetFbCampaignBudget(campaignId?: string, budget?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundSetFbCampaignBudget(campaignId, budget, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundSetFbCampaignBudget']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundSetGmcProductsToReulpoad(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundSetGmcProductsToReulpoad(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundSetGmcProductsToReulpoad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTagMapSerializationTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTagMapSerializationTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTagMapSerializationTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestClearSheet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestClearSheet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestClearSheet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [hour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestDateTimeCreation(date?: string, hour?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestDateTimeCreation(date, hour, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestDateTimeCreation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestEshopBlacklist(eshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestEshopBlacklist(eshopId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestEshopBlacklist']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [adCreativeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestFbAdCreativeUtm(adCreativeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestFbAdCreativeUtm(adCreativeId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestFbAdCreativeUtm']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestFbCatalogs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestFbCatalogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestFbCatalogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestFbStatuses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestFbStatuses(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestFbStatuses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestIOptionsInjection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestIOptionsInjection(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestIOptionsInjection']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestLinkUtmRemoving(input?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestLinkUtmRemoving(input, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestLinkUtmRemoving']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestLog(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestLog(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestLog']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestMultiInitPerformance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestMultiInitPerformance(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestMultiInitPerformance']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestParsingPrice(input?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestParsingPrice(input, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestParsingPrice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestProductIssuesBlacklist(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestProductIssuesBlacklist(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestProductIssuesBlacklist']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestReadDate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestReadDate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestReadDate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestStatsImport(eshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestStatsImport(eshopId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestStatsImport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestStatusFromGads(campaignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestStatusFromGads(campaignId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestStatusFromGads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestTriggerFunctionsFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestTriggerFunctionsFile(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestTriggerFunctionsFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTestikBlastik(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTestikBlastik(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTestikBlastik']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [pid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTransactionWebhookTest(pid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTransactionWebhookTest(pid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTransactionWebhookTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundTurnOffFbAds(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundTurnOffFbAds(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundTurnOffFbAds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundimportSessionsTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundimportSessionsTest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundimportSessionsTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundtestImportCampaignStatisticsBack(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundtestImportCampaignStatisticsBack(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundtestImportCampaignStatisticsBack']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [productCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testPlaygroundtestMemory(productCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testPlaygroundtestMemory(productCount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TANGANICAApi.testPlaygroundtestMemory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TANGANICAApi - factory interface
 * @export
 */
export const TANGANICAApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TANGANICAApiFp(configuration)
    return {
        /**
         * Creates a credit note for final invoice
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingGenerateCreditNoteRequest} [adminAccountingGenerateCreditNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGenerateCreditNote(adminAccountingGenerateCreditNoteRequest?: AdminAccountingGenerateCreditNoteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAccountingGenerateCreditNote(adminAccountingGenerateCreditNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets eshop\'s credit.
         * @summary Policies: RequireInternalRole
         * @param {AdminAccountingGetEshopCreditRequest} [adminAccountingGetEshopCreditRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGetEshopCredit(adminAccountingGetEshopCreditRequest?: AdminAccountingGetEshopCreditRequest, options?: any): AxiosPromise<AdminAccountingGetEshopCreditResponse> {
            return localVarFp.adminAccountingGetEshopCredit(adminAccountingGetEshopCreditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the presigned url to the invoice pdf stored in Spaces.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
         * @param {AdminAccountingGetInvoicePdfUrlRequest} [adminAccountingGetInvoicePdfUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGetInvoicePdfUrl(adminAccountingGetInvoicePdfUrlRequest?: AdminAccountingGetInvoicePdfUrlRequest, options?: any): AxiosPromise<AdminAccountingGetInvoicePdfUrlResponse> {
            return localVarFp.adminAccountingGetInvoicePdfUrl(adminAccountingGetInvoicePdfUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
         * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
         * @param {AdminAccountingGetInvoicesRequest} [adminAccountingGetInvoicesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingGetInvoices(adminAccountingGetInvoicesRequest?: AdminAccountingGetInvoicesRequest, options?: any): AxiosPromise<AdminAccountingGetInvoicesResponse> {
            return localVarFp.adminAccountingGetInvoices(adminAccountingGetInvoicesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Regenerates the invoice with data from the database. Updates the regenerated invoice - changes Locale based on input and links latest BillingSetting
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingRegenerateInvoiceRequest} [adminAccountingRegenerateInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingRegenerateInvoice(adminAccountingRegenerateInvoiceRequest?: AdminAccountingRegenerateInvoiceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAccountingRegenerateInvoice(adminAccountingRegenerateInvoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Canceles the invoice.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingSetAsCanceledRequest} [adminAccountingSetAsCanceledRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingSetAsCanceled(adminAccountingSetAsCanceledRequest?: AdminAccountingSetAsCanceledRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAccountingSetAsCanceled(adminAccountingSetAsCanceledRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets the ProformaInvoice or CreditNote status as paid.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
         * @param {AdminAccountingSetAsPaidRequest} [adminAccountingSetAsPaidRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAccountingSetAsPaid(adminAccountingSetAsPaidRequest?: AdminAccountingSetAsPaidRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAccountingSetAsPaid(adminAccountingSetAsPaidRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to directly add user permissions to other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
         * @param {AdminAdminsAddAdminUserPermissionRequest} [adminAdminsAddAdminUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsAddAdminUserPermission(adminAdminsAddAdminUserPermissionRequest?: AdminAdminsAddAdminUserPermissionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAdminsAddAdminUserPermission(adminAdminsAddAdminUserPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to  add user permissions to other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsAddAdminUserRoleRequest} [adminAdminsAddAdminUserRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsAddAdminUserRole(adminAdminsAddAdminUserRoleRequest?: AdminAdminsAddAdminUserRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAdminsAddAdminUserRole(adminAdminsAddAdminUserRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to get roles they are able to assign to given internal user.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsGetAssignableRolesRequest} [adminAdminsGetAssignableRolesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsGetAssignableRoles(adminAdminsGetAssignableRolesRequest?: AdminAdminsGetAssignableRolesRequest, options?: any): AxiosPromise<AdminAdminsGetAssignableRolesResponse> {
            return localVarFp.adminAdminsGetAssignableRoles(adminAdminsGetAssignableRolesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all users with their roles.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsGetInternalUserRoles(options?: any): AxiosPromise<AdminAdminsGetInternalUserRolesResponse> {
            return localVarFp.adminAdminsGetInternalUserRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail sending settings.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsGetSubscriptions(options?: any): AxiosPromise<AdminAdminsGetSubscriptionsResponse> {
            return localVarFp.adminAdminsGetSubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove user permissions of other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
         * @param {AdminAdminsRemoveAdminUserPermissionRequest} [adminAdminsRemoveAdminUserPermissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsRemoveAdminUserPermission(adminAdminsRemoveAdminUserPermissionRequest?: AdminAdminsRemoveAdminUserPermissionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAdminsRemoveAdminUserPermission(adminAdminsRemoveAdminUserPermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove roles of other internal users.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
         * @param {AdminAdminsRemoveAdminUserRoleRequest} [adminAdminsRemoveAdminUserRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsRemoveAdminUserRole(adminAdminsRemoveAdminUserRoleRequest?: AdminAdminsRemoveAdminUserRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAdminsRemoveAdminUserRole(adminAdminsRemoveAdminUserRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
         * @summary Policies: RequireInternalRole
         * @param {AdminAdminsSubscribeRequest} [adminAdminsSubscribeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsSubscribe(adminAdminsSubscribeRequest?: AdminAdminsSubscribeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAdminsSubscribe(adminAdminsSubscribeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
         * @summary Policies: RequireInternalRole
         * @param {AdminAdminsUnsubscribeRequest} [adminAdminsUnsubscribeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdminsUnsubscribe(adminAdminsUnsubscribeRequest?: AdminAdminsUnsubscribeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAdminsUnsubscribe(adminAdminsUnsubscribeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
         * @param {AdminAuthForgottenPasswordRequest} [adminAuthForgottenPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthForgottenPassword(adminAuthForgottenPasswordRequest?: AdminAuthForgottenPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAuthForgottenPassword(adminAuthForgottenPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {AdminAuthEshopAccessTokenRequest} [adminAuthEshopAccessTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthGetEshopAccessToken(adminAuthEshopAccessTokenRequest?: AdminAuthEshopAccessTokenRequest, options?: any): AxiosPromise<AdminAuthGetEshopAccessTokenResponse> {
            return localVarFp.adminAuthGetEshopAccessToken(adminAuthEshopAccessTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint returns list of AdminPermissions current user has.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthGetPermissions(options?: any): AxiosPromise<AdminAuthGetPermissionsResponse> {
            return localVarFp.adminAuthGetPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthGetUserAccessToken(options?: any): AxiosPromise<AdminAuthGetAccessTokenResponse> {
            return localVarFp.adminAuthGetUserAccessToken(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for admin login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
         * @param {AdminAuthLoginRequest} [adminAuthLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthLogin(adminAuthLoginRequest?: AdminAuthLoginRequest, options?: any): AxiosPromise<AdminAuthLoginResponse> {
            return localVarFp.adminAuthLogin(adminAuthLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthLogout(options?: any): AxiosPromise<void> {
            return localVarFp.adminAuthLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for registering new admin user. The new admin needs to re-log to obtain new access token.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal</li><li><b>Email</b>: Email of an already registered user who should be assigned with Internal role instead of Client role</li></ul>
         * @summary Policies: RequireInternalRole; AdminPermissions: RegisterNewAdmin
         * @param {AdminAuthRegisterRequest} [adminAuthRegisterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuthRegister(adminAuthRegisterRequest?: AdminAuthRegisterRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminAuthRegister(adminAuthRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
         * @summary Policies: RequireResetPasswordToken
         * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCallbacksResetPassword(clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminCallbacksResetPassword(clientCallbacksResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is tanganica admins or developers to be able logout all users<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin, Internal</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientAuthLogoutAll(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.adminClientAuthLogoutAll(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his Email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>Email</b>: valid user Email</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientAuthLogoutUserByEmail(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminClientAuthLogoutUserByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireInternalRole
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminClientAuthLogoutUserById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminClientAuthLogoutUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Adds (or removes) given amount of credit to eshop. (Creates appropriate transaction)
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailAddAdminTransactionRequest} [adminEshopsDetailAddAdminTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailAddAdminTransaction(adminEshopsDetailAddAdminTransactionRequest?: AdminEshopsDetailAddAdminTransactionRequest, options?: any): AxiosPromise<AdminEshopsDetailGetEshopDetailResponse> {
            return localVarFp.adminEshopDetailAddAdminTransaction(adminEshopsDetailAddAdminTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Sets Eshop.Archived as true.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailArchiveEshopRequest} [adminEshopsDetailArchiveEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailArchiveEshop(adminEshopsDetailArchiveEshopRequest?: AdminEshopsDetailArchiveEshopRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailArchiveEshop(adminEshopsDetailArchiveEshopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets currently active campaign formats
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetActiveCampaignFormatsRequest} [adminEshopsDetailGetActiveCampaignFormatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetActiveCampaignFormats(adminEshopsDetailGetActiveCampaignFormatsRequest?: AdminEshopsDetailGetActiveCampaignFormatsRequest, options?: any): AxiosPromise<AdminEshopsDetailGetActiveCampaignFormatsResponse> {
            return localVarFp.adminEshopDetailGetActiveCampaignFormats(adminEshopsDetailGetActiveCampaignFormatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to get assets for {CampaignType} campaigns
         * @summary Policies: RequireInternalRole
         * @param {EnumsMarketingFormat} campaignType 
         * @param {AdminEshopsDetailGetAssetsRequest} [adminEshopsDetailGetAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetAssets(campaignType: EnumsMarketingFormat, adminEshopsDetailGetAssetsRequest?: AdminEshopsDetailGetAssetsRequest, options?: any): AxiosPromise<AdminEshopsDetailGetAssetsResponse> {
            return localVarFp.adminEshopDetailGetAssets(campaignType, adminEshopsDetailGetAssetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets eshop billing info.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetBillingSettingsRequest} [adminEshopsDetailGetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetBillingSettings(adminEshopsDetailGetBillingSettingsRequest?: AdminEshopsDetailGetBillingSettingsRequest, options?: any): AxiosPromise<AdminEshopsDetailGetBillingSettingsResponse> {
            return localVarFp.adminEshopDetailGetBillingSettings(adminEshopsDetailGetBillingSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets all already created campaigns
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetCreatedCampaignsRequest} [adminEshopsDetailGetCreatedCampaignsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetCreatedCampaigns(adminEshopsDetailGetCreatedCampaignsRequest?: AdminEshopsDetailGetCreatedCampaignsRequest, options?: any): AxiosPromise<AdminEshopsDetailGetCreatedCampaignsResponse> {
            return localVarFp.adminEshopDetailGetCreatedCampaigns(adminEshopsDetailGetCreatedCampaignsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets history of all transactions where transaction type is not AdSpend or contribution
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetCreditTopupHistoryRequest} [adminEshopsDetailGetCreditTopupHistoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetCreditTopupHistory(adminEshopsDetailGetCreditTopupHistoryRequest?: AdminEshopsDetailGetCreditTopupHistoryRequest, options?: any): AxiosPromise<AdminEshopsDetailGetCreditTopupHistoryResponse> {
            return localVarFp.adminEshopDetailGetCreditTopupHistory(adminEshopsDetailGetCreditTopupHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets contribution transactions overview.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetDailyContributionStatisticsRequest} [adminEshopsDetailGetDailyContributionStatisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetDailyContributionStatistics(adminEshopsDetailGetDailyContributionStatisticsRequest?: AdminEshopsDetailGetDailyContributionStatisticsRequest, options?: any): AxiosPromise<AdminEshopsDetailGetDailyContributionStatisticsResponse> {
            return localVarFp.adminEshopDetailGetDailyContributionStatistics(adminEshopsDetailGetDailyContributionStatisticsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetContributionPercentageRequest} [adminEshopsDetailGetContributionPercentageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetDefaultContributionPercentage(adminEshopsDetailGetContributionPercentageRequest?: AdminEshopsDetailGetContributionPercentageRequest, options?: any): AxiosPromise<AdminEshopsDetailGetContributionPercentageResponse> {
            return localVarFp.adminEshopDetailGetDefaultContributionPercentage(adminEshopsDetailGetContributionPercentageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets basic eshop info for the top part of admin eshop detail page.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetEshopDetailRequest} [adminEshopsDetailGetEshopDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetEshopDetail(adminEshopsDetailGetEshopDetailRequest?: AdminEshopsDetailGetEshopDetailRequest, options?: any): AxiosPromise<AdminEshopsDetailGetEshopDetailResponse> {
            return localVarFp.adminEshopDetailGetEshopDetail(adminEshopsDetailGetEshopDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Get Feed processing logs.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetFeedLogsRequest} [adminEshopsDetailGetFeedLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetFeedLogs(adminEshopsDetailGetFeedLogsRequest?: AdminEshopsDetailGetFeedLogsRequest, options?: any): AxiosPromise<AdminEshopsDetailGetFeedLogsResponse> {
            return localVarFp.adminEshopDetailGetFeedLogs(adminEshopsDetailGetFeedLogsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Get Feed url.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetFeedUrlRequest} [adminEshopsDetailGetFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetFeedUrl(adminEshopsDetailGetFeedUrlRequest?: AdminEshopsDetailGetFeedUrlRequest, options?: any): AxiosPromise<AdminEshopsDetailGetFeedUrlResponse> {
            return localVarFp.adminEshopDetailGetFeedUrl(adminEshopsDetailGetFeedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets data for graphs on the EshopDetail page in admin
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetGraphDataRequest} [adminEshopsDetailGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetGraphData(adminEshopsDetailGetGraphDataRequest?: AdminEshopsDetailGetGraphDataRequest, options?: any): AxiosPromise<AdminEshopsDetailGetGraphDataResponse> {
            return localVarFp.adminEshopDetailGetGraphData(adminEshopsDetailGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Get current number of products in database.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetProductCountRequest} [adminEshopsDetailGetProductCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetProductCount(adminEshopsDetailGetProductCountRequest?: AdminEshopsDetailGetProductCountRequest, options?: any): AxiosPromise<AdminEshopsDetailGetProductCountResponse> {
            return localVarFp.adminEshopDetailGetProductCount(adminEshopsDetailGetProductCountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the state of products advertisement for given platform
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsDetailGetProductsStateRequest} [adminEshopsDetailGetProductsStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailGetProductsState(adminEshopsDetailGetProductsStateRequest?: AdminEshopsDetailGetProductsStateRequest, options?: any): AxiosPromise<AdminEshopsDetailGetProductsStateResponse> {
            return localVarFp.adminEshopDetailGetProductsState(adminEshopsDetailGetProductsStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Process feed for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailProcessEshopFeedRequest} [adminEshopsDetailProcessEshopFeedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailProcessEshopFeed(adminEshopsDetailProcessEshopFeedRequest?: AdminEshopsDetailProcessEshopFeedRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailProcessEshopFeed(adminEshopsDetailProcessEshopFeedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Sets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetContributionPercentageRequest} [adminEshopsDetailSetContributionPercentageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailSetDefaultContributionPercentage(adminEshopsDetailSetContributionPercentageRequest?: AdminEshopsDetailSetContributionPercentageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailSetDefaultContributionPercentage(adminEshopsDetailSetContributionPercentageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Change Feed url.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetFeedUrlRequest} [adminEshopsDetailSetFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailSetFeedUrl(adminEshopsDetailSetFeedUrlRequest?: AdminEshopsDetailSetFeedUrlRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailSetFeedUrl(adminEshopsDetailSetFeedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Sets the state of products advertisement for given platform
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailSetProductsStateRequest} [adminEshopsDetailSetProductsStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailSetProductsState(adminEshopsDetailSetProductsStateRequest?: AdminEshopsDetailSetProductsStateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailSetProductsState(adminEshopsDetailSetProductsStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Update products info in admin table for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUpdateEshopProductsAdminInfoRequest} [adminEshopsDetailUpdateEshopProductsAdminInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailUpdateEshopProductsAdminInfo(adminEshopsDetailUpdateEshopProductsAdminInfoRequest?: AdminEshopsDetailUpdateEshopProductsAdminInfoRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailUpdateEshopProductsAdminInfo(adminEshopsDetailUpdateEshopProductsAdminInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Update products status for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUpdateEshopProductsStatusRequest} [adminEshopsDetailUpdateEshopProductsStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailUpdateEshopProductsStatus(adminEshopsDetailUpdateEshopProductsStatusRequest?: AdminEshopsDetailUpdateEshopProductsStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailUpdateEshopProductsStatus(adminEshopsDetailUpdateEshopProductsStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Upload products for current eshop.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
         * @param {AdminEshopsDetailUploadEshopProductsRequest} [adminEshopsDetailUploadEshopProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopDetailUploadEshopProducts(adminEshopsDetailUploadEshopProductsRequest?: AdminEshopsDetailUploadEshopProductsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopDetailUploadEshopProducts(adminEshopsDetailUploadEshopProductsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the data for the campaign statistics sub-table visible after expanding the eshop view in the main eshop table in admin.It is also used on eshop detail.
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsGetEshopCampaignsStatisticsRequest} [adminEshopsGetEshopCampaignsStatisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopsGetEshopCampaignsStatistics(adminEshopsGetEshopCampaignsStatisticsRequest?: AdminEshopsGetEshopCampaignsStatisticsRequest, options?: any): AxiosPromise<AdminEshopsGetEshopCampaignsStatisticsResponse> {
            return localVarFp.adminEshopsGetEshopCampaignsStatistics(adminEshopsGetEshopCampaignsStatisticsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the eshops
         * @summary Policies: RequireInternalRole
         * @param {AdminEshopsGetEshopsRequest} [adminEshopsGetEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopsGetEshops(adminEshopsGetEshopsRequest?: AdminEshopsGetEshopsRequest, options?: any): AxiosPromise<AdminEshopsGetEshopsResponse> {
            return localVarFp.adminEshopsGetEshops(adminEshopsGetEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Updates the database view from which data for admin eshops table are queried.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEshopsRefreshEshopsView(options?: any): AxiosPromise<void> {
            return localVarFp.adminEshopsRefreshEshopsView(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading a gclid report file
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetGclidsCsvLink(options?: any): AxiosPromise<AdminDTOGetGclidsCsvResponse> {
            return localVarFp.adminExportGetGclidsCsvLink(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading csv files with invoices 
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetInvoiceCsvLinks(options?: any): AxiosPromise<AdminDTOGetInvoicesCsvResponse> {
            return localVarFp.adminExportGetInvoiceCsvLinks(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading xml files with invoices 
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetInvoiceXmlLinks(options?: any): AxiosPromise<AdminDTOGetInvoiceXmlResponse> {
            return localVarFp.adminExportGetInvoiceXmlLinks(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint returns a link for downloading a zip with invoices
         * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportGetInvoiceZipLink(options?: any): AxiosPromise<AdminDTOGetInvoiceZipResponse> {
            return localVarFp.adminExportGetInvoiceZipLink(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the list of unsuccessful feeds.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeedsGetFeedErrors(options?: any): AxiosPromise<AdminDTOGetFeedErrorsResponse> {
            return localVarFp.adminFeedsGetFeedErrors(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the specified feed cron info.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetFeedProcessingInfoRequest} [adminDTOGetFeedProcessingInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeedsGetFeedProcessingInfo(adminDTOGetFeedProcessingInfoRequest?: AdminDTOGetFeedProcessingInfoRequest, options?: any): AxiosPromise<AdminDTOGetFeedProcessingInfoResponse> {
            return localVarFp.adminFeedsGetFeedProcessingInfo(adminDTOGetFeedProcessingInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Endpoint for getting list of all googleCustomerSubAccounts in TGNCA s.r.o account
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGoogleCampaignsGetAllCustomerSubAccounts(options?: any): AxiosPromise<AdminGoogleCampaignsGetAllCustomerSubAccountsResponse> {
            return localVarFp.adminGoogleCampaignsGetAllCustomerSubAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view incomplete google campaigns that they need to create on google
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGoogleCampaignsGetCampaigns(options?: any): AxiosPromise<AdminGoogleCampaignsGetCampaignsResponse> {
            return localVarFp.adminGoogleCampaignsGetCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Endpoint serving as a trigger to match the external id / customerSubAccount id with an empty campaign in database - both single and multiple select
         * @summary Policies: RequireInternalRole; AdminPermissions: SetGoogleCampaigns
         * @param {AdminGoogleCampaignsGoogleCampaignCreatedRequest} [adminGoogleCampaignsGoogleCampaignCreatedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGoogleCampaignsGoogleCampaignCreated(adminGoogleCampaignsGoogleCampaignCreatedRequest?: AdminGoogleCampaignsGoogleCampaignCreatedRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminGoogleCampaignsGoogleCampaignCreated(adminGoogleCampaignsGoogleCampaignCreatedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets list of activated eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportActivatedEshops
         * @param {AdminKpiReportGetActivatedEshopsRequest} [adminKpiReportGetActivatedEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetActivatedEshops(adminKpiReportGetActivatedEshopsRequest?: AdminKpiReportGetActivatedEshopsRequest, options?: any): AxiosPromise<AdminKpiReportGetActivatedEshopsResponse> {
            return localVarFp.adminKpiReportGetActivatedEshops(adminKpiReportGetActivatedEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for Advertising expenses graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetAdExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: any): AxiosPromise<AdminKpiReportGetAdExpensesGraphDataResponse> {
            return localVarFp.adminKpiReportGetAdExpensesGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for Contributions graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetContributionsGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: any): AxiosPromise<AdminKpiReportGetContributionsGraphDataResponse> {
            return localVarFp.adminKpiReportGetContributionsGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for Contributions/Advertising expenses graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetContributionsToAdExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: any): AxiosPromise<AdminKpiReportGetContributionsToAdExpensesGraphDataResponse> {
            return localVarFp.adminKpiReportGetContributionsToAdExpensesGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for Eshops with spending graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetEshopsWithAdspendGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: any): AxiosPromise<AdminKpiReportGetEshopsWithAdspendGraphDataResponse> {
            return localVarFp.adminKpiReportGetEshopsWithAdspendGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for Turnover (GeneratedRevenue) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetGeneratedRevenueGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: any): AxiosPromise<AdminKpiReportGetGeneratedRevenueGraphDataResponse> {
            return localVarFp.adminKpiReportGetGeneratedRevenueGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets kpi report data for given time frame.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetKpiReportRequest} [adminKpiReportGetKpiReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetKpiReport(adminKpiReportGetKpiReportRequest?: AdminKpiReportGetKpiReportRequest, options?: any): AxiosPromise<AdminKpiReportGetKpiReportResponse> {
            return localVarFp.adminKpiReportGetKpiReport(adminKpiReportGetKpiReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for the campaign table in monthly report section of Kpi report page.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetMonthlyCampaignStatsRequest} [adminKpiReportGetMonthlyCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetMonthlyCampaignStats(adminKpiReportGetMonthlyCampaignStatsRequest?: AdminKpiReportGetMonthlyCampaignStatsRequest, options?: any): AxiosPromise<AdminKpiReportGetMonthlyCampaignStatsResponse> {
            return localVarFp.adminKpiReportGetMonthlyCampaignStats(adminKpiReportGetMonthlyCampaignStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for monthly report section of Kpi report page.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetMonthlyReportRequest} [adminKpiReportGetMonthlyReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetMonthlyReport(adminKpiReportGetMonthlyReportRequest?: AdminKpiReportGetMonthlyReportRequest, options?: any): AxiosPromise<AdminKpiReportGetMonthlyReportResponse> {
            return localVarFp.adminKpiReportGetMonthlyReport(adminKpiReportGetMonthlyReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for Transactions (NumberOfOrders) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetNumberOfOrdersGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: any): AxiosPromise<AdminKpiReportGetNumberOfOrdersGraphDataResponse> {
            return localVarFp.adminKpiReportGetNumberOfOrdersGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets list of registered eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportRegisteredEshops
         * @param {AdminKpiReportGetRegisteredEshopsRequest} [adminKpiReportGetRegisteredEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetRegisteredEshops(adminKpiReportGetRegisteredEshopsRequest?: AdminKpiReportGetRegisteredEshopsRequest, options?: any): AxiosPromise<AdminKpiReportGetRegisteredEshopsResponse> {
            return localVarFp.adminKpiReportGetRegisteredEshops(adminKpiReportGetRegisteredEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for Accounting (TotalExpenses) graph.
         * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
         * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminKpiReportGetTotalExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: any): AxiosPromise<AdminKpiReportGetTotalExpensesGraphDataResponse> {
            return localVarFp.adminKpiReportGetTotalExpensesGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the user logs
         * @summary Policies: RequireInternalRole
         * @param {AdminLogsGetLogsRequest} [adminLogsGetLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogsGetLogs(adminLogsGetLogsRequest?: AdminLogsGetLogsRequest, options?: any): AxiosPromise<AdminLogsGetLogsResponse> {
            return localVarFp.adminLogsGetLogs(adminLogsGetLogsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail logs
         * @summary Policies: RequireInternalRole
         * @param {AdminLogsGetMailLogsRequest} [adminLogsGetMailLogsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLogsGetMailLogs(adminLogsGetMailLogsRequest?: AdminLogsGetMailLogsRequest, options?: any): AxiosPromise<AdminLogsGetMailLogsResponse> {
            return localVarFp.adminLogsGetMailLogs(adminLogsGetMailLogsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the data from DB view made for Gmc products data and apply filtering, sorting and paging.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsDataRequest} [adminDTOGetProductsDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetProductsData(adminDTOGetProductsDataRequest?: AdminDTOGetProductsDataRequest, options?: any): AxiosPromise<AdminDTOGetProductsDataResponse> {
            return localVarFp.adminProductsGetProductsData(adminDTOGetProductsDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the DOTNET issues for given eshop.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsDotnetIssuesRequest} [adminDTOGetProductsDotnetIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetProductsDotnetIssues(adminDTOGetProductsDotnetIssuesRequest?: AdminDTOGetProductsDotnetIssuesRequest, options?: any): AxiosPromise<AdminDTOGetProductsDotnetIssuesResponse> {
            return localVarFp.adminProductsGetProductsDotnetIssues(adminDTOGetProductsDotnetIssuesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the GMC issues for given eshop.
         * @summary Policies: RequireInternalRole
         * @param {AdminDTOGetProductsGmcIssuesRequest} [adminDTOGetProductsGmcIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetProductsGmcIssues(adminDTOGetProductsGmcIssuesRequest?: AdminDTOGetProductsGmcIssuesRequest, options?: any): AxiosPromise<AdminDTOGetProductsGmcIssuesResponse> {
            return localVarFp.adminProductsGetProductsGmcIssues(adminDTOGetProductsGmcIssuesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the count and capacity notification of GMC subaccounts.
         * @summary Policies: RequireInternalRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsGetSubaccountsInfo(options?: any): AxiosPromise<AdminDTOGetSubaccountsInfoResponse> {
            return localVarFp.adminProductsGetSubaccountsInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Updates the count of GMC subaccounts.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsUpdateGmcSubaccounts(options?: any): AxiosPromise<void> {
            return localVarFp.adminProductsUpdateGmcSubaccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint gets number of eshops with spend for czech and foreign eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuActiveEshopsCount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTopMenuGetActiveEshopsCount(options?: any): AxiosPromise<AdminTopMenuGetActiveEshopsCountResponse> {
            return localVarFp.adminTopMenuGetActiveEshopsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint gets overall credit for czech and foreign eshops
         * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuEshopsCredit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTopMenuGetEshopsCredit(options?: any): AxiosPromise<AdminTopMenuGetEshopsCreditResponse> {
            return localVarFp.adminTopMenuGetEshopsCredit(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution - same as in the client app
         * @summary Policies: RequireInternalRole
         * @param {AdminUserDetailGetUserAffilInfoRequest} [adminUserDetailGetUserAffilInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailGetUserAffilInfo(adminUserDetailGetUserAffilInfoRequest?: AdminUserDetailGetUserAffilInfoRequest, options?: any): AxiosPromise<AdminUserDetailGetUserAffilInfoResponse> {
            return localVarFp.adminUserDetailGetUserAffilInfo(adminUserDetailGetUserAffilInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets basic user info.
         * @summary Policies: RequireInternalRole
         * @param {AdminUserDetailGetUserDetailRequest} [adminUserDetailGetUserDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailGetUserDetail(adminUserDetailGetUserDetailRequest?: AdminUserDetailGetUserDetailRequest, options?: any): AxiosPromise<AdminUserDetailGetUserDetailResponse> {
            return localVarFp.adminUserDetailGetUserDetail(adminUserDetailGetUserDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID
         * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
         * @param {AdminUserDetailLogoutUserRequest} [adminUserDetailLogoutUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailLogoutUser(adminUserDetailLogoutUserRequest?: AdminUserDetailLogoutUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUserDetailLogoutUser(adminUserDetailLogoutUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Sets user as affiliate.
         * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
         * @param {AdminUserDetailSetUserAffilRequest} [adminUserDetailSetUserAffilRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDetailSetUserAffil(adminUserDetailSetUserAffilRequest?: AdminUserDetailSetUserAffilRequest, options?: any): AxiosPromise<void> {
            return localVarFp.adminUserDetailSetUserAffil(adminUserDetailSetUserAffilRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
         * @summary Policies: RequireInternalRole
         * @param {AdminUsersGetUsersRequest} [adminUsersGetUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersGetUsers(adminUsersGetUsersRequest?: AdminUsersGetUsersRequest, options?: any): AxiosPromise<AdminUsersGetUsersResponse> {
            return localVarFp.adminUsersGetUsers(adminUsersGetUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> The user requests an affil program - email is sent to admins with users name and id
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportAskForAffil(options?: any): AxiosPromise<void> {
            return localVarFp.clientAffiliateReportAskForAffil(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the amount of affil contribution available for the eshop to withdraw
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffilContributionAvailable(options?: any): AxiosPromise<ClientDTOGetAffilContributionAvailableResponse> {
            return localVarFp.clientAffiliateReportGetAffilContributionAvailable(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffilGraphData(options?: any): AxiosPromise<ClientDTOGetAffilGraphDataResponse> {
            return localVarFp.clientAffiliateReportGetAffilGraphData(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets all the necessary info about the affiliate program
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffilInfo(options?: any): AxiosPromise<ClientDTOGetAffilInfoResponse> {
            return localVarFp.clientAffiliateReportGetAffilInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAffiliateReportGetAffiliateContributionTotal(options?: any): AxiosPromise<ClientDTOGetAffiliateContributionTotalResponse> {
            return localVarFp.clientAffiliateReportGetAffiliateContributionTotal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateTextAssetsRequest} [clientDTOGenerateTextAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAssetGenerateTextAssets(clientDTOGenerateTextAssetsRequest?: ClientDTOGenerateTextAssetsRequest, options?: any): AxiosPromise<ClientDTOGenerateTextAssetsResponse> {
            return localVarFp.clientAssetGenerateTextAssets(clientDTOGenerateTextAssetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout when his refresh token is expired
         * @param {ClientAuthLogoutRequest} [clientAuthLogoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthExpiredTokenLogout(clientAuthLogoutRequest?: ClientAuthLogoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthExpiredTokenLogout(clientAuthLogoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
         * @param {ClientAuthForgottenPasswordRequest} [clientAuthForgottenPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthForgottenPassword(clientAuthForgottenPasswordRequest?: ClientAuthForgottenPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthForgottenPassword(clientAuthForgottenPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {ClientAuthEshopAccessTokenRequest} [clientAuthEshopAccessTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetEshopAccessToken(clientAuthEshopAccessTokenRequest?: ClientAuthEshopAccessTokenRequest, options?: any): AxiosPromise<ClientAuthGetEshopAccessTokenResponse> {
            return localVarFp.clientAuthGetEshopAccessToken(clientAuthEshopAccessTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint gets the facebook oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
         * @param {ClientAuthGetFacebookOAuthUrlRequest} [clientAuthGetFacebookOAuthUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetFacebookOAuthUrl(clientAuthGetFacebookOAuthUrlRequest?: ClientAuthGetFacebookOAuthUrlRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthGetFacebookOAuthUrl(clientAuthGetFacebookOAuthUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint gets the google oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
         * @param {ClientAuthGetGoogleOAuthUrlRequest} [clientAuthGetGoogleOAuthUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetGoogleOAuthUrl(clientAuthGetGoogleOAuthUrlRequest?: ClientAuthGetGoogleOAuthUrlRequest, options?: any): AxiosPromise<ClientAuthGetGoogleOAuthUrlResponse> {
            return localVarFp.clientAuthGetGoogleOAuthUrl(clientAuthGetGoogleOAuthUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
         * @summary Policies: RequireRefreshToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetUserAccessToken(options?: any): AxiosPromise<ClientAuthGetAccessTokenResponse> {
            return localVarFp.clientAuthGetUserAccessToken(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for standard user password login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
         * @param {ClientAuthLoginRequest} [clientAuthLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthLogin(clientAuthLoginRequest?: ClientAuthLoginRequest, options?: any): AxiosPromise<ClientAuthLoginResponse> {
            return localVarFp.clientAuthLogin(clientAuthLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: UserAccessToken</li></ul>
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthLogout(options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint creates new user with client account owner privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal AND Admin</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Email</b>: have to be unique</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
         * @param {ClientAuthRegisterRequest} [clientAuthRegisterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthRegister(clientAuthRegisterRequest?: ClientAuthRegisterRequest, options?: any): AxiosPromise<ClientAuthRegisterResponse> {
            return localVarFp.clientAuthRegister(clientAuthRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint creates new user with client account owner privileges and given eshop role privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: InviteToken</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
         * @summary Policies: RequireInviteToken
         * @param {ClientAuthRegisterInvitedRequest} [clientAuthRegisterInvitedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthRegisterInvited(clientAuthRegisterInvitedRequest?: ClientAuthRegisterInvitedRequest, options?: any): AxiosPromise<ClientAuthRegisterInvitedResponse> {
            return localVarFp.clientAuthRegisterInvited(clientAuthRegisterInvitedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Is called after user clicks on the confirmation link in email. User is then redirected to login page (? TODO)
         * @summary Policies: RequireEmailChangeToken
         * @param {ClientCallbacksConfirmEmailChangeRequest} [clientCallbacksConfirmEmailChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksConfirmEmailChange(clientCallbacksConfirmEmailChangeRequest?: ClientCallbacksConfirmEmailChangeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientCallbacksConfirmEmailChange(clientCallbacksConfirmEmailChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Called by frontend, which passes through the parameters obtained by external auth provider <h3>Requirements</h3><ul><li><b>Authorization roles</b>: token obtained from state query parameter of redirectUri </li></ul>
         * @summary Policies: RequireOAuthStateToken
         * @param {ClientCallbacksExternalAuthCallbackRequest} [clientCallbacksExternalAuthCallbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksExternalAuthCallback(clientCallbacksExternalAuthCallbackRequest?: ClientCallbacksExternalAuthCallbackRequest, options?: any): AxiosPromise<ClientCallbacksExternalAuthCallbackResponse> {
            return localVarFp.clientCallbacksExternalAuthCallback(clientCallbacksExternalAuthCallbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
         * @summary Policies: RequireResetPasswordToken
         * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksResetPassword(clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientCallbacksResetPassword(clientCallbacksResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCallbacksStripePaymentWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.clientCallbacksStripePaymentWebhook(options).then((request) => request(axios, basePath));
        },
        /**
         * Exchanges the auth code for access token and indicates whether it has correct business permissions (true/false)
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest} [clientCampaignsCheckAuthCodeForBusinessPermissionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsCheckAuthCodeForBusinessPermissions(clientCampaignsCheckAuthCodeForBusinessPermissionsRequest?: ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest, options?: any): AxiosPromise<ClientCampaignsCheckAuthCodeForBusinessPermissionsResponse> {
            return localVarFp.clientCampaignsCheckAuthCodeForBusinessPermissions(clientCampaignsCheckAuthCodeForBusinessPermissionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if FB page is linked to Eshop in DB
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsCheckFBPageLink(options?: any): AxiosPromise<ClientCampaignsCheckFBPageLinkResponse> {
            return localVarFp.clientCampaignsCheckFBPageLink(options).then((request) => request(axios, basePath));
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsDeleteAssetsRequest} [clientCampaignsDeleteAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsDeleteAssets(campaignType: EnumsMarketingFormat, clientCampaignsDeleteAssetsRequest?: ClientCampaignsDeleteAssetsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientCampaignsDeleteAssets(campaignType, clientCampaignsDeleteAssetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetAssets(campaignType: EnumsMarketingFormat, options?: any): AxiosPromise<ClientCampaignsGetAssetsResponse> {
            return localVarFp.clientCampaignsGetAssets(campaignType, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint gets the information if any campaign with given Format exists for current eshop.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetCampaignStatus(campaignType: EnumsMarketingFormat, options?: any): AxiosPromise<ClientCampaignsGetCampaignStatusResponse> {
            return localVarFp.clientCampaignsGetCampaignStatus(campaignType, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint gets the value of daily budget as well as minimal budget information for provided marketing format campaign.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetDailyBudget(campaignType: EnumsMarketingFormat, options?: any): AxiosPromise<ClientCampaignsGetDailyBudgetResponse> {
            return localVarFp.clientCampaignsGetDailyBudget(campaignType, options).then((request) => request(axios, basePath));
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetDashboardStatus(options?: any): AxiosPromise<ClientCampaignsGetStatusResponse> {
            return localVarFp.clientCampaignsGetDashboardStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a link to the FB business sharing interface
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetFBBusinessOauthUrl(options?: any): AxiosPromise<ClientCampaignsGetFBBusinessOauthUrlResponse> {
            return localVarFp.clientCampaignsGetFBBusinessOauthUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the name and link of the linked FB page
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetFBLinkedPageInfo(options?: any): AxiosPromise<ClientCampaignsGetFBLinkedPageInfoResponse> {
            return localVarFp.clientCampaignsGetFBLinkedPageInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a link to the FB page sharing interface
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetFBPagesOauthUrl(options?: any): AxiosPromise<ClientCampaignsGetFBPagesOauthUrlResponse> {
            return localVarFp.clientCampaignsGetFBPagesOauthUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * Get status for provided {CampaignType} campaign. This does not reflect reality in marketing platform (Google/Facebook) but desired status of the user.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsGetStatus(campaignType: EnumsMarketingFormat, options?: any): AxiosPromise<ClientCampaignsGetStatusResponse> {
            return localVarFp.clientCampaignsGetStatus(campaignType, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint sets the value of daily budget for provided marketing format campaign. First it attempts to set the value using the corresponding platform API (e.g., Google Ads, Facebook Ads). If the API call fails, it throws an error and skip the change in database. In case of success, it updates the value inside the database and returns success.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsSetDailyBudgetRequest} [clientCampaignsSetDailyBudgetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsSetDailyBudget(campaignType: EnumsMarketingFormat, clientCampaignsSetDailyBudgetRequest?: ClientCampaignsSetDailyBudgetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientCampaignsSetDailyBudget(campaignType, clientCampaignsSetDailyBudgetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint reverses the status of a marketing campaign in both the database and corresponding marketing platforms (e.g., Google Ads, Facebook Ads) based on the provided MarketingFormat. If the database operation fails, it throws an error and skips the API calls. If a platform API call switch fails, it still returns success and attempts the switch later based on status in the database.
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsSetStatusRequest} [clientCampaignsSetStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsSetStatus(campaignType: EnumsMarketingFormat, clientCampaignsSetStatusRequest?: ClientCampaignsSetStatusRequest, options?: any): AxiosPromise<ClientCampaignsSetStatusResponse> {
            return localVarFp.clientCampaignsSetStatus(campaignType, clientCampaignsSetStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds TGNCA as agency to the clients shared page and notifies admins via email
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientCampaignsShareFBPageWithTanganicaRequest} [clientCampaignsShareFBPageWithTanganicaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsShareFBPageWithTanganica(clientCampaignsShareFBPageWithTanganicaRequest?: ClientCampaignsShareFBPageWithTanganicaRequest, options?: any): AxiosPromise<ClientCampaignsShareFBPageWithTanganicaResponse> {
            return localVarFp.clientCampaignsShareFBPageWithTanganica(clientCampaignsShareFBPageWithTanganicaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint updates the Bing DSA ad text with updated assets.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsUpdateBingDSACampaign(options?: any): AxiosPromise<void> {
            return localVarFp.clientCampaignsUpdateBingDSACampaign(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint updates the DPA campaign Ad Creatives with updated assets.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsUpdateDPACampaign(options?: any): AxiosPromise<void> {
            return localVarFp.clientCampaignsUpdateDPACampaign(options).then((request) => request(axios, basePath));
        },
        /**
         * Used to store assets for {CampaignType} campaigns
         * @summary Policies: RequireEshopAccessToken
         * @param {EnumsMarketingFormat} campaignType 
         * @param {ClientCampaignsUploadAssetsRequest} [clientCampaignsUploadAssetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCampaignsUploadAssets(campaignType: EnumsMarketingFormat, clientCampaignsUploadAssetsRequest?: ClientCampaignsUploadAssetsRequest, options?: any): AxiosPromise<ClientCampaignsUploadAssetsResponse> {
            return localVarFp.clientCampaignsUploadAssets(campaignType, clientCampaignsUploadAssetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentRequest} [clientDTOGenerateBlogContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateBlogContent(clientDTOGenerateBlogContentRequest?: ClientDTOGenerateBlogContentRequest, options?: any): AxiosPromise<ClientDTOGenerateBlogContentResponse> {
            return localVarFp.clientContentGenerateBlogContent(clientDTOGenerateBlogContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentIdeaRequest} [clientDTOGenerateBlogContentIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateBlogContentIdea(clientDTOGenerateBlogContentIdeaRequest?: ClientDTOGenerateBlogContentIdeaRequest, options?: any): AxiosPromise<ClientDTOGenerateBlogContentIdeaResponse> {
            return localVarFp.clientContentGenerateBlogContentIdea(clientDTOGenerateBlogContentIdeaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateBlogContentCtrategyRequest} [clientDTOGenerateBlogContentCtrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateBlogContentStrategy(clientDTOGenerateBlogContentCtrategyRequest?: ClientDTOGenerateBlogContentCtrategyRequest, options?: any): AxiosPromise<ClientDTOGenerateBlogContentCtrategyResponse> {
            return localVarFp.clientContentGenerateBlogContentStrategy(clientDTOGenerateBlogContentCtrategyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateEmailContentStrategyRequest} [clientDTOGenerateEmailContentStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateEmailContentStrategy(clientDTOGenerateEmailContentStrategyRequest?: ClientDTOGenerateEmailContentStrategyRequest, options?: any): AxiosPromise<ClientDTOGenerateEmailContentStrategyResponse> {
            return localVarFp.clientContentGenerateEmailContentStrategy(clientDTOGenerateEmailContentStrategyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateNewsletterRequest} [clientDTOGenerateNewsletterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateNewsletter(clientDTOGenerateNewsletterRequest?: ClientDTOGenerateNewsletterRequest, options?: any): AxiosPromise<ClientDTOGenerateNewsletterResponse> {
            return localVarFp.clientContentGenerateNewsletter(clientDTOGenerateNewsletterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateNewsletterIdeaRequest} [clientDTOGenerateNewsletterIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateNewsletterIdea(clientDTOGenerateNewsletterIdeaRequest?: ClientDTOGenerateNewsletterIdeaRequest, options?: any): AxiosPromise<ClientDTOGenerateNewsletterIdeaResponse> {
            return localVarFp.clientContentGenerateNewsletterIdea(clientDTOGenerateNewsletterIdeaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentRequest} [clientDTOGenerateSocialMediaContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContent(clientDTOGenerateSocialMediaContentRequest?: ClientDTOGenerateSocialMediaContentRequest, options?: any): AxiosPromise<ClientDTOGenerateSocialMediaContentResponse> {
            return localVarFp.clientContentGenerateSocialMediaContent(clientDTOGenerateSocialMediaContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentHeadlineRequest} [clientDTOGenerateSocialMediaContentHeadlineRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContentHeadline(clientDTOGenerateSocialMediaContentHeadlineRequest?: ClientDTOGenerateSocialMediaContentHeadlineRequest, options?: any): AxiosPromise<ClientDTOGenerateSocialMediaContentHeadlineResponse> {
            return localVarFp.clientContentGenerateSocialMediaContentHeadline(clientDTOGenerateSocialMediaContentHeadlineRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentIdeaRequest} [clientDTOGenerateSocialMediaContentIdeaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContentIdea(clientDTOGenerateSocialMediaContentIdeaRequest?: ClientDTOGenerateSocialMediaContentIdeaRequest, options?: any): AxiosPromise<ClientDTOGenerateSocialMediaContentIdeaResponse> {
            return localVarFp.clientContentGenerateSocialMediaContentIdea(clientDTOGenerateSocialMediaContentIdeaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGenerateSocialMediaContentStrategyRequest} [clientDTOGenerateSocialMediaContentStrategyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentGenerateSocialMediaContentStrategy(clientDTOGenerateSocialMediaContentStrategyRequest?: ClientDTOGenerateSocialMediaContentStrategyRequest, options?: any): AxiosPromise<ClientDTOGenerateSocialMediaContentStrategyResponse> {
            return localVarFp.clientContentGenerateSocialMediaContentStrategy(clientDTOGenerateSocialMediaContentStrategyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOOptimizeBlogContentRequest} [clientDTOOptimizeBlogContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientContentOptimizeBlogContent(clientDTOOptimizeBlogContentRequest?: ClientDTOOptimizeBlogContentRequest, options?: any): AxiosPromise<ClientDTOOptimizeBlogContentResponse> {
            return localVarFp.clientContentOptimizeBlogContent(clientDTOOptimizeBlogContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends the feed processing request
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDashboardFixFeedUrlRequest} [clientDashboardFixFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardFixFeedUrl(clientDashboardFixFeedUrlRequest?: ClientDashboardFixFeedUrlRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientDashboardFixFeedUrl(clientDashboardFixFeedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetActiveCampaignFormats(options?: any): AxiosPromise<ClientDashboardGetActiveCampaignFormatsResponse> {
            return localVarFp.clientDashboardGetActiveCampaignFormats(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets last feed state
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetFeedState(options?: any): AxiosPromise<ClientDashboardGetFeedStateResponse> {
            return localVarFp.clientDashboardGetFeedState(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the last feed processing error
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetLastFeedError(options?: any): AxiosPromise<ClientDashboardGetLastFeedErrorResponse> {
            return localVarFp.clientDashboardGetLastFeedError(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets number of learning phase orders. Learning phase orders are orders from all campaigns made in last 30 days.They are located in <b>Overview of your campaigns</b> section of <b>Dashboard</b> page
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetLearningPhaseOrders(options?: any): AxiosPromise<ClientDashboardGetLearningPhaseOrdersResponse> {
            return localVarFp.clientDashboardGetLearningPhaseOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if all steps of order measuring are set up
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetPixelMeasuringCondition(options?: any): AxiosPromise<ClientDashboardGetPixelMeasuringConditionResponse> {
            return localVarFp.clientDashboardGetPixelMeasuringCondition(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the product condition from the DB for given eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetProductsConditionCount(options?: any): AxiosPromise<ClientDashboardGetProductsConditionResponse> {
            return localVarFp.clientDashboardGetProductsConditionCount(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the product issues summary given eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDashboardGetProductsIssuesSummaryRequest} [clientDashboardGetProductsIssuesSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetProductsIssuesSummary(clientDashboardGetProductsIssuesSummaryRequest?: ClientDashboardGetProductsIssuesSummaryRequest, options?: any): AxiosPromise<ClientDashboardGetProductsIssuesSummaryResponse> {
            return localVarFp.clientDashboardGetProductsIssuesSummary(clientDashboardGetProductsIssuesSummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets remaining trial period for eshop with eshop ID provided in JWT
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetRemainingTrialPeriod(options?: any): AxiosPromise<ClientDashboardGetRemainingTrialPeriodResponse> {
            return localVarFp.clientDashboardGetRemainingTrialPeriod(options).then((request) => request(axios, basePath));
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetSetupStatus(options?: any): AxiosPromise<ClientDashboardGetSetupStatusResponse> {
            return localVarFp.clientDashboardGetSetupStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets campaign statistic data for last 30 days that are located in section basic statistics in dashboard.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetStatistics(options?: any): AxiosPromise<ClientDashboardGetStatisticsResponse> {
            return localVarFp.clientDashboardGetStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * The user sets whether the products should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetStatus(options?: any): AxiosPromise<ClientDashboardGetStatusResponse> {
            return localVarFp.clientDashboardGetStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * get the percentage of successfully measured customers of the eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetSuccessfullyMeasuredCustomersPercentage(options?: any): AxiosPromise<ClientDashboardGetSuccessfullyMeasuredCustomersPercentageResponse> {
            return localVarFp.clientDashboardGetSuccessfullyMeasuredCustomersPercentage(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current tanganica contribution percentage for the eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDashboardGetTgncaContributionPercentage(options?: any): AxiosPromise<ClientDashboardGetTgncaContributionPercentageResponse> {
            return localVarFp.clientDashboardGetTgncaContributionPercentage(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Returns only one eshop with same data structure as in GetEshops above. Designed to be used for admin LoginAs functionality only.
         * @summary Policies: RequireInternalEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopListGetEshop(options?: any): AxiosPromise<ClientEshopListGetEshopResponse> {
            return localVarFp.clientEshopListGetEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Returns a overview of eshops the user has. Including his roles in the eshops. Designed to be used for active eshop selection.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopListGetEshops(options?: any): AxiosPromise<ClientEshopListGetEshopsResponse> {
            return localVarFp.clientEshopListGetEshops(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsCreateEshopDescription(options?: any): AxiosPromise<void> {
            return localVarFp.clientEshopSettingsCreateEshopDescription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetCosMax(options?: any): AxiosPromise<ClientEshopSettingsGetCosMaxResponse> {
            return localVarFp.clientEshopSettingsGetCosMax(options).then((request) => request(axios, basePath));
        },
        /**
         * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetCreditNotificationLimit(options?: any): AxiosPromise<ClientEshopSettingsGetCreditNotificationLimitResponse> {
            return localVarFp.clientEshopSettingsGetCreditNotificationLimit(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetEshopDescription(options?: any): AxiosPromise<ClientEshopSettingsGetEshopDescriptionResponse> {
            return localVarFp.clientEshopSettingsGetEshopDescription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetFeedState(options?: any): AxiosPromise<ClientEshopSettingsGetFeedStateResponse> {
            return localVarFp.clientEshopSettingsGetFeedState(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetNotificationEmail(options?: any): AxiosPromise<ClientEshopSettingsGetNotificationEmailResponse> {
            return localVarFp.clientEshopSettingsGetNotificationEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets pixel status of current eshop.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetPixelStatus(options?: any): AxiosPromise<ClientEshopSettingsGetPixelStatusResponse> {
            return localVarFp.clientEshopSettingsGetPixelStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetProductFeedUrl(options?: any): AxiosPromise<ClientEshopSettingsGetProductFeedUrlResponse> {
            return localVarFp.clientEshopSettingsGetProductFeedUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsGetUsersWithAccess(options?: any): AxiosPromise<ClientEshopSettingsGetUsersWithAccessResponse> {
            return localVarFp.clientEshopSettingsGetUsersWithAccess(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> If invited user is alread registered he is linked to given eshop with given role - (only notification email is sent (?) ).If the invited email is not in the database, mail is sent to the given email address with link containing special token. This link redirects user to FE for registration, where email input is already set from the token<h3>Requirements</h3><ul><li><b>redirectUrl</b>: url to redirect user to with InviteToken - the url is sent to user by email - form on this url will call the RegisterInvited endpoint. The provided url will be appended with JWT token as follows: \"{redirectUrl}?InviteToken={token}\"</li></ul>
         * @summary Policies: , RequireEshopAccessToken
         * @param {ClientEshopSettingsInviteUserToEshopRequest} [clientEshopSettingsInviteUserToEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsInviteUser(clientEshopSettingsInviteUserToEshopRequest?: ClientEshopSettingsInviteUserToEshopRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientEshopSettingsInviteUser(clientEshopSettingsInviteUserToEshopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsRemoveUserFromEshopRequest} [clientEshopSettingsRemoveUserFromEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsRemoveUserFromEshop(clientEshopSettingsRemoveUserFromEshopRequest?: ClientEshopSettingsRemoveUserFromEshopRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientEshopSettingsRemoveUserFromEshop(clientEshopSettingsRemoveUserFromEshopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetCosMaxRequest} [clientEshopSettingsSetCosMaxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetCosMax(clientEshopSettingsSetCosMaxRequest?: ClientEshopSettingsSetCosMaxRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientEshopSettingsSetCosMax(clientEshopSettingsSetCosMaxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetCreditNotificationLimitRequest} [clientEshopSettingsSetCreditNotificationLimitRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetCreditNotificationLimit(clientEshopSettingsSetCreditNotificationLimitRequest?: ClientEshopSettingsSetCreditNotificationLimitRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientEshopSettingsSetCreditNotificationLimit(clientEshopSettingsSetCreditNotificationLimitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetNotificationEmailRequest} [clientEshopSettingsSetNotificationEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetNotificationEmail(clientEshopSettingsSetNotificationEmailRequest?: ClientEshopSettingsSetNotificationEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientEshopSettingsSetNotificationEmail(clientEshopSettingsSetNotificationEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientEshopSettingsSetProductFeedUrlRequest} [clientEshopSettingsSetProductFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientEshopSettingsSetProductFeedUrl(clientEshopSettingsSetProductFeedUrlRequest?: ClientEshopSettingsSetProductFeedUrlRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientEshopSettingsSetProductFeedUrl(clientEshopSettingsSetProductFeedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Feed processing logs
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLogsGetUserFeedLogs(options?: any): AxiosPromise<ClientDTOGetUserFeedLogsResponse> {
            return localVarFp.clientLogsGetUserFeedLogs(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets billing info for form prefill.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistoryGetBillingSettings(options?: any): AxiosPromise<ClientPaymentHistoryGetBillingSettingsResponse> {
            return localVarFp.clientPaymentHistoryGetBillingSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the presigned url to the invoice pdf stored in Spaces.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientPaymentHistoryGetInvoicePdfUrlRequest} [clientPaymentHistoryGetInvoicePdfUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistoryGetInvoicePdfUrl(clientPaymentHistoryGetInvoicePdfUrlRequest?: ClientPaymentHistoryGetInvoicePdfUrlRequest, options?: any): AxiosPromise<ClientPaymentHistoryGetInvoicePdfUrlResponse> {
            return localVarFp.clientPaymentHistoryGetInvoicePdfUrl(clientPaymentHistoryGetInvoicePdfUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of all invoice documents of eshop in database.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistoryGetPaymentHistory(options?: any): AxiosPromise<ClientPaymentHistoryGetPaymentHistoryResponse> {
            return localVarFp.clientPaymentHistoryGetPaymentHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Used to save/update billing data.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientPaymentHistorySetBillingSettingsRequest} [clientPaymentHistorySetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPaymentHistorySetBillingSettings(clientPaymentHistorySetBillingSettingsRequest?: ClientPaymentHistorySetBillingSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientPaymentHistorySetBillingSettings(clientPaymentHistorySetBillingSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Loads all categories for eshop products
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetCategories(options?: any): AxiosPromise<ClientDTOGetCategoriesResponse> {
            return localVarFp.clientProductsGetCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all Categories and their information
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetCategoriesData(options?: any): AxiosPromise<ClientDTOGetCategoriesDataResponse> {
            return localVarFp.clientProductsGetCategoriesData(options).then((request) => request(axios, basePath));
        },
        /**
         * Get status of Feed and Products
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetFeedStatus(options?: any): AxiosPromise<ClientDTOGetFeedStatusResponse> {
            return localVarFp.clientProductsGetFeedStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Loads all issues for given product in current Eshop
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductIssuesRequest} [clientDTOGetProductIssuesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetProductIssues(clientDTOGetProductIssuesRequest?: ClientDTOGetProductIssuesRequest, options?: any): AxiosPromise<ClientDTOGetProductIssuesResponse> {
            return localVarFp.clientProductsGetProductIssues(clientDTOGetProductIssuesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Loads all products for Eshop according to given sort and filters
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductsRequest} [clientDTOGetProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetProducts(clientDTOGetProductsRequest?: ClientDTOGetProductsRequest, options?: any): AxiosPromise<ClientDTOGetProductsResponse> {
            return localVarFp.clientProductsGetProducts(clientDTOGetProductsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the summary of products and their status
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsGetProductsSummary(options?: any): AxiosPromise<ClientDTOGetProductsSummaryResponse> {
            return localVarFp.clientProductsGetProductsSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * Sets the advertisement for given Category and changes Category attributes
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetCategoryAdvertisementRequest} [clientDTOSetCategoryAdvertisementRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsSetCategoryAdvertisement(clientDTOSetCategoryAdvertisementRequest?: ClientDTOSetCategoryAdvertisementRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientProductsSetCategoryAdvertisement(clientDTOSetCategoryAdvertisementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The user sets whether the category should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetMultipleProductsAdvertisementStatusRequest} [clientDTOSetMultipleProductsAdvertisementStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsSetMultipleProductsAdvertisementStatus(clientDTOSetMultipleProductsAdvertisementStatusRequest?: ClientDTOSetMultipleProductsAdvertisementStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientProductsSetMultipleProductsAdvertisementStatus(clientDTOSetMultipleProductsAdvertisementStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The user sets whether the product should be advertised or not
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetProductAdvertisementStatusRequest} [clientDTOSetProductAdvertisementStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientProductsSetProductAdvertisementStatus(clientDTOSetProductAdvertisementStatusRequest?: ClientDTOSetProductAdvertisementStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientProductsSetProductAdvertisementStatus(clientDTOSetProductAdvertisementStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates a bank transfer payment process within the application. When called, this endpoint does not perform a real financial transaction but generates a proforma invoice based on the user\'s selected credit amount. The response includes a presigned URL to access the generated invoice and a QR code string for payment facilitation.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOBankTransferPaymentRequest} [clientDTOBankTransferPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditBankTransferPayment(clientDTOBankTransferPaymentRequest?: ClientDTOBankTransferPaymentRequest, options?: any): AxiosPromise<ClientDTOBankTransferPaymentResponse> {
            return localVarFp.clientRechargeCreditBankTransferPayment(clientDTOBankTransferPaymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new checkout session for processing payments. Session is returned as clientSecret that have to be used to get the session through Stripe API.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOCreateCheckoutRequest} [clientDTOCreateCheckoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditCreateCheckout(clientDTOCreateCheckoutRequest?: ClientDTOCreateCheckoutRequest, options?: any): AxiosPromise<ClientDTOCreateCheckoutResponse> {
            return localVarFp.clientRechargeCreditCreateCheckout(clientDTOCreateCheckoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new checkout setup session for saving cards. Session is returned as clientSecret that have to be used to get the session through Stripe API.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOCreateSetupRequest} [clientDTOCreateSetupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditCreateSetup(clientDTOCreateSetupRequest?: ClientDTOCreateSetupRequest, options?: any): AxiosPromise<ClientDTOCreateCheckoutResponse> {
            return localVarFp.clientRechargeCreditCreateSetup(clientDTOCreateSetupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetAutomaticCreditRechargeOptions(options?: any): AxiosPromise<ClientDTOGetAutomaticCreditRechargeOptionsResponse> {
            return localVarFp.clientRechargeCreditGetAutomaticCreditRechargeOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetAutomaticCreditRechargeStatus(options?: any): AxiosPromise<ClientDTOGetAutomaticCreditRechargeStatusResponse> {
            return localVarFp.clientRechargeCreditGetAutomaticCreditRechargeStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetEshopTaxRate(options?: any): AxiosPromise<ClientDTOGetEshopTaxRateResponse> {
            return localVarFp.clientRechargeCreditGetEshopTaxRate(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns default amount for payments with currency of provided eshop in Token.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetPaymentDefaultAmount(options?: any): AxiosPromise<ClientDTOGetPaymentDefaultAmountResponse> {
            return localVarFp.clientRechargeCreditGetPaymentDefaultAmount(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets checkout sessions response status and email of customer specified by provided session ID.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetSessionStatusRequest} [clientDTOGetSessionStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditGetSessionStatus(clientDTOGetSessionStatusRequest?: ClientDTOGetSessionStatusRequest, options?: any): AxiosPromise<ClientDTOGetSessionStatusResponse> {
            return localVarFp.clientRechargeCreditGetSessionStatus(clientDTOGetSessionStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns true if user present in Token has saved card.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditHasSavedCard(options?: any): AxiosPromise<ClientDTOHasSavedCardResponse> {
            return localVarFp.clientRechargeCreditHasSavedCard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetAutomaticCreditRechargeOptionsRequest} [clientDTOSetAutomaticCreditRechargeOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditSetAutomaticCreditRechargeOptions(clientDTOSetAutomaticCreditRechargeOptionsRequest?: ClientDTOSetAutomaticCreditRechargeOptionsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientRechargeCreditSetAutomaticCreditRechargeOptions(clientDTOSetAutomaticCreditRechargeOptionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOSetAutomaticCreditRechargeStatusRequest} [clientDTOSetAutomaticCreditRechargeStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRechargeCreditSetAutomaticCreditRechargeStatus(clientDTOSetAutomaticCreditRechargeStatusRequest?: ClientDTOSetAutomaticCreditRechargeStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientRechargeCreditSetAutomaticCreditRechargeStatus(clientDTOSetAutomaticCreditRechargeStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportCampaignStatsRequest} [clientReportExportCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportCampaignStats(clientReportExportCampaignStatsRequest?: ClientReportExportCampaignStatsRequest, options?: any): AxiosPromise<ClientReportExportCampaignStatsResponse> {
            return localVarFp.clientReportExportCampaignStats(clientReportExportCampaignStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportOrderStatsRequest} [clientReportExportOrderStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportOrderStats(clientReportExportOrderStatsRequest?: ClientReportExportOrderStatsRequest, options?: any): AxiosPromise<ClientReportExportOrderStatsResponse> {
            return localVarFp.clientReportExportOrderStats(clientReportExportOrderStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportProductCategoryStatsRequest} [clientReportExportProductCategoryStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportProductCategoryStats(clientReportExportProductCategoryStatsRequest?: ClientReportExportProductCategoryStatsRequest, options?: any): AxiosPromise<ClientReportExportProductCategoryStatsResponse> {
            return localVarFp.clientReportExportProductCategoryStats(clientReportExportProductCategoryStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportProductStatsRequest} [clientReportExportProductStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportProductStats(clientReportExportProductStatsRequest?: ClientReportExportProductStatsRequest, options?: any): AxiosPromise<ClientReportExportProductStatsResponse> {
            return localVarFp.clientReportExportProductStats(clientReportExportProductStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportTrafficStatsRequest} [clientReportExportTrafficStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportTrafficStats(clientReportExportTrafficStatsRequest?: ClientReportExportTrafficStatsRequest, options?: any): AxiosPromise<ClientReportExportTrafficStatsResponse> {
            return localVarFp.clientReportExportTrafficStats(clientReportExportTrafficStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientReportExportVisitSourceStatsRequest} [clientReportExportVisitSourceStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientReportExportVisitSourceStats(clientReportExportVisitSourceStatsRequest?: ClientReportExportVisitSourceStatsRequest, options?: any): AxiosPromise<ClientReportExportVisitSourceStatsResponse> {
            return localVarFp.clientReportExportVisitSourceStats(clientReportExportVisitSourceStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets data for client/report page in app.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetCampaignStatsRequest} [clientDTOGetCampaignStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetCampaignStats(clientDTOGetCampaignStatsRequest?: ClientDTOGetCampaignStatsRequest, options?: any): AxiosPromise<ClientDTOGetCampaignStatsResponse> {
            return localVarFp.clientStatisticsGetCampaignStats(clientDTOGetCampaignStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetCampaignStatsGraphDataRequest} [clientDTOGetCampaignStatsGraphDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetCampaignStatsGraphData(clientDTOGetCampaignStatsGraphDataRequest?: ClientDTOGetCampaignStatsGraphDataRequest, options?: any): AxiosPromise<ClientDTOGetCampaignStatsGraphDataResponse> {
            return localVarFp.clientStatisticsGetCampaignStatsGraphData(clientDTOGetCampaignStatsGraphDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetGmvTrendChartDataRequest} [clientDTOGetGmvTrendChartDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetGmvTrendChartData(clientDTOGetGmvTrendChartDataRequest?: ClientDTOGetGmvTrendChartDataRequest, options?: any): AxiosPromise<ClientDTOGetGmvTrendChartDataResponse> {
            return localVarFp.clientStatisticsGetGmvTrendChartData(clientDTOGetGmvTrendChartDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetOrderStatsRequest} [clientDTOGetOrderStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetOrderStats(clientDTOGetOrderStatsRequest?: ClientDTOGetOrderStatsRequest, options?: any): AxiosPromise<ClientDTOGetOrderStatsResponse> {
            return localVarFp.clientStatisticsGetOrderStats(clientDTOGetOrderStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductCategoryStatsRequest} [clientDTOGetProductCategoryStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetProductCategoryStats(clientDTOGetProductCategoryStatsRequest?: ClientDTOGetProductCategoryStatsRequest, options?: any): AxiosPromise<ClientDTOGetProductCategoryStatsResponse> {
            return localVarFp.clientStatisticsGetProductCategoryStats(clientDTOGetProductCategoryStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetProductStatsRequest} [clientDTOGetProductStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetProductStats(clientDTOGetProductStatsRequest?: ClientDTOGetProductStatsRequest, options?: any): AxiosPromise<ClientDTOGetProductStatsResponse> {
            return localVarFp.clientStatisticsGetProductStats(clientDTOGetProductStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetTrafficStatsRequest} [clientDTOGetTrafficStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetTrafficStats(clientDTOGetTrafficStatsRequest?: ClientDTOGetTrafficStatsRequest, options?: any): AxiosPromise<ClientDTOGetTrafficStatsResponse> {
            return localVarFp.clientStatisticsGetTrafficStats(clientDTOGetTrafficStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientDTOGetVisitSourceStatsRequest} [clientDTOGetVisitSourceStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientStatisticsGetVisitSourceStats(clientDTOGetVisitSourceStatsRequest?: ClientDTOGetVisitSourceStatsRequest, options?: any): AxiosPromise<ClientDTOGetVisitSourceStatsResponse> {
            return localVarFp.clientStatisticsGetVisitSourceStats(clientDTOGetVisitSourceStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Endpoint for Contact Us tab of the app
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientSurveySendContactFormRequest} [clientSurveySendContactFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSurveySendContactForm(clientSurveySendContactFormRequest?: ClientSurveySendContactFormRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientSurveySendContactForm(clientSurveySendContactFormRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the current credit for a specific e-shop using the provided e-shop ID from access token.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientTopMenuGetCredit(options?: any): AxiosPromise<ClientDTOGetCreditResponse> {
            return localVarFp.clientTopMenuGetCredit(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserAffiliateGetAffilContributionForAllOwnedEshops(options?: any): AxiosPromise<ClientDTOGetAffilContributionForAllOwnedEshopsResponse> {
            return localVarFp.clientUserAffiliateGetAffilContributionForAllOwnedEshops(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserAffiliateGetCompanyInfoForAffilInvoice(options?: any): AxiosPromise<ClientDTOGetCompanyInfoForAffilInvoiceResponse> {
            return localVarFp.clientUserAffiliateGetCompanyInfoForAffilInvoice(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Sets the user\'s email to what is in the input. Sends an email with confirmation link. Confirmation link calls the ConfirmEmailChange enspoint in CallbacksController
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsChangeEmailRequest} [clientUserSettingsChangeEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsChangeEmail(clientUserSettingsChangeEmailRequest?: ClientUserSettingsChangeEmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientUserSettingsChangeEmail(clientUserSettingsChangeEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> This endpoint allows authenticated users to change their password. It requires the user to provide their current (old) password, along with the desired new password and a confirmation of the new password. The endpoint verifies the old password, and if it matches the user\'s current password and the new password and confirmation match, the user\'s password will be updated.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsChangePasswordRequest} [clientUserSettingsChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsChangePassword(clientUserSettingsChangePasswordRequest?: ClientUserSettingsChangePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientUserSettingsChangePassword(clientUserSettingsChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets user\'s name.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsGetName(options?: any): AxiosPromise<ClientUserSettingsGetNameResponse> {
            return localVarFp.clientUserSettingsGetName(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets user\'s phone number.
         * @summary Policies: RequireUserAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsGetPhoneNumber(options?: any): AxiosPromise<ClientUserSettingsGetPhoneNumberResponse> {
            return localVarFp.clientUserSettingsGetPhoneNumber(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> The user\'s location will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsLocaleRequest} [clientUserSettingsLocaleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsSetLocale(clientUserSettingsLocaleRequest?: ClientUserSettingsLocaleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientUserSettingsSetLocale(clientUserSettingsLocaleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> The user\'s name will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsSetNameRequest} [clientUserSettingsSetNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsSetName(clientUserSettingsSetNameRequest?: ClientUserSettingsSetNameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientUserSettingsSetName(clientUserSettingsSetNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> The user\'s phone number will change. Returns if the localization was successfully changed
         * @summary Policies: RequireUserAccessToken
         * @param {ClientUserSettingsSetPhoneNumberRequest} [clientUserSettingsSetPhoneNumberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUserSettingsSetPhoneNumber(clientUserSettingsSetPhoneNumberRequest?: ClientUserSettingsSetPhoneNumberRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientUserSettingsSetPhoneNumber(clientUserSettingsSetPhoneNumberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Creates new eshop in the database and links it with user. Given user has role EshopOwner in created eshop.Returns Id of newly created eshop. Use it to request eshop specific access token 😉.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientWizardAddEshopRequest} [clientWizardAddEshopRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardAddEshop(clientWizardAddEshopRequest?: ClientWizardAddEshopRequest, options?: any): AxiosPromise<ClientWizardAddEshopResponse> {
            return localVarFp.clientWizardAddEshop(clientWizardAddEshopRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Provides the pixel status. Return only true for testing. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardCheckPixel(options?: any): AxiosPromise<ClientWizardCheckPixelResponse> {
            return localVarFp.clientWizardCheckPixel(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets billing info for form prefill. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetBillingSettings(options?: any): AxiosPromise<ClientWizardGetBillingSettingsResponse> {
            return localVarFp.clientWizardGetBillingSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets basic eshop info for AddEshop form prefill in wizard. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetEshop(options?: any): AxiosPromise<ClientWizardGetEshopResponse> {
            return localVarFp.clientWizardGetEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the Url of the eshop - used for redirection while pixel verifing process
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetEshopUrl(options?: any): AxiosPromise<ClientWizardGetEshopUrlResponse> {
            return localVarFp.clientWizardGetEshopUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Gets the eshop\'s product feed url from db. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetProductFeedUrl(options?: any): AxiosPromise<ClientWizardGetProductFeedUrlResponse> {
            return localVarFp.clientWizardGetProductFeedUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets remaining trial period for eshop with eshop ID provided in JWT
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardGetRemainingTrialPeriod(options?: any): AxiosPromise<ClientWizardGetRemainingTrialPeriodResponse> {
            return localVarFp.clientWizardGetRemainingTrialPeriod(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Create the neccessities for Eshop to function and process its data.
         * @summary Policies: RequireEshopAccessToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardInitEshop(options?: any): AxiosPromise<void> {
            return localVarFp.clientWizardInitEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Used to save/update billing data. To be moved to wizard controller.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientWizardSetBillingSettingsRequest} [clientWizardSetBillingSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardSetBillingSettings(clientWizardSetBillingSettingsRequest?: ClientWizardSetBillingSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientWizardSetBillingSettings(clientWizardSetBillingSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Used to set the url for the product feed. Given feed link is checked and if correct, saved to DB.
         * @summary Policies: RequireEshopAccessToken
         * @param {ClientWizardSetProductFeedUrlRequest} [clientWizardSetProductFeedUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardSetProductFeedUrl(clientWizardSetProductFeedUrlRequest?: ClientWizardSetProductFeedUrlRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientWizardSetProductFeedUrl(clientWizardSetProductFeedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * <h3>Description</h3> Updates users Name and PhoneNumber. This endpoint is called when user adds his first eshop right after he registers into the app.
         * @summary Policies: RequireUserAccessToken
         * @param {ClientWizardUpdateUserRequest} [clientWizardUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientWizardUpdateUser(clientWizardUpdateUserRequest?: ClientWizardUpdateUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clientWizardUpdateUser(clientWizardUpdateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronAutomaticCreditTopUp(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronAutomaticCreditTopUp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronBackupLogs(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronBackupLogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCampaignDependenciesCheck(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronCampaignDependenciesCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCampaignStatusesComparison(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronCampaignStatusesComparison(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCheckForMissingAppImports(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronCheckForMissingAppImports(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCleanProductCronLogs(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronCleanProductCronLogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronCountGmcSubaccounts(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronCountGmcSubaccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronEshopsContributionList(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronEshopsContributionList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportCampaignHashes(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportCampaignHashes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportCampaignPerformanceData(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportCampaignPerformanceData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopMarketingInfo(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportEshopMarketingInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopsActivityDuration(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportEshopsActivityDuration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopsList(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportEshopsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportEshopsProducts(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportEshopsProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportInvoicesCsv(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportInvoicesCsv(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportInvoicesXml(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportInvoicesXml(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportInvoicesZip(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportInvoicesZip(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportPartnershipReportLastMonth(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportPartnershipReportLastMonth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronExportPartnershipReportThisMonth(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronExportPartnershipReportThisMonth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {CronCronFillDemoDataRequest} [cronCronFillDemoDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronFillDemoData(cronCronFillDemoDataRequest?: CronCronFillDemoDataRequest, options?: any): AxiosPromise<void> {
            return localVarFp.cronCronFillDemoData(cronCronFillDemoDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronGCLIDsExport(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronGCLIDsExport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportCampaignStatistics(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronImportCampaignStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportExchangeRates(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronImportExchangeRates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportOrders(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronImportOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportOrdersYesterday(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronImportOrdersYesterday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportPixelEvents(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronImportPixelEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportPseudoUsers(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronImportPseudoUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronImportSessions(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronImportSessions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronProcessFeeds(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronProcessFeeds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronProcessProducts(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronProcessProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronSendNoCreditSequenceMails(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronSendNoCreditSequenceMails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronSendWelcomeSequenceMails(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronSendWelcomeSequenceMails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateEshopsDashboardStatuses(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronUpdateEshopsDashboardStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateEshopsLastTwoWeeksCos(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronUpdateEshopsLastTwoWeeksCos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateEshopsTable(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronUpdateEshopsTable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUpdateProductsStatus(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronUpdateProductsStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireCronToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cronCronUploadProductsToPlatforms(options?: any): AxiosPromise<void> {
            return localVarFp.cronCronUploadProductsToPlatforms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EshopTanganicaProductsDTOGetAllProductsRequest} [eshopTanganicaProductsDTOGetAllProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopTanganicaProductsGetAllProducts(eshopTanganicaProductsDTOGetAllProductsRequest?: EshopTanganicaProductsDTOGetAllProductsRequest, options?: any): AxiosPromise<EshopTanganicaProductsDTOGetAllProductsResponse> {
            return localVarFp.eshopTanganicaProductsGetAllProducts(eshopTanganicaProductsDTOGetAllProductsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EshopTanganicaProductsDTOGetProductDetailRequest} [eshopTanganicaProductsDTOGetProductDetailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eshopTanganicaProductsGetProductDetail(eshopTanganicaProductsDTOGetProductDetailRequest?: EshopTanganicaProductsDTOGetProductDetailRequest, options?: any): AxiosPromise<EshopTanganicaProductsDTOGetProductDetailResponse> {
            return localVarFp.eshopTanganicaProductsGetProductDetail(eshopTanganicaProductsDTOGetProductDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualProcessAllAvailableFeeds(useProxy?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceFeedsManualProcessAllAvailableFeeds(useProxy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {MaintenanceDTOProcessFeedsForGivenEshopsRequest} [maintenanceDTOProcessFeedsForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualProcessFeedsForGivenEshops(useProxy?: boolean, maintenanceDTOProcessFeedsForGivenEshopsRequest?: MaintenanceDTOProcessFeedsForGivenEshopsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceFeedsManualProcessFeedsForGivenEshops(useProxy, maintenanceDTOProcessFeedsForGivenEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {boolean} [useProxy] 
         * @param {MaintenanceDTOProcessFeedsForManyGivenEshopsRequest} [maintenanceDTOProcessFeedsForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualProcessFeedsForManyGivenEshops(useProxy?: boolean, maintenanceDTOProcessFeedsForManyGivenEshopsRequest?: MaintenanceDTOProcessFeedsForManyGivenEshopsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceFeedsManualProcessFeedsForManyGivenEshops(useProxy, maintenanceDTOProcessFeedsForManyGivenEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceFeedsManualSynchronizeFeedProductsForEshop(eshopId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceFeedsManualSynchronizeFeedProductsForEshop(eshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceAnonymizeUser(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceAnonymizeUser(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOChangeOrderContributionRequest} [maintenanceDTOChangeOrderContributionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceChangeOrderContribution(maintenanceDTOChangeOrderContributionRequest?: MaintenanceDTOChangeOrderContributionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceChangeOrderContribution(maintenanceDTOChangeOrderContributionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingFBCatalogsNew(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceCreateMissingFBCatalogsNew(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingGoogleSubaccounts(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceCreateMissingGoogleSubaccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingGoogleSubaccountsNew(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceCreateMissingGoogleSubaccountsNew(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOCurrencyChangeBackRequest} [maintenanceDTOCurrencyChangeBackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceCurrencyChangeBack(maintenanceDTOCurrencyChangeBackRequest?: MaintenanceDTOCurrencyChangeBackRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceCurrencyChangeBack(maintenanceDTOCurrencyChangeBackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTODeleteOrderRequest} [maintenanceDTODeleteOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceDeleteOrder(maintenanceDTODeleteOrderRequest?: MaintenanceDTODeleteOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceDeleteOrder(maintenanceDTODeleteOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceEliminateSubaccountDuplicates(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceEliminateSubaccountDuplicates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceGetCronToken(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceGetCronToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceGetDevToken(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceGetDevToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceImportExchangeRates(date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceImportExchangeRates(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceMigrateExchangeRates(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceMigrateExchangeRates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceSwitchFBCampaignsIds(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceSwitchFBCampaignsIds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMaintenanceTestLog(options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceMaintenanceTestLog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUpdateProductsStatusForGivenEshops(maintenanceDTOUpdateProductsStatusForGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceProductsManualUpdateProductsStatusForGivenEshops(maintenanceDTOUpdateProductsStatusForGivenEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUpdateProductsStatusForManyGivenEshops(maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceProductsManualUpdateProductsStatusForManyGivenEshops(maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUploadProductsForManyGivenEshopsRequest} [maintenanceDTOUploadProductsForManyGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUploadProductsForManyGivenEshops(maintenanceDTOUploadProductsForManyGivenEshopsRequest?: MaintenanceDTOUploadProductsForManyGivenEshopsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceProductsManualUploadProductsForManyGivenEshops(maintenanceDTOUploadProductsForManyGivenEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: RequireDevToken
         * @param {MaintenanceDTOUploadProductsForGivenEshopsRequest} [maintenanceDTOUploadProductsForGivenEshopsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceProductsManualUploadProductsGivenEshops(maintenanceDTOUploadProductsForGivenEshopsRequest?: MaintenanceDTOUploadProductsForGivenEshopsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceProductsManualUploadProductsGivenEshops(maintenanceDTOUploadProductsForGivenEshopsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestFillMissingMicrosoftSettingsTables(options?: any): AxiosPromise<void> {
            return localVarFp.testMTestFillMissingMicrosoftSettingsTables(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestMiskoTemp(eshopId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestMiskoTemp(eshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accountName] 
         * @param {number} [parentCustomerId] 
         * @param {number} [superAdminId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAccount(accountName?: string, parentCustomerId?: number, superAdminId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsAddAccount(accountName, parentCustomerId, superAdminId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [adGroupId] 
         * @param {CampaignManagementAd} [campaignManagementAd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAd(accountId?: number, adGroupId?: number, campaignManagementAd?: CampaignManagementAd, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsAddAd(accountId, adGroupId, campaignManagementAd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {boolean} [returnInheritedBidStrategyTypes] 
         * @param {CampaignManagementAdGroup} [campaignManagementAdGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAdGroup(accountId?: number, campaignId?: number, returnInheritedBidStrategyTypes?: boolean, campaignManagementAdGroup?: CampaignManagementAdGroup, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsAddAdGroup(accountId, campaignId, returnInheritedBidStrategyTypes, campaignManagementAdGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementAssetGroup} [campaignManagementAssetGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddAssetGroup(accountId?: number, campaignId?: number, campaignManagementAssetGroup?: CampaignManagementAssetGroup, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsAddAssetGroup(accountId, campaignId, campaignManagementAssetGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddCampaign(accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsAddCampaign(accountId, campaignManagementCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaignCriterionType} [criterionType] 
         * @param {Array<CampaignManagementCampaignCriterion>} [campaignManagementCampaignCriterion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddCampaignCriterions(accountId?: number, criterionType?: CampaignManagementCampaignCriterionType, campaignManagementCampaignCriterion?: Array<CampaignManagementCampaignCriterion>, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsAddCampaignCriterions(accountId, criterionType, campaignManagementCampaignCriterion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [imagePath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsAddMedia(accountId?: number, imagePath?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsAddMedia(accountId, imagePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {string} [countryCode] 
         * @param {string} [description1] 
         * @param {string} [description2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsCreateDSA(accountId?: number, marketingId?: number, domainName?: string, countryCode?: string, description1?: string, description2?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsCreateDSA(accountId, marketingId, domainName, countryCode, description1, description2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [countryCode] 
         * @param {number} [storeId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsCreatePLA(accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsCreatePLA(accountId, countryCode, storeId, marketingId, domainName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [countryCode] 
         * @param {number} [storeId] 
         * @param {number} [marketingId] 
         * @param {string} [domainName] 
         * @param {string} [businessName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsCreatePRX(accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, businessName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsCreatePRX(accountId, countryCode, storeId, marketingId, domainName, businessName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsDeleteAccount(accountId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsDeleteAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsDeleteCampaign(accountId?: number, campaignId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsDeleteCampaign(accountId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CustomerManagementAccountAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetAccount(accountId?: number, returnAdditionalFields?: CustomerManagementAccountAdditionalField, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetAccount(accountId, returnAdditionalFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementAdGroupAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetAdGroupsByCampaignId(accountId?: number, campaignId?: number, returnAdditionalFields?: CampaignManagementAdGroupAdditionalField, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetAdGroupsByCampaignId(accountId, campaignId, returnAdditionalFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [adGroupId] 
         * @param {CampaignManagementAdType} [adType] 
         * @param {CampaignManagementAdAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetAdsByAdGroupId(accountId?: number, adGroupId?: number, adType?: CampaignManagementAdType, returnAdditionalFields?: CampaignManagementAdAdditionalField, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetAdsByAdGroupId(accountId, adGroupId, adType, returnAdditionalFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementCampaignType} [campaignType] 
         * @param {CampaignManagementCampaignAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetCampaign(accountId?: number, campaignId?: number, campaignType?: CampaignManagementCampaignType, returnAdditionalFields?: CampaignManagementCampaignAdditionalField, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetCampaign(accountId, campaignId, campaignType, returnAdditionalFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {number} [campaignId] 
         * @param {CampaignManagementCampaignCriterionType} [criterionType] 
         * @param {CampaignManagementCriterionAdditionalField} [returnAdditionalFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetCampaignCriterionsByIds(accountId?: number, campaignId?: number, criterionType?: CampaignManagementCampaignCriterionType, returnAdditionalFields?: CampaignManagementCriterionAdditionalField, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetCampaignCriterionsByIds(accountId, campaignId, criterionType, returnAdditionalFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaignType} [campaignType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetCampaignsByAccountId(accountId?: number, campaignType?: CampaignManagementCampaignType, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetCampaignsByAccountId(accountId, campaignType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetGeoLocationsFileUrl(accountId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetGeoLocationsFileUrl(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {AdInsightEntityType} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId(accountId?: number, entityType?: AdInsightEntityType, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId(accountId, entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsGetUser(options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsGetUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsSearchAccounts(options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsSearchAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportingCampaignPerformanceReportRequest} [reportingCampaignPerformanceReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsSubmitGenerateReport(reportingCampaignPerformanceReportRequest?: ReportingCampaignPerformanceReportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsSubmitGenerateReport(reportingCampaignPerformanceReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {string} [newName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsUpdateAccount(accountId?: number, newName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsUpdateAccount(accountId, newName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [accountId] 
         * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingAdsUpdateCampaign(accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingAdsUpdateCampaign(accountId, campaignManagementCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestBingGetCampaignReport(options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestBingGetCampaignReport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiBatchProducts(merchantId?: number, catalogId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiBatchProducts(merchantId, catalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiCreateCatalog(merchantId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiCreateCatalog(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [storeName] 
         * @param {string} [storeUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiCreateStore(storeName?: string, storeUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiCreateStore(storeName, storeUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiDeleteCatalog(merchantId?: number, catalogId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiDeleteCatalog(merchantId, catalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiDeleteProduct(merchantId?: number, productId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiDeleteProduct(merchantId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetCatalog(merchantId?: number, catalogId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetCatalog(merchantId, catalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetCatalogs(merchantId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetCatalogs(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProduct(merchantId?: number, productId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetProduct(merchantId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProducts(merchantId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetProducts(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProductsStatuses(merchantId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetProductsStatuses(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetProductsStatusesSummary(merchantId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetProductsStatusesSummary(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetStore(merchantId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetStore(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiGetStores(options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiGetStores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {string} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiInsertProduct(merchantId?: number, catalogId?: number, offerId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiInsertProduct(merchantId, catalogId, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [merchantId] 
         * @param {number} [catalogId] 
         * @param {boolean} [isPublishingEnabled] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestTestContentApiUpdateCatalog(merchantId?: number, catalogId?: number, isPublishingEnabled?: boolean, name?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testMTestTestContentApiUpdateCatalog(merchantId, catalogId, isPublishingEnabled, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestUpdateCategoryAdvertiseMicrosoft(options?: any): AxiosPromise<void> {
            return localVarFp.testMTestUpdateCategoryAdvertiseMicrosoft(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testMTestUpdateProductMicrosoftStatus(options?: any): AxiosPromise<void> {
            return localVarFp.testMTestUpdateProductMicrosoftStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAddAdminUser(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPTestAddAdminUser(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAdminMailsToResx(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestAdminMailsToResx(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAffilContrComparison(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestAffilContrComparison(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAnyCompensation(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestAnyCompensation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestAssignDeletedOwner(guid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPTestAssignDeletedOwner(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestBillingSettingsExporting(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestBillingSettingsExporting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCHECKSTRIPECUSTOMERS(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCHECKSTRIPECUSTOMERS(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCheckCreditTesting(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCheckCreditTesting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCheckOwners(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCheckOwners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCheckTransactions(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCheckTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreateMissingTriggerFunctionsInTenants(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCreateMissingTriggerFunctionsInTenants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditComparison(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCreditComparison(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditComparisonGivenEshop(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCreditComparisonGivenEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditComparisonInternal(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCreditComparisonInternal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditPerEshop(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCreditPerEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestCreditTenantComparison(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestCreditTenantComparison(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetAllActiveBillingSettings(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestGetAllActiveBillingSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetEshopsIsPayerOfTaxes(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestGetEshopsIsPayerOfTaxes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetInvalidBsEshops(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestGetInvalidBsEshops(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetInvalidInvoices(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestGetInvalidInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestGetSentMailNumbers(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestGetSentMailNumbers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestInvoiceGenTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestInvoiceGenTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestLocalizerTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestLocalizerTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestPTestDTOLogCountsRequest} [testPTestDTOLogCountsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestLogCounts(testPTestDTOLogCountsRequest?: TestPTestDTOLogCountsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.testPTestLogCounts(testPTestDTOLogCountsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestLogsToFiles(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestLogsToFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestMailTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestMailTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Policies: ; AdminPermissions: GetKpiReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestPermissionAuthTesting(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestPermissionAuthTesting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestPositiveAffilContrPerEshop(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestPositiveAffilContrPerEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestRegenerateFuckedInvoices(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestRegenerateFuckedInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestRoleClaims(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestRoleClaims(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendAllAdminEmails(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSendAllAdminEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendAllClientEmails(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSendAllClientEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendMail(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSendMail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSendMailWithAttachment(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSendMailWithAttachment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetCreditNoteAmountsNegative(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSetCreditNoteAmountsNegative(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetNullMailLogAdminMailTypes(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSetNullMailLogAdminMailTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetNullMailLogClientMailTypesNew(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSetNullMailLogClientMailTypesNew(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetNullMailLogClientMailTypesOld(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSetNullMailLogClientMailTypesOld(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetPassword(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSetPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSetUserAdmin(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSetUserAdmin(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestSyncInvoiceIssueDatesWithBs(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestSyncInvoiceIssueDatesWithBs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestTestMailsRedesign(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestTestMailsRedesign(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestTestUploadFile(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestTestUploadFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestUPDATESTRIPECUSTOMERS(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestUPDATESTRIPECUSTOMERS(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestUserClaimsRetrieval(options?: any): AxiosPromise<void> {
            return localVarFp.testPTestUserClaimsRetrieval(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestPTestDTOUtcDateValidationRequest} [testPTestDTOUtcDateValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPTestUtcDateTimeValidation(testPTestDTOUtcDateValidationRequest?: TestPTestDTOUtcDateValidationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.testPTestUtcDateTimeValidation(testPTestDTOUtcDateValidationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundAssignAdminRole(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundAssignAdminRole(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundAssignAffilRole(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundAssignAffilRole(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundAssignTestRole(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundAssignTestRole(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [batchSize] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundBulkInsertTest(batchSize?: number, pageSize?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundBulkInsertTest(batchSize, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCampaignStatisticsDataRepair(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCampaignStatisticsDataRepair(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCheckFBCampaignsCount(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCheckFBCampaignsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCheckPixel(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCheckPixel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCleanSpacesBucket(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCleanSpacesBucket(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCorrectUtmsManuallyForGivenMktIds(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCorrectUtmsManuallyForGivenMktIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateAllFbCatalogs(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCreateAllFbCatalogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateFacebookCampaignTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCreateFacebookCampaignTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateFbCampaigns(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCreateFbCampaigns(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [folderPath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateFolderOnDO(folderPath?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCreateFolderOnDO(folderPath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateMissingGoogleCampaigns(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCreateMissingGoogleCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundCreateMissingGoogleSubaccounts(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundCreateMissingGoogleSubaccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDataComparingTransactions(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDataComparingTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDataComparingTransactionsPerEshop(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDataComparingTransactionsPerEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDbViewTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDbViewTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteAdCreative(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteAdCreative(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteAllAssistedOrders(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteAllAssistedOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteBills(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteBills(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteDPACampaignByDbId(eshopId?: string, campaignId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteDPACampaignByDbId(eshopId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteGmcProductsForEshops(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteGmcProductsForEshops(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteOrdersFromBeforeMigration(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteOrdersFromBeforeMigration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteOrdersFromDay(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteOrdersFromDay(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteOrdersWithPseudoUserId(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteOrdersWithPseudoUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteStuff(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteStuff(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeleteTransactionsAndBills(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeleteTransactionsAndBills(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDeserializeTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDeserializeTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundDownloadImageToLocal(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundDownloadImageToLocal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundEshopCreditUpdateTriggerToAllSchemas(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundEshopCreditUpdateTriggerToAllSchemas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundEshopIdTestWrite(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundEshopIdTestWrite(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundEshopSubaccountsCheck(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundEshopSubaccountsCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFBDeleteWhatever(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFBDeleteWhatever(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [catalogId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFBItemStatus(catalogId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFBItemStatus(catalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFBRenameCatalogs(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFBRenameCatalogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFacebookCampaignStructureV3(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFacebookCampaignStructureV3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFbCampaignStatisticsFacebook(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFbCampaignStatisticsFacebook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFbCampaignsRestructure(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFbCampaignsRestructure(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFbDeleteAdsetsOld(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFbDeleteAdsetsOld(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFillAllAllowProducts(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFillAllAllowProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFillDailyBudgets(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFillDailyBudgets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFillFbCampaingMarketingFormat(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFillFbCampaingMarketingFormat(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindAllGmcProductErrors(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFindAllGmcProductErrors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindEshopsWithMissingSubaccounts(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFindEshopsWithMissingSubaccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [useCzkAdAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindFbAdsUtmDuplicates(useCzkAdAccount?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFindFbAdsUtmDuplicates(useCzkAdAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindFbCampaignsInfo(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFindFbCampaignsInfo(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindMarketingIds(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFindMarketingIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFindMissingFBAds(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFindMissingFBAds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [logOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixFbAdStatuses(logOnly?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFixFbAdStatuses(logOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [marketingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixFbAdUtms(marketingId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFixFbAdUtms(marketingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixGoogleCampaignStatisticsTimezone(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFixGoogleCampaignStatisticsTimezone(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixTaxRatesAndVariableSymbols(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFixTaxRatesAndVariableSymbols(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFixTransactions(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFixTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundFuckIIIIIIIIIIIIT(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundFuckIIIIIIIIIIIIT(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [productCount] 
         * @param {number} [feedCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGCTest(productCount?: number, feedCount?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGCTest(productCount, feedCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [feedCount] 
         * @param {number} [taskCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGCTestParallelRealData(feedCount?: number, taskCount?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGCTestParallelRealData(feedCount, taskCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGarbageCollectorGeneration2(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGarbageCollectorGeneration2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGenerateEshopSpecificAccessToken(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGenerateEshopSpecificAccessToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetActiveEshopsAndProductsCount(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetActiveEshopsAndProductsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [adAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAdCreatives(adAccountId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAdCreatives(adAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAdspendsPerCampaign(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAdspendsPerCampaign(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllAdExpensesForDay(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAllAdExpensesForDay(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllCampaignsGoogle(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAllCampaignsGoogle(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllFbCatalogs(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAllFbCatalogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllGmcSubaccountsCz(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAllGmcSubaccountsCz(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllGmcSubaccountsEu(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAllGmcSubaccountsEu(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetAllUnknown(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetAllUnknown(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCampaignStatsTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetCampaignStatsTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCampaignsWithMissingProductSets(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetCampaignsWithMissingProductSets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCreditDifference(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetCreditDifference(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCreditExpensesDifference(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetCreditExpensesDifference(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetCreditPerEshop(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetCreditPerEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetDesiredStatusIssues(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetDesiredStatusIssues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetEshopIdsFromMktIds(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetEshopIdsFromMktIds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetEshopsInfo(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetEshopsInfo(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetExchangeRate(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetExchangeRate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetFileFormatByHeader(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetFileFormatByHeader(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGmcLimits(tanganicaEu?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetGmcLimits(tanganicaEu, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {EnumsCountryOfAdvertisement} [country] 
         * @param {number} [merchantId] 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGmcProductIssues(productId?: string, country?: EnumsCountryOfAdvertisement, merchantId?: number, tanganicaEu?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetGmcProductIssues(productId, country, merchantId, tanganicaEu, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [tanganicaEu] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGmcSubaccountsCount(tanganicaEu?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetGmcSubaccountsCount(tanganicaEu, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [campaignId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetGoogleCampaignFormatTest(campaignId?: number, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetGoogleCampaignFormatTest(campaignId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientControllersGetHourlyCostMicrosRequest} [clientControllersGetHourlyCostMicrosRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetHourlyCostMicros(clientControllersGetHourlyCostMicrosRequest?: ClientControllersGetHourlyCostMicrosRequest, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetHourlyCostMicros(clientControllersGetHourlyCostMicrosRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetLookalikeAudienceByOriginAudienceId(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetLookalikeAudienceByOriginAudienceId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetOrderDataTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetOrderDataTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetOrdersPerCampaignsPerEshop(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetOrdersPerCampaignsPerEshop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGetSubaccountIdForEshop(eshopId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGetSubaccountIdForEshop(eshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [campaignId] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundGoogleAdsStatsTest(customerId?: number, campaignId?: number, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundGoogleAdsStatsTest(customerId, campaignId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundHttpClientTest(url?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundHttpClientTest(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportAppImportsRecords(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundImportAppImportsRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportDataFroNewlyMigratedCampaigns(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundImportDataFroNewlyMigratedCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportOrdersExactDay(date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundImportOrdersExactDay(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportOrdersExactTime(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundImportOrdersExactTime(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundImportStatisticsExactDay(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundImportStatisticsExactDay(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundListAllTgncaGlobalFbCatalogs(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundListAllTgncaGlobalFbCatalogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundListResponseTypeSwagger(options?: any): AxiosPromise<TestListResponseListResponse> {
            return localVarFp.testPlaygroundListResponseTypeSwagger(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundLogInsideBQ(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundLogInsideBQ(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundMeasureFeedSize(url?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundMeasureFeedSize(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundMigrateProductCategories(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundMigrateProductCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundMigrationTest(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundMigrationTest(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundPreloadController(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundPreloadController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundPrintPageViewPathFromJson(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundPrintPageViewPathFromJson(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRegexSplitTest(input?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundRegexSplitTest(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRemoveGmcFeedFile(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundRemoveGmcFeedFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRepairCampaignDesiredStatuses(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundRepairCampaignDesiredStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundRepairCampaigns(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundRepairCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundReuploadProductsGmc(eshopId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundReuploadProductsGmc(eshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundSetAllProductsGmcUpdateToTrue(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundSetAllProductsGmcUpdateToTrue(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {number} [budget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundSetFbCampaignBudget(campaignId?: string, budget?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundSetFbCampaignBudget(campaignId, budget, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundSetGmcProductsToReulpoad(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundSetGmcProductsToReulpoad(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTagMapSerializationTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTagMapSerializationTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestClearSheet(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestClearSheet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [hour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestDateTimeCreation(date?: string, hour?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestDateTimeCreation(date, hour, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestEshopBlacklist(eshopId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestEshopBlacklist(eshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [adCreativeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestFbAdCreativeUtm(adCreativeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestFbAdCreativeUtm(adCreativeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestFbCatalogs(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestFbCatalogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestFbStatuses(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestFbStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestIOptionsInjection(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestIOptionsInjection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestLinkUtmRemoving(input?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestLinkUtmRemoving(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestLog(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestLog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestMultiInitPerformance(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestMultiInitPerformance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestParsingPrice(input?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestParsingPrice(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestProductIssuesBlacklist(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestProductIssuesBlacklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestReadDate(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestReadDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [eshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestStatsImport(eshopId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestStatsImport(eshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestStatusFromGads(campaignId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestStatusFromGads(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestTriggerFunctionsFile(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestTriggerFunctionsFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTestikBlastik(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTestikBlastik(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [pid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTransactionWebhookTest(pid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTransactionWebhookTest(pid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundTurnOffFbAds(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundTurnOffFbAds(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundimportSessionsTest(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundimportSessionsTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundtestImportCampaignStatisticsBack(options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundtestImportCampaignStatisticsBack(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [productCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testPlaygroundtestMemory(productCount?: number, options?: any): AxiosPromise<void> {
            return localVarFp.testPlaygroundtestMemory(productCount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TANGANICAApi - object-oriented interface
 * @export
 * @class TANGANICAApi
 * @extends {BaseAPI}
 */
export class TANGANICAApi extends BaseAPI {
    /**
     * Creates a credit note for final invoice
     * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
     * @param {AdminAccountingGenerateCreditNoteRequest} [adminAccountingGenerateCreditNoteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAccountingGenerateCreditNote(adminAccountingGenerateCreditNoteRequest?: AdminAccountingGenerateCreditNoteRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAccountingGenerateCreditNote(adminAccountingGenerateCreditNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets eshop\'s credit.
     * @summary Policies: RequireInternalRole
     * @param {AdminAccountingGetEshopCreditRequest} [adminAccountingGetEshopCreditRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAccountingGetEshopCredit(adminAccountingGetEshopCreditRequest?: AdminAccountingGetEshopCreditRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAccountingGetEshopCredit(adminAccountingGetEshopCreditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the presigned url to the invoice pdf stored in Spaces.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
     * @param {AdminAccountingGetInvoicePdfUrlRequest} [adminAccountingGetInvoicePdfUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAccountingGetInvoicePdfUrl(adminAccountingGetInvoicePdfUrlRequest?: AdminAccountingGetInvoicePdfUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAccountingGetInvoicePdfUrl(adminAccountingGetInvoicePdfUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
     * @summary Policies: RequireInternalRole; AdminPermissions: GetAccounting
     * @param {AdminAccountingGetInvoicesRequest} [adminAccountingGetInvoicesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAccountingGetInvoices(adminAccountingGetInvoicesRequest?: AdminAccountingGetInvoicesRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAccountingGetInvoices(adminAccountingGetInvoicesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Regenerates the invoice with data from the database. Updates the regenerated invoice - changes Locale based on input and links latest BillingSetting
     * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
     * @param {AdminAccountingRegenerateInvoiceRequest} [adminAccountingRegenerateInvoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAccountingRegenerateInvoice(adminAccountingRegenerateInvoiceRequest?: AdminAccountingRegenerateInvoiceRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAccountingRegenerateInvoice(adminAccountingRegenerateInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Canceles the invoice.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
     * @param {AdminAccountingSetAsCanceledRequest} [adminAccountingSetAsCanceledRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAccountingSetAsCanceled(adminAccountingSetAsCanceledRequest?: AdminAccountingSetAsCanceledRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAccountingSetAsCanceled(adminAccountingSetAsCanceledRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets the ProformaInvoice or CreditNote status as paid.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetAccounting
     * @param {AdminAccountingSetAsPaidRequest} [adminAccountingSetAsPaidRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAccountingSetAsPaid(adminAccountingSetAsPaidRequest?: AdminAccountingSetAsPaidRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAccountingSetAsPaid(adminAccountingSetAsPaidRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to directly add user permissions to other internal users.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
     * @param {AdminAdminsAddAdminUserPermissionRequest} [adminAdminsAddAdminUserPermissionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsAddAdminUserPermission(adminAdminsAddAdminUserPermissionRequest?: AdminAdminsAddAdminUserPermissionRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsAddAdminUserPermission(adminAdminsAddAdminUserPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to  add user permissions to other internal users.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
     * @param {AdminAdminsAddAdminUserRoleRequest} [adminAdminsAddAdminUserRoleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsAddAdminUserRole(adminAdminsAddAdminUserRoleRequest?: AdminAdminsAddAdminUserRoleRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsAddAdminUserRole(adminAdminsAddAdminUserRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to get roles they are able to assign to given internal user.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
     * @param {AdminAdminsGetAssignableRolesRequest} [adminAdminsGetAssignableRolesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsGetAssignableRoles(adminAdminsGetAssignableRolesRequest?: AdminAdminsGetAssignableRolesRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsGetAssignableRoles(adminAdminsGetAssignableRolesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all users with their roles.
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsGetInternalUserRoles(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsGetInternalUserRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail sending settings.
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsGetSubscriptions(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsGetSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove user permissions of other internal users.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserPermission
     * @param {AdminAdminsRemoveAdminUserPermissionRequest} [adminAdminsRemoveAdminUserPermissionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsRemoveAdminUserPermission(adminAdminsRemoveAdminUserPermissionRequest?: AdminAdminsRemoveAdminUserPermissionRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsRemoveAdminUserPermission(adminAdminsRemoveAdminUserPermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to remove roles of other internal users.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetInternalUserRole
     * @param {AdminAdminsRemoveAdminUserRoleRequest} [adminAdminsRemoveAdminUserRoleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsRemoveAdminUserRole(adminAdminsRemoveAdminUserRoleRequest?: AdminAdminsRemoveAdminUserRoleRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsRemoveAdminUserRole(adminAdminsRemoveAdminUserRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
     * @summary Policies: RequireInternalRole
     * @param {AdminAdminsSubscribeRequest} [adminAdminsSubscribeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsSubscribe(adminAdminsSubscribeRequest?: AdminAdminsSubscribeRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsSubscribe(adminAdminsSubscribeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to unsubscribe users from receiving system mails.
     * @summary Policies: RequireInternalRole
     * @param {AdminAdminsUnsubscribeRequest} [adminAdminsUnsubscribeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAdminsUnsubscribe(adminAdminsUnsubscribeRequest?: AdminAdminsUnsubscribeRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAdminsUnsubscribe(adminAdminsUnsubscribeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
     * @param {AdminAuthForgottenPasswordRequest} [adminAuthForgottenPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAuthForgottenPassword(adminAuthForgottenPasswordRequest?: AdminAuthForgottenPasswordRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAuthForgottenPassword(adminAuthForgottenPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
     * @summary Policies: RequireRefreshToken
     * @param {AdminAuthEshopAccessTokenRequest} [adminAuthEshopAccessTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAuthGetEshopAccessToken(adminAuthEshopAccessTokenRequest?: AdminAuthEshopAccessTokenRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAuthGetEshopAccessToken(adminAuthEshopAccessTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint returns list of AdminPermissions current user has.
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAuthGetPermissions(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAuthGetPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
     * @summary Policies: RequireRefreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAuthGetUserAccessToken(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAuthGetUserAccessToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for admin login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
     * @param {AdminAuthLoginRequest} [adminAuthLoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAuthLogin(adminAuthLoginRequest?: AdminAuthLoginRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAuthLogin(adminAuthLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li></ul>
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAuthLogout(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAuthLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for registering new admin user. The new admin needs to re-log to obtain new access token.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal</li><li><b>Email</b>: Email of an already registered user who should be assigned with Internal role instead of Client role</li></ul>
     * @summary Policies: RequireInternalRole; AdminPermissions: RegisterNewAdmin
     * @param {AdminAuthRegisterRequest} [adminAuthRegisterRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminAuthRegister(adminAuthRegisterRequest?: AdminAuthRegisterRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminAuthRegister(adminAuthRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
     * @summary Policies: RequireResetPasswordToken
     * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminCallbacksResetPassword(clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminCallbacksResetPassword(clientCallbacksResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is tanganica admins or developers to be able logout all users<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin, Internal</li></ul>
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminClientAuthLogoutAll(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminClientAuthLogoutAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his Email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>Email</b>: valid user Email</li></ul>
     * @summary Policies: RequireInternalRole
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminClientAuthLogoutUserByEmail(email: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminClientAuthLogoutUserByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Admin AND Internal</li><li><b>UserId</b>: valid user guid</li></ul>
     * @summary Policies: RequireInternalRole
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminClientAuthLogoutUserById(id: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminClientAuthLogoutUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Adds (or removes) given amount of credit to eshop. (Creates appropriate transaction)
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailAddAdminTransactionRequest} [adminEshopsDetailAddAdminTransactionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailAddAdminTransaction(adminEshopsDetailAddAdminTransactionRequest?: AdminEshopsDetailAddAdminTransactionRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailAddAdminTransaction(adminEshopsDetailAddAdminTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Sets Eshop.Archived as true.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailArchiveEshopRequest} [adminEshopsDetailArchiveEshopRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailArchiveEshop(adminEshopsDetailArchiveEshopRequest?: AdminEshopsDetailArchiveEshopRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailArchiveEshop(adminEshopsDetailArchiveEshopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets currently active campaign formats
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetActiveCampaignFormatsRequest} [adminEshopsDetailGetActiveCampaignFormatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetActiveCampaignFormats(adminEshopsDetailGetActiveCampaignFormatsRequest?: AdminEshopsDetailGetActiveCampaignFormatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetActiveCampaignFormats(adminEshopsDetailGetActiveCampaignFormatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to get assets for {CampaignType} campaigns
     * @summary Policies: RequireInternalRole
     * @param {EnumsMarketingFormat} campaignType 
     * @param {AdminEshopsDetailGetAssetsRequest} [adminEshopsDetailGetAssetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetAssets(campaignType: EnumsMarketingFormat, adminEshopsDetailGetAssetsRequest?: AdminEshopsDetailGetAssetsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetAssets(campaignType, adminEshopsDetailGetAssetsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets eshop billing info.
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetBillingSettingsRequest} [adminEshopsDetailGetBillingSettingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetBillingSettings(adminEshopsDetailGetBillingSettingsRequest?: AdminEshopsDetailGetBillingSettingsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetBillingSettings(adminEshopsDetailGetBillingSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets all already created campaigns
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetCreatedCampaignsRequest} [adminEshopsDetailGetCreatedCampaignsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetCreatedCampaigns(adminEshopsDetailGetCreatedCampaignsRequest?: AdminEshopsDetailGetCreatedCampaignsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetCreatedCampaigns(adminEshopsDetailGetCreatedCampaignsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets history of all transactions where transaction type is not AdSpend or contribution
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetCreditTopupHistoryRequest} [adminEshopsDetailGetCreditTopupHistoryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetCreditTopupHistory(adminEshopsDetailGetCreditTopupHistoryRequest?: AdminEshopsDetailGetCreditTopupHistoryRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetCreditTopupHistory(adminEshopsDetailGetCreditTopupHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets contribution transactions overview.
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetDailyContributionStatisticsRequest} [adminEshopsDetailGetDailyContributionStatisticsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetDailyContributionStatistics(adminEshopsDetailGetDailyContributionStatisticsRequest?: AdminEshopsDetailGetDailyContributionStatisticsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetDailyContributionStatistics(adminEshopsDetailGetDailyContributionStatisticsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetContributionPercentageRequest} [adminEshopsDetailGetContributionPercentageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetDefaultContributionPercentage(adminEshopsDetailGetContributionPercentageRequest?: AdminEshopsDetailGetContributionPercentageRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetDefaultContributionPercentage(adminEshopsDetailGetContributionPercentageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets basic eshop info for the top part of admin eshop detail page.
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetEshopDetailRequest} [adminEshopsDetailGetEshopDetailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetEshopDetail(adminEshopsDetailGetEshopDetailRequest?: AdminEshopsDetailGetEshopDetailRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetEshopDetail(adminEshopsDetailGetEshopDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Get Feed processing logs.
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetFeedLogsRequest} [adminEshopsDetailGetFeedLogsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetFeedLogs(adminEshopsDetailGetFeedLogsRequest?: AdminEshopsDetailGetFeedLogsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetFeedLogs(adminEshopsDetailGetFeedLogsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Get Feed url.
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetFeedUrlRequest} [adminEshopsDetailGetFeedUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetFeedUrl(adminEshopsDetailGetFeedUrlRequest?: AdminEshopsDetailGetFeedUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetFeedUrl(adminEshopsDetailGetFeedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets data for graphs on the EshopDetail page in admin
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetGraphDataRequest} [adminEshopsDetailGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetGraphData(adminEshopsDetailGetGraphDataRequest?: AdminEshopsDetailGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetGraphData(adminEshopsDetailGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Get current number of products in database.
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetProductCountRequest} [adminEshopsDetailGetProductCountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetProductCount(adminEshopsDetailGetProductCountRequest?: AdminEshopsDetailGetProductCountRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetProductCount(adminEshopsDetailGetProductCountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the state of products advertisement for given platform
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsDetailGetProductsStateRequest} [adminEshopsDetailGetProductsStateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailGetProductsState(adminEshopsDetailGetProductsStateRequest?: AdminEshopsDetailGetProductsStateRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailGetProductsState(adminEshopsDetailGetProductsStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Process feed for current eshop.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailProcessEshopFeedRequest} [adminEshopsDetailProcessEshopFeedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailProcessEshopFeed(adminEshopsDetailProcessEshopFeedRequest?: AdminEshopsDetailProcessEshopFeedRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailProcessEshopFeed(adminEshopsDetailProcessEshopFeedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Sets Eshop.DefaultContributionPercentage which is not set by default. When set, the contribution is not decided by Eshop\'s generated revenue, but the given value is used
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailSetContributionPercentageRequest} [adminEshopsDetailSetContributionPercentageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailSetDefaultContributionPercentage(adminEshopsDetailSetContributionPercentageRequest?: AdminEshopsDetailSetContributionPercentageRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailSetDefaultContributionPercentage(adminEshopsDetailSetContributionPercentageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Change Feed url.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailSetFeedUrlRequest} [adminEshopsDetailSetFeedUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailSetFeedUrl(adminEshopsDetailSetFeedUrlRequest?: AdminEshopsDetailSetFeedUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailSetFeedUrl(adminEshopsDetailSetFeedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Sets the state of products advertisement for given platform
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailSetProductsStateRequest} [adminEshopsDetailSetProductsStateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailSetProductsState(adminEshopsDetailSetProductsStateRequest?: AdminEshopsDetailSetProductsStateRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailSetProductsState(adminEshopsDetailSetProductsStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Update products info in admin table for current eshop.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailUpdateEshopProductsAdminInfoRequest} [adminEshopsDetailUpdateEshopProductsAdminInfoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailUpdateEshopProductsAdminInfo(adminEshopsDetailUpdateEshopProductsAdminInfoRequest?: AdminEshopsDetailUpdateEshopProductsAdminInfoRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailUpdateEshopProductsAdminInfo(adminEshopsDetailUpdateEshopProductsAdminInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Update products status for current eshop.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailUpdateEshopProductsStatusRequest} [adminEshopsDetailUpdateEshopProductsStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailUpdateEshopProductsStatus(adminEshopsDetailUpdateEshopProductsStatusRequest?: AdminEshopsDetailUpdateEshopProductsStatusRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailUpdateEshopProductsStatus(adminEshopsDetailUpdateEshopProductsStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Upload products for current eshop.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetEshopDetail
     * @param {AdminEshopsDetailUploadEshopProductsRequest} [adminEshopsDetailUploadEshopProductsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopDetailUploadEshopProducts(adminEshopsDetailUploadEshopProductsRequest?: AdminEshopsDetailUploadEshopProductsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopDetailUploadEshopProducts(adminEshopsDetailUploadEshopProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the data for the campaign statistics sub-table visible after expanding the eshop view in the main eshop table in admin.It is also used on eshop detail.
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsGetEshopCampaignsStatisticsRequest} [adminEshopsGetEshopCampaignsStatisticsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopsGetEshopCampaignsStatistics(adminEshopsGetEshopCampaignsStatisticsRequest?: AdminEshopsGetEshopCampaignsStatisticsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopsGetEshopCampaignsStatistics(adminEshopsGetEshopCampaignsStatisticsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the eshops
     * @summary Policies: RequireInternalRole
     * @param {AdminEshopsGetEshopsRequest} [adminEshopsGetEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopsGetEshops(adminEshopsGetEshopsRequest?: AdminEshopsGetEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopsGetEshops(adminEshopsGetEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Updates the database view from which data for admin eshops table are queried.
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminEshopsRefreshEshopsView(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminEshopsRefreshEshopsView(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint returns a link for downloading a gclid report file
     * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminExportGetGclidsCsvLink(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminExportGetGclidsCsvLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint returns a link for downloading csv files with invoices 
     * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminExportGetInvoiceCsvLinks(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminExportGetInvoiceCsvLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint returns a link for downloading xml files with invoices 
     * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminExportGetInvoiceXmlLinks(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminExportGetInvoiceXmlLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint returns a link for downloading a zip with invoices
     * @summary Policies: RequireInternalRole; AdminPermissions: GetExport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminExportGetInvoiceZipLink(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminExportGetInvoiceZipLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the list of unsuccessful feeds.
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminFeedsGetFeedErrors(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminFeedsGetFeedErrors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the specified feed cron info.
     * @summary Policies: RequireInternalRole
     * @param {AdminDTOGetFeedProcessingInfoRequest} [adminDTOGetFeedProcessingInfoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminFeedsGetFeedProcessingInfo(adminDTOGetFeedProcessingInfoRequest?: AdminDTOGetFeedProcessingInfoRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminFeedsGetFeedProcessingInfo(adminDTOGetFeedProcessingInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Endpoint for getting list of all googleCustomerSubAccounts in TGNCA s.r.o account
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminGoogleCampaignsGetAllCustomerSubAccounts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminGoogleCampaignsGetAllCustomerSubAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view incomplete google campaigns that they need to create on google
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminGoogleCampaignsGetCampaigns(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminGoogleCampaignsGetCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Endpoint serving as a trigger to match the external id / customerSubAccount id with an empty campaign in database - both single and multiple select
     * @summary Policies: RequireInternalRole; AdminPermissions: SetGoogleCampaigns
     * @param {AdminGoogleCampaignsGoogleCampaignCreatedRequest} [adminGoogleCampaignsGoogleCampaignCreatedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminGoogleCampaignsGoogleCampaignCreated(adminGoogleCampaignsGoogleCampaignCreatedRequest?: AdminGoogleCampaignsGoogleCampaignCreatedRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminGoogleCampaignsGoogleCampaignCreated(adminGoogleCampaignsGoogleCampaignCreatedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets list of activated eshops
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportActivatedEshops
     * @param {AdminKpiReportGetActivatedEshopsRequest} [adminKpiReportGetActivatedEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetActivatedEshops(adminKpiReportGetActivatedEshopsRequest?: AdminKpiReportGetActivatedEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetActivatedEshops(adminKpiReportGetActivatedEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for Advertising expenses graph.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetAdExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetAdExpensesGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for Contributions graph.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetContributionsGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetContributionsGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for Contributions/Advertising expenses graph.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetContributionsToAdExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetContributionsToAdExpensesGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for Eshops with spending graph.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetEshopsWithAdspendGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetEshopsWithAdspendGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for Turnover (GeneratedRevenue) graph.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetGeneratedRevenueGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetGeneratedRevenueGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets kpi report data for given time frame.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetKpiReportRequest} [adminKpiReportGetKpiReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetKpiReport(adminKpiReportGetKpiReportRequest?: AdminKpiReportGetKpiReportRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetKpiReport(adminKpiReportGetKpiReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for the campaign table in monthly report section of Kpi report page.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetMonthlyCampaignStatsRequest} [adminKpiReportGetMonthlyCampaignStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetMonthlyCampaignStats(adminKpiReportGetMonthlyCampaignStatsRequest?: AdminKpiReportGetMonthlyCampaignStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetMonthlyCampaignStats(adminKpiReportGetMonthlyCampaignStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for monthly report section of Kpi report page.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetMonthlyReportRequest} [adminKpiReportGetMonthlyReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetMonthlyReport(adminKpiReportGetMonthlyReportRequest?: AdminKpiReportGetMonthlyReportRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetMonthlyReport(adminKpiReportGetMonthlyReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for Transactions (NumberOfOrders) graph.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetNumberOfOrdersGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetNumberOfOrdersGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets list of registered eshops
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReportRegisteredEshops
     * @param {AdminKpiReportGetRegisteredEshopsRequest} [adminKpiReportGetRegisteredEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetRegisteredEshops(adminKpiReportGetRegisteredEshopsRequest?: AdminKpiReportGetRegisteredEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetRegisteredEshops(adminKpiReportGetRegisteredEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for Accounting (TotalExpenses) graph.
     * @summary Policies: RequireInternalRole; AdminPermissions: GetKpiReport
     * @param {AdminKpiReportGetGraphDataRequest} [adminKpiReportGetGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminKpiReportGetTotalExpensesGraphData(adminKpiReportGetGraphDataRequest?: AdminKpiReportGetGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminKpiReportGetTotalExpensesGraphData(adminKpiReportGetGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the user logs
     * @summary Policies: RequireInternalRole
     * @param {AdminLogsGetLogsRequest} [adminLogsGetLogsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminLogsGetLogs(adminLogsGetLogsRequest?: AdminLogsGetLogsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminLogsGetLogs(adminLogsGetLogsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the mail logs
     * @summary Policies: RequireInternalRole
     * @param {AdminLogsGetMailLogsRequest} [adminLogsGetMailLogsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminLogsGetMailLogs(adminLogsGetMailLogsRequest?: AdminLogsGetMailLogsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminLogsGetMailLogs(adminLogsGetMailLogsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the data from DB view made for Gmc products data and apply filtering, sorting and paging.
     * @summary Policies: RequireInternalRole
     * @param {AdminDTOGetProductsDataRequest} [adminDTOGetProductsDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminProductsGetProductsData(adminDTOGetProductsDataRequest?: AdminDTOGetProductsDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminProductsGetProductsData(adminDTOGetProductsDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the DOTNET issues for given eshop.
     * @summary Policies: RequireInternalRole
     * @param {AdminDTOGetProductsDotnetIssuesRequest} [adminDTOGetProductsDotnetIssuesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminProductsGetProductsDotnetIssues(adminDTOGetProductsDotnetIssuesRequest?: AdminDTOGetProductsDotnetIssuesRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminProductsGetProductsDotnetIssues(adminDTOGetProductsDotnetIssuesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the GMC issues for given eshop.
     * @summary Policies: RequireInternalRole
     * @param {AdminDTOGetProductsGmcIssuesRequest} [adminDTOGetProductsGmcIssuesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminProductsGetProductsGmcIssues(adminDTOGetProductsGmcIssuesRequest?: AdminDTOGetProductsGmcIssuesRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminProductsGetProductsGmcIssues(adminDTOGetProductsGmcIssuesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the count and capacity notification of GMC subaccounts.
     * @summary Policies: RequireInternalRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminProductsGetSubaccountsInfo(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminProductsGetSubaccountsInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Updates the count of GMC subaccounts.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetProducts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminProductsUpdateGmcSubaccounts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminProductsUpdateGmcSubaccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint gets number of eshops with spend for czech and foreign eshops
     * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuActiveEshopsCount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminTopMenuGetActiveEshopsCount(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminTopMenuGetActiveEshopsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint gets overall credit for czech and foreign eshops
     * @summary Policies: RequireInternalRole; AdminPermissions: GetTopMenuEshopsCredit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminTopMenuGetEshopsCredit(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminTopMenuGetEshopsCredit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution - same as in the client app
     * @summary Policies: RequireInternalRole
     * @param {AdminUserDetailGetUserAffilInfoRequest} [adminUserDetailGetUserAffilInfoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminUserDetailGetUserAffilInfo(adminUserDetailGetUserAffilInfoRequest?: AdminUserDetailGetUserAffilInfoRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminUserDetailGetUserAffilInfo(adminUserDetailGetUserAffilInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets basic user info.
     * @summary Policies: RequireInternalRole
     * @param {AdminUserDetailGetUserDetailRequest} [adminUserDetailGetUserDetailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminUserDetailGetUserDetail(adminUserDetailGetUserDetailRequest?: AdminUserDetailGetUserDetailRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminUserDetailGetUserDetail(adminUserDetailGetUserDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to logout some other user by providing his ID
     * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
     * @param {AdminUserDetailLogoutUserRequest} [adminUserDetailLogoutUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminUserDetailLogoutUser(adminUserDetailLogoutUserRequest?: AdminUserDetailLogoutUserRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminUserDetailLogoutUser(adminUserDetailLogoutUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Sets user as affiliate.
     * @summary Policies: RequireInternalRole; AdminPermissions: SetUserDetail
     * @param {AdminUserDetailSetUserAffilRequest} [adminUserDetailSetUserAffilRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminUserDetailSetUserAffil(adminUserDetailSetUserAffilRequest?: AdminUserDetailSetUserAffilRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminUserDetailSetUserAffil(adminUserDetailSetUserAffilRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for tanganica admins or developers to be able to view all the users
     * @summary Policies: RequireInternalRole
     * @param {AdminUsersGetUsersRequest} [adminUsersGetUsersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public adminUsersGetUsers(adminUsersGetUsersRequest?: AdminUsersGetUsersRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).adminUsersGetUsers(adminUsersGetUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> The user requests an affil program - email is sent to admins with users name and id
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAffiliateReportAskForAffil(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAffiliateReportAskForAffil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the amount of affil contribution available for the eshop to withdraw
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAffiliateReportGetAffilContributionAvailable(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAffiliateReportGetAffilContributionAvailable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAffiliateReportGetAffilGraphData(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAffiliateReportGetAffilGraphData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets all the necessary info about the affiliate program
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAffiliateReportGetAffilInfo(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAffiliateReportGetAffilInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the total amount of affil contribution the eshop has generated over time
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAffiliateReportGetAffiliateContributionTotal(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAffiliateReportGetAffiliateContributionTotal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateTextAssetsRequest} [clientDTOGenerateTextAssetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAssetGenerateTextAssets(clientDTOGenerateTextAssetsRequest?: ClientDTOGenerateTextAssetsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAssetGenerateTextAssets(clientDTOGenerateTextAssetsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for user to logout when his refresh token is expired
     * @param {ClientAuthLogoutRequest} [clientAuthLogoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthExpiredTokenLogout(clientAuthLogoutRequest?: ClientAuthLogoutRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthExpiredTokenLogout(clientAuthLogoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint consumes email and if given email has registered user in our database then email will be sent with link for password reset. Always returns 200 OK so no one can use it to find out someones registered email<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>email</b>: email of user which wants to reset password</li><li><b>redirectUrl</b>: url to redirect user to with reset token - is sent to user by email - form on this url will call the ResetPassword endpoint. The provided url will be appended with email and token as follows: \"{redirectUrl}?email={email}&token={token}\";</li></ul>
     * @param {ClientAuthForgottenPasswordRequest} [clientAuthForgottenPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthForgottenPassword(clientAuthForgottenPasswordRequest?: ClientAuthForgottenPasswordRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthForgottenPassword(clientAuthForgottenPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint creates new eshop specific access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
     * @summary Policies: RequireRefreshToken
     * @param {ClientAuthEshopAccessTokenRequest} [clientAuthEshopAccessTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthGetEshopAccessToken(clientAuthEshopAccessTokenRequest?: ClientAuthEshopAccessTokenRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthGetEshopAccessToken(clientAuthEshopAccessTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint gets the facebook oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
     * @param {ClientAuthGetFacebookOAuthUrlRequest} [clientAuthGetFacebookOAuthUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthGetFacebookOAuthUrl(clientAuthGetFacebookOAuthUrlRequest?: ClientAuthGetFacebookOAuthUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthGetFacebookOAuthUrl(clientAuthGetFacebookOAuthUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint gets the google oauth sign in url <h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>RedirectUri</b>: Url on which user should be redirected after external login</li></ul>
     * @param {ClientAuthGetGoogleOAuthUrlRequest} [clientAuthGetGoogleOAuthUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthGetGoogleOAuthUrl(clientAuthGetGoogleOAuthUrlRequest?: ClientAuthGetGoogleOAuthUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthGetGoogleOAuthUrl(clientAuthGetGoogleOAuthUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint creates new access token if valid refresh token is provided in authorization header as Bearer. Any type of access token instead of refresh token in auth header gets 403 Forbidden response.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: RefreshToken</li><li><b>UserId</b>: valid user guid</li></ul>
     * @summary Policies: RequireRefreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthGetUserAccessToken(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthGetUserAccessToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for standard user password login<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL</li><li><b>Password</b>: correct password for user trying to login</li><li><b>Email</b>: correct email for user trying to login</li></ul>
     * @param {ClientAuthLoginRequest} [clientAuthLoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthLogin(clientAuthLoginRequest?: ClientAuthLoginRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthLogin(clientAuthLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint is for user to logout<h3>Requirements</h3><ul><li><b>Authorization roles</b>: UserAccessToken</li></ul>
     * @summary Policies: RequireUserAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthLogout(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint creates new user with client account owner privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: Internal AND Admin</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Email</b>: have to be unique</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
     * @param {ClientAuthRegisterRequest} [clientAuthRegisterRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthRegister(clientAuthRegisterRequest?: ClientAuthRegisterRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthRegister(clientAuthRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint creates new user with client account owner privileges and given eshop role privileges.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: InviteToken</li><li><b>Password</b>: have to be at least 8 characters and contain at least one number, upper case and nonalphanumeric character</li><li><b>Locale</b>: have to be 2 characters exactly (2 character standard country code)</ul>
     * @summary Policies: RequireInviteToken
     * @param {ClientAuthRegisterInvitedRequest} [clientAuthRegisterInvitedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientAuthRegisterInvited(clientAuthRegisterInvitedRequest?: ClientAuthRegisterInvitedRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientAuthRegisterInvited(clientAuthRegisterInvitedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Is called after user clicks on the confirmation link in email. User is then redirected to login page (? TODO)
     * @summary Policies: RequireEmailChangeToken
     * @param {ClientCallbacksConfirmEmailChangeRequest} [clientCallbacksConfirmEmailChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCallbacksConfirmEmailChange(clientCallbacksConfirmEmailChangeRequest?: ClientCallbacksConfirmEmailChangeRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCallbacksConfirmEmailChange(clientCallbacksConfirmEmailChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Called by frontend, which passes through the parameters obtained by external auth provider <h3>Requirements</h3><ul><li><b>Authorization roles</b>: token obtained from state query parameter of redirectUri </li></ul>
     * @summary Policies: RequireOAuthStateToken
     * @param {ClientCallbacksExternalAuthCallbackRequest} [clientCallbacksExternalAuthCallbackRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCallbacksExternalAuthCallback(clientCallbacksExternalAuthCallbackRequest?: ClientCallbacksExternalAuthCallbackRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCallbacksExternalAuthCallback(clientCallbacksExternalAuthCallbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint consumes parameters from link sent by forgotten password endpoint + new password to reset user password.<h3>Requirements</h3><ul><li><b>Authorization roles</b>: NULL </li><li><b>Token</b>: valid password reset token</li></ul>
     * @summary Policies: RequireResetPasswordToken
     * @param {ClientCallbacksResetPasswordRequest} [clientCallbacksResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCallbacksResetPassword(clientCallbacksResetPasswordRequest?: ClientCallbacksResetPasswordRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCallbacksResetPassword(clientCallbacksResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCallbacksStripePaymentWebhook(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCallbacksStripePaymentWebhook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchanges the auth code for access token and indicates whether it has correct business permissions (true/false)
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest} [clientCampaignsCheckAuthCodeForBusinessPermissionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsCheckAuthCodeForBusinessPermissions(clientCampaignsCheckAuthCodeForBusinessPermissionsRequest?: ClientCampaignsCheckAuthCodeForBusinessPermissionsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsCheckAuthCodeForBusinessPermissions(clientCampaignsCheckAuthCodeForBusinessPermissionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if FB page is linked to Eshop in DB
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsCheckFBPageLink(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsCheckFBPageLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to store assets for {CampaignType} campaigns
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {ClientCampaignsDeleteAssetsRequest} [clientCampaignsDeleteAssetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsDeleteAssets(campaignType: EnumsMarketingFormat, clientCampaignsDeleteAssetsRequest?: ClientCampaignsDeleteAssetsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsDeleteAssets(campaignType, clientCampaignsDeleteAssetsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to store assets for {CampaignType} campaigns
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetAssets(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetAssets(campaignType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint gets the information if any campaign with given Format exists for current eshop.
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetCampaignStatus(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetCampaignStatus(campaignType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint gets the value of daily budget as well as minimal budget information for provided marketing format campaign.
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetDailyBudget(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetDailyBudget(campaignType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user sets whether the products should be advertised or not
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetDashboardStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetDashboardStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a link to the FB business sharing interface
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetFBBusinessOauthUrl(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetFBBusinessOauthUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the name and link of the linked FB page
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetFBLinkedPageInfo(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetFBLinkedPageInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a link to the FB page sharing interface
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetFBPagesOauthUrl(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetFBPagesOauthUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get status for provided {CampaignType} campaign. This does not reflect reality in marketing platform (Google/Facebook) but desired status of the user.
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsGetStatus(campaignType: EnumsMarketingFormat, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsGetStatus(campaignType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint sets the value of daily budget for provided marketing format campaign. First it attempts to set the value using the corresponding platform API (e.g., Google Ads, Facebook Ads). If the API call fails, it throws an error and skip the change in database. In case of success, it updates the value inside the database and returns success.
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {ClientCampaignsSetDailyBudgetRequest} [clientCampaignsSetDailyBudgetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsSetDailyBudget(campaignType: EnumsMarketingFormat, clientCampaignsSetDailyBudgetRequest?: ClientCampaignsSetDailyBudgetRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsSetDailyBudget(campaignType, clientCampaignsSetDailyBudgetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint reverses the status of a marketing campaign in both the database and corresponding marketing platforms (e.g., Google Ads, Facebook Ads) based on the provided MarketingFormat. If the database operation fails, it throws an error and skips the API calls. If a platform API call switch fails, it still returns success and attempts the switch later based on status in the database.
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {ClientCampaignsSetStatusRequest} [clientCampaignsSetStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsSetStatus(campaignType: EnumsMarketingFormat, clientCampaignsSetStatusRequest?: ClientCampaignsSetStatusRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsSetStatus(campaignType, clientCampaignsSetStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds TGNCA as agency to the clients shared page and notifies admins via email
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientCampaignsShareFBPageWithTanganicaRequest} [clientCampaignsShareFBPageWithTanganicaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsShareFBPageWithTanganica(clientCampaignsShareFBPageWithTanganicaRequest?: ClientCampaignsShareFBPageWithTanganicaRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsShareFBPageWithTanganica(clientCampaignsShareFBPageWithTanganicaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint updates the Bing DSA ad text with updated assets.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsUpdateBingDSACampaign(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsUpdateBingDSACampaign(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint updates the DPA campaign Ad Creatives with updated assets.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsUpdateDPACampaign(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsUpdateDPACampaign(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to store assets for {CampaignType} campaigns
     * @summary Policies: RequireEshopAccessToken
     * @param {EnumsMarketingFormat} campaignType 
     * @param {ClientCampaignsUploadAssetsRequest} [clientCampaignsUploadAssetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientCampaignsUploadAssets(campaignType: EnumsMarketingFormat, clientCampaignsUploadAssetsRequest?: ClientCampaignsUploadAssetsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientCampaignsUploadAssets(campaignType, clientCampaignsUploadAssetsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateBlogContentRequest} [clientDTOGenerateBlogContentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateBlogContent(clientDTOGenerateBlogContentRequest?: ClientDTOGenerateBlogContentRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateBlogContent(clientDTOGenerateBlogContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateBlogContentIdeaRequest} [clientDTOGenerateBlogContentIdeaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateBlogContentIdea(clientDTOGenerateBlogContentIdeaRequest?: ClientDTOGenerateBlogContentIdeaRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateBlogContentIdea(clientDTOGenerateBlogContentIdeaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateBlogContentCtrategyRequest} [clientDTOGenerateBlogContentCtrategyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateBlogContentStrategy(clientDTOGenerateBlogContentCtrategyRequest?: ClientDTOGenerateBlogContentCtrategyRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateBlogContentStrategy(clientDTOGenerateBlogContentCtrategyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateEmailContentStrategyRequest} [clientDTOGenerateEmailContentStrategyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateEmailContentStrategy(clientDTOGenerateEmailContentStrategyRequest?: ClientDTOGenerateEmailContentStrategyRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateEmailContentStrategy(clientDTOGenerateEmailContentStrategyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateNewsletterRequest} [clientDTOGenerateNewsletterRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateNewsletter(clientDTOGenerateNewsletterRequest?: ClientDTOGenerateNewsletterRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateNewsletter(clientDTOGenerateNewsletterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateNewsletterIdeaRequest} [clientDTOGenerateNewsletterIdeaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateNewsletterIdea(clientDTOGenerateNewsletterIdeaRequest?: ClientDTOGenerateNewsletterIdeaRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateNewsletterIdea(clientDTOGenerateNewsletterIdeaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateSocialMediaContentRequest} [clientDTOGenerateSocialMediaContentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateSocialMediaContent(clientDTOGenerateSocialMediaContentRequest?: ClientDTOGenerateSocialMediaContentRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateSocialMediaContent(clientDTOGenerateSocialMediaContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateSocialMediaContentHeadlineRequest} [clientDTOGenerateSocialMediaContentHeadlineRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateSocialMediaContentHeadline(clientDTOGenerateSocialMediaContentHeadlineRequest?: ClientDTOGenerateSocialMediaContentHeadlineRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateSocialMediaContentHeadline(clientDTOGenerateSocialMediaContentHeadlineRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateSocialMediaContentIdeaRequest} [clientDTOGenerateSocialMediaContentIdeaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateSocialMediaContentIdea(clientDTOGenerateSocialMediaContentIdeaRequest?: ClientDTOGenerateSocialMediaContentIdeaRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateSocialMediaContentIdea(clientDTOGenerateSocialMediaContentIdeaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGenerateSocialMediaContentStrategyRequest} [clientDTOGenerateSocialMediaContentStrategyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentGenerateSocialMediaContentStrategy(clientDTOGenerateSocialMediaContentStrategyRequest?: ClientDTOGenerateSocialMediaContentStrategyRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentGenerateSocialMediaContentStrategy(clientDTOGenerateSocialMediaContentStrategyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOOptimizeBlogContentRequest} [clientDTOOptimizeBlogContentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientContentOptimizeBlogContent(clientDTOOptimizeBlogContentRequest?: ClientDTOOptimizeBlogContentRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientContentOptimizeBlogContent(clientDTOOptimizeBlogContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends the feed processing request
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDashboardFixFeedUrlRequest} [clientDashboardFixFeedUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardFixFeedUrl(clientDashboardFixFeedUrlRequest?: ClientDashboardFixFeedUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardFixFeedUrl(clientDashboardFixFeedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user sets whether the products should be advertised or not
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetActiveCampaignFormats(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetActiveCampaignFormats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets last feed state
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetFeedState(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetFeedState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the last feed processing error
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetLastFeedError(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetLastFeedError(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets number of learning phase orders. Learning phase orders are orders from all campaigns made in last 30 days.They are located in <b>Overview of your campaigns</b> section of <b>Dashboard</b> page
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetLearningPhaseOrders(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetLearningPhaseOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if all steps of order measuring are set up
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetPixelMeasuringCondition(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetPixelMeasuringCondition(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the product condition from the DB for given eshop
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetProductsConditionCount(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetProductsConditionCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the product issues summary given eshop
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDashboardGetProductsIssuesSummaryRequest} [clientDashboardGetProductsIssuesSummaryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetProductsIssuesSummary(clientDashboardGetProductsIssuesSummaryRequest?: ClientDashboardGetProductsIssuesSummaryRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetProductsIssuesSummary(clientDashboardGetProductsIssuesSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets remaining trial period for eshop with eshop ID provided in JWT
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetRemainingTrialPeriod(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetRemainingTrialPeriod(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user sets whether the products should be advertised or not
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetSetupStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetSetupStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets campaign statistic data for last 30 days that are located in section basic statistics in dashboard.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetStatistics(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user sets whether the products should be advertised or not
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get the percentage of successfully measured customers of the eshop
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetSuccessfullyMeasuredCustomersPercentage(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetSuccessfullyMeasuredCustomersPercentage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current tanganica contribution percentage for the eshop
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientDashboardGetTgncaContributionPercentage(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientDashboardGetTgncaContributionPercentage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Returns only one eshop with same data structure as in GetEshops above. Designed to be used for admin LoginAs functionality only.
     * @summary Policies: RequireInternalEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopListGetEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopListGetEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Returns a overview of eshops the user has. Including his roles in the eshops. Designed to be used for active eshop selection.
     * @summary Policies: RequireUserAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopListGetEshops(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopListGetEshops(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsCreateEshopDescription(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsCreateEshopDescription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetCosMax(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetCosMax(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetCreditNotificationLimit(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetCreditNotificationLimit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetEshopDescription(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetEshopDescription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetFeedState(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetFeedState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetNotificationEmail(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetNotificationEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets pixel status of current eshop.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetPixelStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetPixelStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetProductFeedUrl(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetProductFeedUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsGetUsersWithAccess(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsGetUsersWithAccess(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> If invited user is alread registered he is linked to given eshop with given role - (only notification email is sent (?) ).If the invited email is not in the database, mail is sent to the given email address with link containing special token. This link redirects user to FE for registration, where email input is already set from the token<h3>Requirements</h3><ul><li><b>redirectUrl</b>: url to redirect user to with InviteToken - the url is sent to user by email - form on this url will call the RegisterInvited endpoint. The provided url will be appended with JWT token as follows: \"{redirectUrl}?InviteToken={token}\"</li></ul>
     * @summary Policies: , RequireEshopAccessToken
     * @param {ClientEshopSettingsInviteUserToEshopRequest} [clientEshopSettingsInviteUserToEshopRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsInviteUser(clientEshopSettingsInviteUserToEshopRequest?: ClientEshopSettingsInviteUserToEshopRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsInviteUser(clientEshopSettingsInviteUserToEshopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientEshopSettingsRemoveUserFromEshopRequest} [clientEshopSettingsRemoveUserFromEshopRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsRemoveUserFromEshop(clientEshopSettingsRemoveUserFromEshopRequest?: ClientEshopSettingsRemoveUserFromEshopRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsRemoveUserFromEshop(clientEshopSettingsRemoveUserFromEshopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientEshopSettingsSetCosMaxRequest} [clientEshopSettingsSetCosMaxRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsSetCosMax(clientEshopSettingsSetCosMaxRequest?: ClientEshopSettingsSetCosMaxRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsSetCosMax(clientEshopSettingsSetCosMaxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * In addition to the user receiving a notification, the credit under the limit is also set in red in the app
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientEshopSettingsSetCreditNotificationLimitRequest} [clientEshopSettingsSetCreditNotificationLimitRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsSetCreditNotificationLimit(clientEshopSettingsSetCreditNotificationLimitRequest?: ClientEshopSettingsSetCreditNotificationLimitRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsSetCreditNotificationLimit(clientEshopSettingsSetCreditNotificationLimitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientEshopSettingsSetNotificationEmailRequest} [clientEshopSettingsSetNotificationEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsSetNotificationEmail(clientEshopSettingsSetNotificationEmailRequest?: ClientEshopSettingsSetNotificationEmailRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsSetNotificationEmail(clientEshopSettingsSetNotificationEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientEshopSettingsSetProductFeedUrlRequest} [clientEshopSettingsSetProductFeedUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientEshopSettingsSetProductFeedUrl(clientEshopSettingsSetProductFeedUrlRequest?: ClientEshopSettingsSetProductFeedUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientEshopSettingsSetProductFeedUrl(clientEshopSettingsSetProductFeedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Feed processing logs
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientLogsGetUserFeedLogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientLogsGetUserFeedLogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets billing info for form prefill.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientPaymentHistoryGetBillingSettings(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientPaymentHistoryGetBillingSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the presigned url to the invoice pdf stored in Spaces.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientPaymentHistoryGetInvoicePdfUrlRequest} [clientPaymentHistoryGetInvoicePdfUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientPaymentHistoryGetInvoicePdfUrl(clientPaymentHistoryGetInvoicePdfUrlRequest?: ClientPaymentHistoryGetInvoicePdfUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientPaymentHistoryGetInvoicePdfUrl(clientPaymentHistoryGetInvoicePdfUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of all invoice documents of eshop in database.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientPaymentHistoryGetPaymentHistory(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientPaymentHistoryGetPaymentHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Used to save/update billing data.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientPaymentHistorySetBillingSettingsRequest} [clientPaymentHistorySetBillingSettingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientPaymentHistorySetBillingSettings(clientPaymentHistorySetBillingSettingsRequest?: ClientPaymentHistorySetBillingSettingsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientPaymentHistorySetBillingSettings(clientPaymentHistorySetBillingSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Loads all categories for eshop products
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsGetCategories(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsGetCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all Categories and their information
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsGetCategoriesData(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsGetCategoriesData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get status of Feed and Products
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsGetFeedStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsGetFeedStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Loads all issues for given product in current Eshop
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetProductIssuesRequest} [clientDTOGetProductIssuesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsGetProductIssues(clientDTOGetProductIssuesRequest?: ClientDTOGetProductIssuesRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsGetProductIssues(clientDTOGetProductIssuesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Loads all products for Eshop according to given sort and filters
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetProductsRequest} [clientDTOGetProductsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsGetProducts(clientDTOGetProductsRequest?: ClientDTOGetProductsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsGetProducts(clientDTOGetProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the summary of products and their status
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsGetProductsSummary(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsGetProductsSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets the advertisement for given Category and changes Category attributes
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOSetCategoryAdvertisementRequest} [clientDTOSetCategoryAdvertisementRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsSetCategoryAdvertisement(clientDTOSetCategoryAdvertisementRequest?: ClientDTOSetCategoryAdvertisementRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsSetCategoryAdvertisement(clientDTOSetCategoryAdvertisementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user sets whether the category should be advertised or not
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOSetMultipleProductsAdvertisementStatusRequest} [clientDTOSetMultipleProductsAdvertisementStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsSetMultipleProductsAdvertisementStatus(clientDTOSetMultipleProductsAdvertisementStatusRequest?: ClientDTOSetMultipleProductsAdvertisementStatusRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsSetMultipleProductsAdvertisementStatus(clientDTOSetMultipleProductsAdvertisementStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user sets whether the product should be advertised or not
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOSetProductAdvertisementStatusRequest} [clientDTOSetProductAdvertisementStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientProductsSetProductAdvertisementStatus(clientDTOSetProductAdvertisementStatusRequest?: ClientDTOSetProductAdvertisementStatusRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientProductsSetProductAdvertisementStatus(clientDTOSetProductAdvertisementStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates a bank transfer payment process within the application. When called, this endpoint does not perform a real financial transaction but generates a proforma invoice based on the user\'s selected credit amount. The response includes a presigned URL to access the generated invoice and a QR code string for payment facilitation.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOBankTransferPaymentRequest} [clientDTOBankTransferPaymentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditBankTransferPayment(clientDTOBankTransferPaymentRequest?: ClientDTOBankTransferPaymentRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditBankTransferPayment(clientDTOBankTransferPaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new checkout session for processing payments. Session is returned as clientSecret that have to be used to get the session through Stripe API.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOCreateCheckoutRequest} [clientDTOCreateCheckoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditCreateCheckout(clientDTOCreateCheckoutRequest?: ClientDTOCreateCheckoutRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditCreateCheckout(clientDTOCreateCheckoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new checkout setup session for saving cards. Session is returned as clientSecret that have to be used to get the session through Stripe API.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOCreateSetupRequest} [clientDTOCreateSetupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditCreateSetup(clientDTOCreateSetupRequest?: ClientDTOCreateSetupRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditCreateSetup(clientDTOCreateSetupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditGetAutomaticCreditRechargeOptions(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditGetAutomaticCreditRechargeOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditGetAutomaticCreditRechargeStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditGetAutomaticCreditRechargeStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditGetEshopTaxRate(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditGetEshopTaxRate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns default amount for payments with currency of provided eshop in Token.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditGetPaymentDefaultAmount(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditGetPaymentDefaultAmount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets checkout sessions response status and email of customer specified by provided session ID.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetSessionStatusRequest} [clientDTOGetSessionStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditGetSessionStatus(clientDTOGetSessionStatusRequest?: ClientDTOGetSessionStatusRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditGetSessionStatus(clientDTOGetSessionStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns true if user present in Token has saved card.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditHasSavedCard(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditHasSavedCard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOSetAutomaticCreditRechargeOptionsRequest} [clientDTOSetAutomaticCreditRechargeOptionsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditSetAutomaticCreditRechargeOptions(clientDTOSetAutomaticCreditRechargeOptionsRequest?: ClientDTOSetAutomaticCreditRechargeOptionsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditSetAutomaticCreditRechargeOptions(clientDTOSetAutomaticCreditRechargeOptionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOSetAutomaticCreditRechargeStatusRequest} [clientDTOSetAutomaticCreditRechargeStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientRechargeCreditSetAutomaticCreditRechargeStatus(clientDTOSetAutomaticCreditRechargeStatusRequest?: ClientDTOSetAutomaticCreditRechargeStatusRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientRechargeCreditSetAutomaticCreditRechargeStatus(clientDTOSetAutomaticCreditRechargeStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientReportExportCampaignStatsRequest} [clientReportExportCampaignStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientReportExportCampaignStats(clientReportExportCampaignStatsRequest?: ClientReportExportCampaignStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientReportExportCampaignStats(clientReportExportCampaignStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientReportExportOrderStatsRequest} [clientReportExportOrderStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientReportExportOrderStats(clientReportExportOrderStatsRequest?: ClientReportExportOrderStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientReportExportOrderStats(clientReportExportOrderStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientReportExportProductCategoryStatsRequest} [clientReportExportProductCategoryStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientReportExportProductCategoryStats(clientReportExportProductCategoryStatsRequest?: ClientReportExportProductCategoryStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientReportExportProductCategoryStats(clientReportExportProductCategoryStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientReportExportProductStatsRequest} [clientReportExportProductStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientReportExportProductStats(clientReportExportProductStatsRequest?: ClientReportExportProductStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientReportExportProductStats(clientReportExportProductStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientReportExportTrafficStatsRequest} [clientReportExportTrafficStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientReportExportTrafficStats(clientReportExportTrafficStatsRequest?: ClientReportExportTrafficStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientReportExportTrafficStats(clientReportExportTrafficStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientReportExportVisitSourceStatsRequest} [clientReportExportVisitSourceStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientReportExportVisitSourceStats(clientReportExportVisitSourceStatsRequest?: ClientReportExportVisitSourceStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientReportExportVisitSourceStats(clientReportExportVisitSourceStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets data for client/report page in app.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetCampaignStatsRequest} [clientDTOGetCampaignStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetCampaignStats(clientDTOGetCampaignStatsRequest?: ClientDTOGetCampaignStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetCampaignStats(clientDTOGetCampaignStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetCampaignStatsGraphDataRequest} [clientDTOGetCampaignStatsGraphDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetCampaignStatsGraphData(clientDTOGetCampaignStatsGraphDataRequest?: ClientDTOGetCampaignStatsGraphDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetCampaignStatsGraphData(clientDTOGetCampaignStatsGraphDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetGmvTrendChartDataRequest} [clientDTOGetGmvTrendChartDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetGmvTrendChartData(clientDTOGetGmvTrendChartDataRequest?: ClientDTOGetGmvTrendChartDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetGmvTrendChartData(clientDTOGetGmvTrendChartDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetOrderStatsRequest} [clientDTOGetOrderStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetOrderStats(clientDTOGetOrderStatsRequest?: ClientDTOGetOrderStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetOrderStats(clientDTOGetOrderStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetProductCategoryStatsRequest} [clientDTOGetProductCategoryStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetProductCategoryStats(clientDTOGetProductCategoryStatsRequest?: ClientDTOGetProductCategoryStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetProductCategoryStats(clientDTOGetProductCategoryStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetProductStatsRequest} [clientDTOGetProductStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetProductStats(clientDTOGetProductStatsRequest?: ClientDTOGetProductStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetProductStats(clientDTOGetProductStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetTrafficStatsRequest} [clientDTOGetTrafficStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetTrafficStats(clientDTOGetTrafficStatsRequest?: ClientDTOGetTrafficStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetTrafficStats(clientDTOGetTrafficStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientDTOGetVisitSourceStatsRequest} [clientDTOGetVisitSourceStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientStatisticsGetVisitSourceStats(clientDTOGetVisitSourceStatsRequest?: ClientDTOGetVisitSourceStatsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientStatisticsGetVisitSourceStats(clientDTOGetVisitSourceStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Endpoint for Contact Us tab of the app
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientSurveySendContactFormRequest} [clientSurveySendContactFormRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientSurveySendContactForm(clientSurveySendContactFormRequest?: ClientSurveySendContactFormRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientSurveySendContactForm(clientSurveySendContactFormRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the current credit for a specific e-shop using the provided e-shop ID from access token.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientTopMenuGetCredit(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientTopMenuGetCredit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
     * @summary Policies: RequireUserAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserAffiliateGetAffilContributionForAllOwnedEshops(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserAffiliateGetAffilContributionForAllOwnedEshops(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets a list of all user\'s eshops and its affil contribution
     * @summary Policies: RequireUserAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserAffiliateGetCompanyInfoForAffilInvoice(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserAffiliateGetCompanyInfoForAffilInvoice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Sets the user\'s email to what is in the input. Sends an email with confirmation link. Confirmation link calls the ConfirmEmailChange enspoint in CallbacksController
     * @summary Policies: RequireUserAccessToken
     * @param {ClientUserSettingsChangeEmailRequest} [clientUserSettingsChangeEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserSettingsChangeEmail(clientUserSettingsChangeEmailRequest?: ClientUserSettingsChangeEmailRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserSettingsChangeEmail(clientUserSettingsChangeEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> This endpoint allows authenticated users to change their password. It requires the user to provide their current (old) password, along with the desired new password and a confirmation of the new password. The endpoint verifies the old password, and if it matches the user\'s current password and the new password and confirmation match, the user\'s password will be updated.
     * @summary Policies: RequireUserAccessToken
     * @param {ClientUserSettingsChangePasswordRequest} [clientUserSettingsChangePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserSettingsChangePassword(clientUserSettingsChangePasswordRequest?: ClientUserSettingsChangePasswordRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserSettingsChangePassword(clientUserSettingsChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets user\'s name.
     * @summary Policies: RequireUserAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserSettingsGetName(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserSettingsGetName(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets user\'s phone number.
     * @summary Policies: RequireUserAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserSettingsGetPhoneNumber(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserSettingsGetPhoneNumber(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> The user\'s location will change. Returns if the localization was successfully changed
     * @summary Policies: RequireUserAccessToken
     * @param {ClientUserSettingsLocaleRequest} [clientUserSettingsLocaleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserSettingsSetLocale(clientUserSettingsLocaleRequest?: ClientUserSettingsLocaleRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserSettingsSetLocale(clientUserSettingsLocaleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> The user\'s name will change. Returns if the localization was successfully changed
     * @summary Policies: RequireUserAccessToken
     * @param {ClientUserSettingsSetNameRequest} [clientUserSettingsSetNameRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserSettingsSetName(clientUserSettingsSetNameRequest?: ClientUserSettingsSetNameRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserSettingsSetName(clientUserSettingsSetNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> The user\'s phone number will change. Returns if the localization was successfully changed
     * @summary Policies: RequireUserAccessToken
     * @param {ClientUserSettingsSetPhoneNumberRequest} [clientUserSettingsSetPhoneNumberRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientUserSettingsSetPhoneNumber(clientUserSettingsSetPhoneNumberRequest?: ClientUserSettingsSetPhoneNumberRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientUserSettingsSetPhoneNumber(clientUserSettingsSetPhoneNumberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Creates new eshop in the database and links it with user. Given user has role EshopOwner in created eshop.Returns Id of newly created eshop. Use it to request eshop specific access token 😉.
     * @summary Policies: RequireUserAccessToken
     * @param {ClientWizardAddEshopRequest} [clientWizardAddEshopRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardAddEshop(clientWizardAddEshopRequest?: ClientWizardAddEshopRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardAddEshop(clientWizardAddEshopRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Provides the pixel status. Return only true for testing. To be moved to wizard controller.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardCheckPixel(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardCheckPixel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets billing info for form prefill. To be moved to wizard controller.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardGetBillingSettings(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardGetBillingSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets basic eshop info for AddEshop form prefill in wizard. To be moved to wizard controller.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardGetEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardGetEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the Url of the eshop - used for redirection while pixel verifing process
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardGetEshopUrl(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardGetEshopUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Gets the eshop\'s product feed url from db. To be moved to wizard controller.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardGetProductFeedUrl(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardGetProductFeedUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets remaining trial period for eshop with eshop ID provided in JWT
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardGetRemainingTrialPeriod(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardGetRemainingTrialPeriod(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Create the neccessities for Eshop to function and process its data.
     * @summary Policies: RequireEshopAccessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardInitEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardInitEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Used to save/update billing data. To be moved to wizard controller.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientWizardSetBillingSettingsRequest} [clientWizardSetBillingSettingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardSetBillingSettings(clientWizardSetBillingSettingsRequest?: ClientWizardSetBillingSettingsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardSetBillingSettings(clientWizardSetBillingSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Used to set the url for the product feed. Given feed link is checked and if correct, saved to DB.
     * @summary Policies: RequireEshopAccessToken
     * @param {ClientWizardSetProductFeedUrlRequest} [clientWizardSetProductFeedUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardSetProductFeedUrl(clientWizardSetProductFeedUrlRequest?: ClientWizardSetProductFeedUrlRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardSetProductFeedUrl(clientWizardSetProductFeedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <h3>Description</h3> Updates users Name and PhoneNumber. This endpoint is called when user adds his first eshop right after he registers into the app.
     * @summary Policies: RequireUserAccessToken
     * @param {ClientWizardUpdateUserRequest} [clientWizardUpdateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public clientWizardUpdateUser(clientWizardUpdateUserRequest?: ClientWizardUpdateUserRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).clientWizardUpdateUser(clientWizardUpdateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronAutomaticCreditTopUp(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronAutomaticCreditTopUp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronBackupLogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronBackupLogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronCampaignDependenciesCheck(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronCampaignDependenciesCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronCampaignStatusesComparison(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronCampaignStatusesComparison(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronCheckForMissingAppImports(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronCheckForMissingAppImports(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronCleanProductCronLogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronCleanProductCronLogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronCountGmcSubaccounts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronCountGmcSubaccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronEshopsContributionList(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronEshopsContributionList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportCampaignHashes(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportCampaignHashes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportCampaignPerformanceData(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportCampaignPerformanceData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportEshopMarketingInfo(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportEshopMarketingInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportEshopsActivityDuration(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportEshopsActivityDuration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportEshopsList(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportEshopsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportEshopsProducts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportEshopsProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportInvoicesCsv(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportInvoicesCsv(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportInvoicesXml(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportInvoicesXml(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportInvoicesZip(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportInvoicesZip(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportPartnershipReportLastMonth(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportPartnershipReportLastMonth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronExportPartnershipReportThisMonth(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronExportPartnershipReportThisMonth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {CronCronFillDemoDataRequest} [cronCronFillDemoDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronFillDemoData(cronCronFillDemoDataRequest?: CronCronFillDemoDataRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronFillDemoData(cronCronFillDemoDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronGCLIDsExport(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronGCLIDsExport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronImportCampaignStatistics(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronImportCampaignStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronImportExchangeRates(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronImportExchangeRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronImportOrders(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronImportOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronImportOrdersYesterday(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronImportOrdersYesterday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronImportPixelEvents(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronImportPixelEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronImportPseudoUsers(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronImportPseudoUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronImportSessions(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronImportSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronProcessFeeds(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronProcessFeeds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronProcessProducts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronProcessProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronSendNoCreditSequenceMails(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronSendNoCreditSequenceMails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronSendWelcomeSequenceMails(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronSendWelcomeSequenceMails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronUpdateEshopsDashboardStatuses(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronUpdateEshopsDashboardStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronUpdateEshopsLastTwoWeeksCos(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronUpdateEshopsLastTwoWeeksCos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronUpdateEshopsTable(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronUpdateEshopsTable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronUpdateProductsStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronUpdateProductsStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireCronToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public cronCronUploadProductsToPlatforms(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).cronCronUploadProductsToPlatforms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EshopTanganicaProductsDTOGetAllProductsRequest} [eshopTanganicaProductsDTOGetAllProductsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public eshopTanganicaProductsGetAllProducts(eshopTanganicaProductsDTOGetAllProductsRequest?: EshopTanganicaProductsDTOGetAllProductsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).eshopTanganicaProductsGetAllProducts(eshopTanganicaProductsDTOGetAllProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EshopTanganicaProductsDTOGetProductDetailRequest} [eshopTanganicaProductsDTOGetProductDetailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public eshopTanganicaProductsGetProductDetail(eshopTanganicaProductsDTOGetProductDetailRequest?: EshopTanganicaProductsDTOGetProductDetailRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).eshopTanganicaProductsGetProductDetail(eshopTanganicaProductsDTOGetProductDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {boolean} [useProxy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceFeedsManualProcessAllAvailableFeeds(useProxy?: boolean, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceFeedsManualProcessAllAvailableFeeds(useProxy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {boolean} [useProxy] 
     * @param {MaintenanceDTOProcessFeedsForGivenEshopsRequest} [maintenanceDTOProcessFeedsForGivenEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceFeedsManualProcessFeedsForGivenEshops(useProxy?: boolean, maintenanceDTOProcessFeedsForGivenEshopsRequest?: MaintenanceDTOProcessFeedsForGivenEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceFeedsManualProcessFeedsForGivenEshops(useProxy, maintenanceDTOProcessFeedsForGivenEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {boolean} [useProxy] 
     * @param {MaintenanceDTOProcessFeedsForManyGivenEshopsRequest} [maintenanceDTOProcessFeedsForManyGivenEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceFeedsManualProcessFeedsForManyGivenEshops(useProxy?: boolean, maintenanceDTOProcessFeedsForManyGivenEshopsRequest?: MaintenanceDTOProcessFeedsForManyGivenEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceFeedsManualProcessFeedsForManyGivenEshops(useProxy, maintenanceDTOProcessFeedsForManyGivenEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {string} [eshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceFeedsManualSynchronizeFeedProductsForEshop(eshopId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceFeedsManualSynchronizeFeedProductsForEshop(eshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceAnonymizeUser(email?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceAnonymizeUser(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {MaintenanceDTOChangeOrderContributionRequest} [maintenanceDTOChangeOrderContributionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceChangeOrderContribution(maintenanceDTOChangeOrderContributionRequest?: MaintenanceDTOChangeOrderContributionRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceChangeOrderContribution(maintenanceDTOChangeOrderContributionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceCreateMissingFBCatalogsNew(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceCreateMissingFBCatalogsNew(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceCreateMissingGoogleSubaccounts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceCreateMissingGoogleSubaccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceCreateMissingGoogleSubaccountsNew(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceCreateMissingGoogleSubaccountsNew(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceCreateMissingSubaccountsForMarketingIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {MaintenanceDTOCurrencyChangeBackRequest} [maintenanceDTOCurrencyChangeBackRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceCurrencyChangeBack(maintenanceDTOCurrencyChangeBackRequest?: MaintenanceDTOCurrencyChangeBackRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceCurrencyChangeBack(maintenanceDTOCurrencyChangeBackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {MaintenanceDTODeleteOrderRequest} [maintenanceDTODeleteOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceDeleteOrder(maintenanceDTODeleteOrderRequest?: MaintenanceDTODeleteOrderRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceDeleteOrder(maintenanceDTODeleteOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceEliminateSubaccountDuplicates(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceEliminateSubaccountDuplicates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceGetCronToken(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceGetCronToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceGetDevToken(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceGetDevToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceImportExchangeRates(date?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceImportExchangeRates(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceMigrateExchangeRates(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceMigrateExchangeRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceSwitchFBCampaignsIds(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceSwitchFBCampaignsIds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceMaintenanceTestLog(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceMaintenanceTestLog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForGivenEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceProductsManualUpdateProductsStatusForGivenEshops(maintenanceDTOUpdateProductsStatusForGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForGivenEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceProductsManualUpdateProductsStatusForGivenEshops(maintenanceDTOUpdateProductsStatusForGivenEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest} [maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceProductsManualUpdateProductsStatusForManyGivenEshops(maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest?: MaintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceProductsManualUpdateProductsStatusForManyGivenEshops(maintenanceDTOUpdateProductsStatusForManyGivenEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {MaintenanceDTOUploadProductsForManyGivenEshopsRequest} [maintenanceDTOUploadProductsForManyGivenEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceProductsManualUploadProductsForManyGivenEshops(maintenanceDTOUploadProductsForManyGivenEshopsRequest?: MaintenanceDTOUploadProductsForManyGivenEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceProductsManualUploadProductsForManyGivenEshops(maintenanceDTOUploadProductsForManyGivenEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: RequireDevToken
     * @param {MaintenanceDTOUploadProductsForGivenEshopsRequest} [maintenanceDTOUploadProductsForGivenEshopsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public maintenanceProductsManualUploadProductsGivenEshops(maintenanceDTOUploadProductsForGivenEshopsRequest?: MaintenanceDTOUploadProductsForGivenEshopsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).maintenanceProductsManualUploadProductsGivenEshops(maintenanceDTOUploadProductsForGivenEshopsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestFillMissingMicrosoftSettingsTables(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestFillMissingMicrosoftSettingsTables(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestMiskoTemp(eshopId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestMiskoTemp(eshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accountName] 
     * @param {number} [parentCustomerId] 
     * @param {number} [superAdminId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsAddAccount(accountName?: string, parentCustomerId?: number, superAdminId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsAddAccount(accountName, parentCustomerId, superAdminId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [adGroupId] 
     * @param {CampaignManagementAd} [campaignManagementAd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsAddAd(accountId?: number, adGroupId?: number, campaignManagementAd?: CampaignManagementAd, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsAddAd(accountId, adGroupId, campaignManagementAd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [campaignId] 
     * @param {boolean} [returnInheritedBidStrategyTypes] 
     * @param {CampaignManagementAdGroup} [campaignManagementAdGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsAddAdGroup(accountId?: number, campaignId?: number, returnInheritedBidStrategyTypes?: boolean, campaignManagementAdGroup?: CampaignManagementAdGroup, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsAddAdGroup(accountId, campaignId, returnInheritedBidStrategyTypes, campaignManagementAdGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [campaignId] 
     * @param {CampaignManagementAssetGroup} [campaignManagementAssetGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsAddAssetGroup(accountId?: number, campaignId?: number, campaignManagementAssetGroup?: CampaignManagementAssetGroup, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsAddAssetGroup(accountId, campaignId, campaignManagementAssetGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsAddCampaign(accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsAddCampaign(accountId, campaignManagementCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {CampaignManagementCampaignCriterionType} [criterionType] 
     * @param {Array<CampaignManagementCampaignCriterion>} [campaignManagementCampaignCriterion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsAddCampaignCriterions(accountId?: number, criterionType?: CampaignManagementCampaignCriterionType, campaignManagementCampaignCriterion?: Array<CampaignManagementCampaignCriterion>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsAddCampaignCriterions(accountId, criterionType, campaignManagementCampaignCriterion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {string} [imagePath] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsAddMedia(accountId?: number, imagePath?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsAddMedia(accountId, imagePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [marketingId] 
     * @param {string} [domainName] 
     * @param {string} [countryCode] 
     * @param {string} [description1] 
     * @param {string} [description2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsCreateDSA(accountId?: number, marketingId?: number, domainName?: string, countryCode?: string, description1?: string, description2?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsCreateDSA(accountId, marketingId, domainName, countryCode, description1, description2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {string} [countryCode] 
     * @param {number} [storeId] 
     * @param {number} [marketingId] 
     * @param {string} [domainName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsCreatePLA(accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsCreatePLA(accountId, countryCode, storeId, marketingId, domainName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {string} [countryCode] 
     * @param {number} [storeId] 
     * @param {number} [marketingId] 
     * @param {string} [domainName] 
     * @param {string} [businessName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsCreatePRX(accountId?: number, countryCode?: string, storeId?: number, marketingId?: number, domainName?: string, businessName?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsCreatePRX(accountId, countryCode, storeId, marketingId, domainName, businessName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsDeleteAccount(accountId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsDeleteAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsDeleteCampaign(accountId?: number, campaignId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsDeleteCampaign(accountId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {CustomerManagementAccountAdditionalField} [returnAdditionalFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetAccount(accountId?: number, returnAdditionalFields?: CustomerManagementAccountAdditionalField, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetAccount(accountId, returnAdditionalFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [campaignId] 
     * @param {CampaignManagementAdGroupAdditionalField} [returnAdditionalFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetAdGroupsByCampaignId(accountId?: number, campaignId?: number, returnAdditionalFields?: CampaignManagementAdGroupAdditionalField, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetAdGroupsByCampaignId(accountId, campaignId, returnAdditionalFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [adGroupId] 
     * @param {CampaignManagementAdType} [adType] 
     * @param {CampaignManagementAdAdditionalField} [returnAdditionalFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetAdsByAdGroupId(accountId?: number, adGroupId?: number, adType?: CampaignManagementAdType, returnAdditionalFields?: CampaignManagementAdAdditionalField, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetAdsByAdGroupId(accountId, adGroupId, adType, returnAdditionalFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [campaignId] 
     * @param {CampaignManagementCampaignType} [campaignType] 
     * @param {CampaignManagementCampaignAdditionalField} [returnAdditionalFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetCampaign(accountId?: number, campaignId?: number, campaignType?: CampaignManagementCampaignType, returnAdditionalFields?: CampaignManagementCampaignAdditionalField, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetCampaign(accountId, campaignId, campaignType, returnAdditionalFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {number} [campaignId] 
     * @param {CampaignManagementCampaignCriterionType} [criterionType] 
     * @param {CampaignManagementCriterionAdditionalField} [returnAdditionalFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetCampaignCriterionsByIds(accountId?: number, campaignId?: number, criterionType?: CampaignManagementCampaignCriterionType, returnAdditionalFields?: CampaignManagementCriterionAdditionalField, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetCampaignCriterionsByIds(accountId, campaignId, criterionType, returnAdditionalFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {CampaignManagementCampaignType} [campaignType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetCampaignsByAccountId(accountId?: number, campaignType?: CampaignManagementCampaignType, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetCampaignsByAccountId(accountId, campaignType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetGeoLocationsFileUrl(accountId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetGeoLocationsFileUrl(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {AdInsightEntityType} [entityType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId(accountId?: number, entityType?: AdInsightEntityType, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetPerformanceInsightsDetailDataByAccountId(accountId, entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsGetUser(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsGetUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsSearchAccounts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsSearchAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportingCampaignPerformanceReportRequest} [reportingCampaignPerformanceReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsSubmitGenerateReport(reportingCampaignPerformanceReportRequest?: ReportingCampaignPerformanceReportRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsSubmitGenerateReport(reportingCampaignPerformanceReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {string} [newName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsUpdateAccount(accountId?: number, newName?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsUpdateAccount(accountId, newName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [accountId] 
     * @param {CampaignManagementCampaign} [campaignManagementCampaign] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingAdsUpdateCampaign(accountId?: number, campaignManagementCampaign?: CampaignManagementCampaign, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingAdsUpdateCampaign(accountId, campaignManagementCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestBingGetCampaignReport(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestBingGetCampaignReport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {number} [catalogId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiBatchProducts(merchantId?: number, catalogId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiBatchProducts(merchantId, catalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiCreateCatalog(merchantId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiCreateCatalog(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [storeName] 
     * @param {string} [storeUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiCreateStore(storeName?: string, storeUrl?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiCreateStore(storeName, storeUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {number} [catalogId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiDeleteCatalog(merchantId?: number, catalogId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiDeleteCatalog(merchantId, catalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {string} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiDeleteProduct(merchantId?: number, productId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiDeleteProduct(merchantId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {number} [catalogId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetCatalog(merchantId?: number, catalogId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetCatalog(merchantId, catalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetCatalogs(merchantId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetCatalogs(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {string} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetProduct(merchantId?: number, productId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetProduct(merchantId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetProducts(merchantId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetProducts(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetProductsStatuses(merchantId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetProductsStatuses(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetProductsStatusesSummary(merchantId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetProductsStatusesSummary(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetStore(merchantId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetStore(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiGetStores(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiGetStores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {number} [catalogId] 
     * @param {string} [offerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiInsertProduct(merchantId?: number, catalogId?: number, offerId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiInsertProduct(merchantId, catalogId, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [merchantId] 
     * @param {number} [catalogId] 
     * @param {boolean} [isPublishingEnabled] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestTestContentApiUpdateCatalog(merchantId?: number, catalogId?: number, isPublishingEnabled?: boolean, name?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestTestContentApiUpdateCatalog(merchantId, catalogId, isPublishingEnabled, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestUpdateCategoryAdvertiseMicrosoft(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestUpdateCategoryAdvertiseMicrosoft(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testMTestUpdateProductMicrosoftStatus(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testMTestUpdateProductMicrosoftStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestAddAdminUser(email?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestAddAdminUser(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestAdminMailsToResx(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestAdminMailsToResx(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestAffilContrComparison(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestAffilContrComparison(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestAnyCompensation(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestAnyCompensation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [guid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestAssignDeletedOwner(guid?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestAssignDeletedOwner(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestBillingSettingsExporting(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestBillingSettingsExporting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCHECKSTRIPECUSTOMERS(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCHECKSTRIPECUSTOMERS(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCheckCreditTesting(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCheckCreditTesting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCheckOwners(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCheckOwners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCheckTransactions(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCheckTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCreateMissingTriggerFunctionsInTenants(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCreateMissingTriggerFunctionsInTenants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCreditComparison(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCreditComparison(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCreditComparisonGivenEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCreditComparisonGivenEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCreditComparisonInternal(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCreditComparisonInternal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCreditPerEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCreditPerEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestCreditTenantComparison(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestCreditTenantComparison(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestGetAllActiveBillingSettings(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestGetAllActiveBillingSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestGetEshopsIsPayerOfTaxes(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestGetEshopsIsPayerOfTaxes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestGetInvalidBsEshops(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestGetInvalidBsEshops(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestGetInvalidInvoices(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestGetInvalidInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestGetSentMailNumbers(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestGetSentMailNumbers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestInvoiceGenTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestInvoiceGenTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestLocalizerTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestLocalizerTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestPTestDTOLogCountsRequest} [testPTestDTOLogCountsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestLogCounts(testPTestDTOLogCountsRequest?: TestPTestDTOLogCountsRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestLogCounts(testPTestDTOLogCountsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestLogsToFiles(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestLogsToFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestMailTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestMailTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Policies: ; AdminPermissions: GetKpiReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestPermissionAuthTesting(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestPermissionAuthTesting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestPositiveAffilContrPerEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestPositiveAffilContrPerEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestRegenerateFuckedInvoices(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestRegenerateFuckedInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestRoleClaims(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestRoleClaims(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSendAllAdminEmails(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSendAllAdminEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSendAllClientEmails(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSendAllClientEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSendMail(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSendMail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSendMailWithAttachment(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSendMailWithAttachment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSetCreditNoteAmountsNegative(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSetCreditNoteAmountsNegative(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSetNullMailLogAdminMailTypes(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSetNullMailLogAdminMailTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSetNullMailLogClientMailTypesNew(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSetNullMailLogClientMailTypesNew(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSetNullMailLogClientMailTypesOld(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSetNullMailLogClientMailTypesOld(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSetPassword(email?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSetPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSetUserAdmin(email?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSetUserAdmin(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestSyncInvoiceIssueDatesWithBs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestSyncInvoiceIssueDatesWithBs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestTestMailsRedesign(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestTestMailsRedesign(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestTestUploadFile(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestTestUploadFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestUPDATESTRIPECUSTOMERS(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestUPDATESTRIPECUSTOMERS(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestUserClaimsRetrieval(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestUserClaimsRetrieval(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestPTestDTOUtcDateValidationRequest} [testPTestDTOUtcDateValidationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPTestUtcDateTimeValidation(testPTestDTOUtcDateValidationRequest?: TestPTestDTOUtcDateValidationRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPTestUtcDateTimeValidation(testPTestDTOUtcDateValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundAssignAdminRole(email?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundAssignAdminRole(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundAssignAffilRole(email?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundAssignAffilRole(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundAssignTestRole(email?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundAssignTestRole(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [batchSize] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundBulkInsertTest(batchSize?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundBulkInsertTest(batchSize, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCampaignStatisticsDataRepair(body?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCampaignStatisticsDataRepair(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundChangeFacebookCampaignToHistoricAndDeleteForMktIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCheckFBCampaignsCount(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCheckFBCampaignsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCheckPixel(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCheckPixel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCleanSpacesBucket(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCleanSpacesBucket(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCorrectUtmsManuallyForGivenMktIds(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCorrectUtmsManuallyForGivenMktIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCreateAllFbCatalogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCreateAllFbCatalogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCreateFacebookCampaignTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCreateFacebookCampaignTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCreateFbCampaigns(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCreateFbCampaigns(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [folderPath] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCreateFolderOnDO(folderPath?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCreateFolderOnDO(folderPath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCreateMissingGoogleCampaigns(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCreateMissingGoogleCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundCreateMissingGoogleSubaccounts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundCreateMissingGoogleSubaccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDataComparingTransactions(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDataComparingTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDataComparingTransactionsPerEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDataComparingTransactionsPerEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDbViewTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDbViewTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteAdCreative(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteAdCreative(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteAllAssistedOrders(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteAllAssistedOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteBills(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteBills(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eshopId] 
     * @param {string} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteDPACampaignByDbId(eshopId?: string, campaignId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteDPACampaignByDbId(eshopId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteGmcProductsForEshops(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteGmcProductsForEshops(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteOrdersFromBeforeMigration(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteOrdersFromBeforeMigration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteOrdersFromDay(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteOrdersFromDay(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteOrdersWithPseudoUserId(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteOrdersWithPseudoUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteStuff(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteStuff(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeleteTransactionsAndBills(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeleteTransactionsAndBills(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDeserializeTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDeserializeTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundDownloadImageToLocal(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundDownloadImageToLocal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundEshopCreditUpdateTriggerToAllSchemas(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundEshopCreditUpdateTriggerToAllSchemas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundEshopIdTestWrite(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundEshopIdTestWrite(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundEshopSubaccountsCheck(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundEshopSubaccountsCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFBDeleteWhatever(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFBDeleteWhatever(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [catalogId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFBItemStatus(catalogId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFBItemStatus(catalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFBRenameCatalogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFBRenameCatalogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFacebookCampaignStructureV3(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFacebookCampaignStructureV3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFbCampaignStatisticsFacebook(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFbCampaignStatisticsFacebook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFbCampaignsRestructure(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFbCampaignsRestructure(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFbDeleteAdsetsOld(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFbDeleteAdsetsOld(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFillAllAllowProducts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFillAllAllowProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFillDailyBudgets(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFillDailyBudgets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFillFbCampaingMarketingFormat(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFillFbCampaingMarketingFormat(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFindAllGmcProductErrors(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFindAllGmcProductErrors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFindEshopsWithMissingSubaccounts(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFindEshopsWithMissingSubaccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [useCzkAdAccount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFindFbAdsUtmDuplicates(useCzkAdAccount?: boolean, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFindFbAdsUtmDuplicates(useCzkAdAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFindFbCampaignsInfo(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFindFbCampaignsInfo(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFindMarketingIds(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFindMarketingIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFindMissingFBAds(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFindMissingFBAds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [logOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFixFbAdStatuses(logOnly?: boolean, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFixFbAdStatuses(logOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [marketingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFixFbAdUtms(marketingId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFixFbAdUtms(marketingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFixGoogleCampaignStatisticsTimezone(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFixGoogleCampaignStatisticsTimezone(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFixTaxRatesAndVariableSymbols(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFixTaxRatesAndVariableSymbols(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFixTransactions(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFixTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundFuckIIIIIIIIIIIIT(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundFuckIIIIIIIIIIIIT(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [productCount] 
     * @param {number} [feedCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGCTest(productCount?: number, feedCount?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGCTest(productCount, feedCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [feedCount] 
     * @param {number} [taskCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGCTestParallelRealData(feedCount?: number, taskCount?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGCTestParallelRealData(feedCount, taskCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGarbageCollectorGeneration2(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGarbageCollectorGeneration2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGenerateEshopSpecificAccessToken(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGenerateEshopSpecificAccessToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetActiveEshopsAndProductsCount(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetActiveEshopsAndProductsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [adAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAdCreatives(adAccountId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAdCreatives(adAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAdspendsPerCampaign(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAdspendsPerCampaign(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAllAdExpensesForDay(body?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAllAdExpensesForDay(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAllCampaignsGoogle(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAllCampaignsGoogle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAllFbCatalogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAllFbCatalogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAllGmcSubaccountsCz(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAllGmcSubaccountsCz(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAllGmcSubaccountsEu(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAllGmcSubaccountsEu(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetAllUnknown(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetAllUnknown(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetCampaignStatsTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetCampaignStatsTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetCampaignsWithMissingProductSets(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetCampaignsWithMissingProductSets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetCreditDifference(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetCreditDifference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetCreditExpensesDifference(body?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetCreditExpensesDifference(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetCreditPerEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetCreditPerEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetDesiredStatusIssues(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetDesiredStatusIssues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetEshopIdsFromMktIds(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetEshopIdsFromMktIds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetEshopsInfo(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetEshopsInfo(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetExchangeRate(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetExchangeRate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetFileFormatByHeader(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetFileFormatByHeader(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [tanganicaEu] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetGmcLimits(tanganicaEu?: boolean, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetGmcLimits(tanganicaEu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {EnumsCountryOfAdvertisement} [country] 
     * @param {number} [merchantId] 
     * @param {boolean} [tanganicaEu] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetGmcProductIssues(productId?: string, country?: EnumsCountryOfAdvertisement, merchantId?: number, tanganicaEu?: boolean, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetGmcProductIssues(productId, country, merchantId, tanganicaEu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [tanganicaEu] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetGmcSubaccountsCount(tanganicaEu?: boolean, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetGmcSubaccountsCount(tanganicaEu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [campaignId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetGoogleCampaignFormatTest(campaignId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetGoogleCampaignFormatTest(campaignId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientControllersGetHourlyCostMicrosRequest} [clientControllersGetHourlyCostMicrosRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetHourlyCostMicros(clientControllersGetHourlyCostMicrosRequest?: ClientControllersGetHourlyCostMicrosRequest, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetHourlyCostMicros(clientControllersGetHourlyCostMicrosRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetLookalikeAudienceByOriginAudienceId(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetLookalikeAudienceByOriginAudienceId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetMktIdsToProcessFeedsAtLeastOnce(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetOrderDataTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetOrderDataTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetOrdersPerCampaignsPerEshop(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetOrdersPerCampaignsPerEshop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGetSubaccountIdForEshop(eshopId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGetSubaccountIdForEshop(eshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [campaignId] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundGoogleAdsStatsTest(customerId?: number, campaignId?: number, body?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundGoogleAdsStatsTest(customerId, campaignId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundHttpClientTest(url?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundHttpClientTest(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundImportAppImportsRecords(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundImportAppImportsRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundImportDataFroNewlyMigratedCampaigns(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundImportDataFroNewlyMigratedCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundImportOrdersExactDay(date?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundImportOrdersExactDay(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundImportOrdersExactTime(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundImportOrdersExactTime(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundImportStatisticsExactDay(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundImportStatisticsExactDay(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundListAllTgncaGlobalFbCatalogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundListAllTgncaGlobalFbCatalogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundListResponseTypeSwagger(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundListResponseTypeSwagger(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundLogInsideBQ(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundLogInsideBQ(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundMeasureFeedSize(url?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundMeasureFeedSize(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundMigrateProductCategories(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundMigrateProductCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundMigrationTest(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundMigrationTest(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundPreloadController(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundPreloadController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundPrintPageViewPathFromJson(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundPrintPageViewPathFromJson(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [input] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundRegexSplitTest(input?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundRegexSplitTest(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundRemoveGmcFeedFile(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundRemoveGmcFeedFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundRepairCampaignDesiredStatuses(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundRepairCampaignDesiredStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundRepairCampaigns(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundRepairCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundReuploadProductsGmc(eshopId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundReuploadProductsGmc(eshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundSetAllProductsGmcUpdateToTrue(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundSetAllProductsGmcUpdateToTrue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [campaignId] 
     * @param {number} [budget] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundSetFbCampaignBudget(campaignId?: string, budget?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundSetFbCampaignBudget(campaignId, budget, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundSetGmcProductsToReulpoad(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundSetGmcProductsToReulpoad(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTagMapSerializationTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTagMapSerializationTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestClearSheet(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestClearSheet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {number} [hour] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestDateTimeCreation(date?: string, hour?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestDateTimeCreation(date, hour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestEshopBlacklist(eshopId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestEshopBlacklist(eshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [adCreativeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestFbAdCreativeUtm(adCreativeId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestFbAdCreativeUtm(adCreativeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestFbCatalogs(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestFbCatalogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestFbStatuses(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestFbStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestIOptionsInjection(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestIOptionsInjection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [input] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestLinkUtmRemoving(input?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestLinkUtmRemoving(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestLog(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestLog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestMultiInitPerformance(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestMultiInitPerformance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [input] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestParsingPrice(input?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestParsingPrice(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestProductIssuesBlacklist(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestProductIssuesBlacklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestReadDate(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestReadDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [eshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestStatsImport(eshopId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestStatsImport(eshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestStatusFromGads(campaignId?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestStatusFromGads(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestTriggerFunctionsFile(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestTriggerFunctionsFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTestikBlastik(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTestikBlastik(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [pid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTransactionWebhookTest(pid?: string, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTransactionWebhookTest(pid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundTurnOffFbAds(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundTurnOffFbAds(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundimportSessionsTest(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundimportSessionsTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundtestImportCampaignStatisticsBack(options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundtestImportCampaignStatisticsBack(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [productCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TANGANICAApi
     */
    public testPlaygroundtestMemory(productCount?: number, options?: AxiosRequestConfig) {
        return TANGANICAApiFp(this.configuration).testPlaygroundtestMemory(productCount, options).then((request) => request(this.axios, this.basePath));
    }
}

