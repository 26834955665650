<template>
  <div class="mb-2 px-5">
    <Breadcrumb class="" :model="breadcrumb">
      <template #item="{ item, props }">
        <router-link :to="item.href">
          {{ $t('socialMedia.' + item.name) }}
        </router-link>
      </template>
    </Breadcrumb>
  </div>
  <ContentBlock :menuCardItems="menuCardItems" v-if="router.currentRoute.value.name === AICONTENT_ROUTES.INDEX" />
  <RouterView v-else />
</template>
<script setup lang="ts">
import Breadcrumb from 'primevue/breadcrumb';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import ContentBlock from '@/modules/aicontent/components/ContentBlock.vue';
import { AICONTENT_ROUTES } from '@/modules/aicontent/enums/RoutesEnum';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const menuCardItems = [
  {
    name: t('socialMedia.socialMediaHeader'),
    description: t('socialMedia.socialMediaDescription'),
    hrefName: AICONTENT_ROUTES.SOCIAL_MEDIA,
    imgsrc: '/socialContent/SocialMedia.png',
  },
  {
    name: t('socialMedia.blogHeader'),
    description: t('socialMedia.blogDescription'),
    hrefName: AICONTENT_ROUTES.BLOG,
    imgsrc: '/socialContent/Blog.png',
  },
  {
    name: t('socialMedia.emialHeader'),
    description: t('socialMedia.emailDescription'),
    hrefName: AICONTENT_ROUTES.EMAIL,
    imgsrc: '/socialContent/Emailing.png',
  },
];

const breadcrumb = computed(() => {
  const matchedRoutes = route.matched;
  const breadcrumbArray = [];

  for (let i = 1; i < matchedRoutes.length; i++) {
    let path = matchedRoutes[i].path;

    // Replace all route params (both required and optional) with actual values
    Object.keys(route.params).forEach((param) => {
      // Create regex to match both required and optional params
      const paramPattern = `:${param}(\\?)?`;
      const regex = new RegExp(paramPattern, 'g');
      path = path.replace(regex, route.params[param] || '');
    });

    breadcrumbArray.push({
      name: matchedRoutes[i].name,
      href: path,
    });
  }

  return breadcrumbArray;
});
</script>
