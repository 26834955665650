import { RouteRecordRaw } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { FULL_PAGE_ROUTES } from '@/enums/Routes/FullPageRoutes';
import { PUBLIC_ROUTES } from '@/enums/Routes/PublicRoutesEnum';
import { WIZARD_ROUTES } from '@/enums/Routes/WizardRoutesEnum';
import ClientLayout from '@/layout/ClientLayout.vue';
import FullPageLayout from '@/layout/FullPageLayout.vue';
import PublicLayout from '@/layout/PublicLayout.vue';
import WizardLayout from '@/layout/WizardLayout.vue';
import AffiliateReportPage from '@/modules/affiliateProgram/pages/AffiliateProgram.vue';
import aiContentRoutes from '@/modules/aicontent/router/routes';
import AddEshopManagerCallback from '@/modules/authentication/pages/AddEshopManagerCallback.vue';
import ChangeEmailCallbackPage from '@/modules/authentication/pages/ChangeEmailCallback.vue';
import ForgottenPasswordPage from '@/modules/authentication/pages/ForgottenPassword.vue';
import IframeLogin from '@/modules/authentication/pages/IframeLogin.vue';
import ListOfEshops from '@/modules/authentication/pages/ListOfEshops.vue';
import LoginPage from '@/modules/authentication/pages/Login.vue';
import LoginAdminPage from '@/modules/authentication/pages/LoginAsFromAdmin.vue';
import LogoutPage from '@/modules/authentication/pages/Logout.vue';
import RegisterPage from '@/modules/authentication/pages/Register.vue';
import ResetPasswordCallbackPage from '@/modules/authentication/pages/ResetPasswordCallback.vue';
import SocialCallback from '@/modules/authentication/pages/SocialCallback.vue';
import BusinessShareCallbackPage from '@/modules/campaigns/pages/meta/DPA/BusinessShareCallback.vue';
import PageShareCallbackPage from '@/modules/campaigns/pages/meta/DPA/PageShareCallback.vue';
import campaignsRoutes from '@/modules/campaigns/router/routes';
import ContactPage from '@/modules/contact/pages/Contact.vue';
import DashboardPage from '@/modules/dashboard/pages/Dashboard.vue';
import EshopSettingsPage from '@/modules/eshopSettings/pages/EshopSetttings.vue';
import SupportPage from '@/modules/help/pages/Help.vue';
import PrioritizationPage from '@/modules/productPrioritization/pages/ProductPrioritization.vue';
import AutomaticRecharge from '@/modules/rechargeCredit/components/AutomaticRecharge.vue';
import BankTransfer from '@/modules/rechargeCredit/components/BankTransfer.vue';
import Checkout from '@/modules/rechargeCredit/components/Checkout.vue';
import BillInformationPage from '@/modules/rechargeCredit/pages/BillInformation.vue';
import PaymentPage from '@/modules/rechargeCredit/pages/Payment.vue';
import PaymentHistoryPage from '@/modules/rechargeCredit/pages/PaymentHistory.vue';
import RechargeCreditPage from '@/modules/rechargeCredit/pages/RechargeCredit.vue';
import StripeCallbackPage from '@/modules/rechargeCredit/pages/StripeCallback.vue';
import CampaignStatisticsPage from '@/modules/statistics/pages/CampaignStatistics.vue';
import CategoriesStatistics from '@/modules/statistics/pages/CategoriesStatistics.vue';
import CompetitiveMetrics from '@/modules/statistics/pages/CompetitiveMetrics.vue';
import OrdersStatisticsPage from '@/modules/statistics/pages/OrdersStatistics.vue';
import ProductsStatisticsPage from '@/modules/statistics/pages/ProductsStatistics.vue';
import StatisticsPage from '@/modules/statistics/pages/Statistics.vue';
import TrafficDirectory from '@/modules/statistics/pages/TrafficDirectory.vue';
import TrafficStatistics from '@/modules/statistics/pages/TrafficStatistics.vue';
import TrafficVisitSource from '@/modules/statistics/pages/TrafficVisitSource.vue';
import UserSettingsPage from '@/modules/userSettings/pages/UserSettings.vue';
import WizardAddEshopPage from '@/modules/wizard/pages/WizardAddEshop.vue';
import WizardBillInfoPage from '@/modules/wizard/pages/WizardBillInfo.vue';
import WizardPixelPage from '@/modules/wizard/pages/WizardPixel.vue';
import WizardXmlPage from '@/modules/wizard/pages/WizardXmlFeed.vue';
import { useEshopsStore } from '@/stores/eshops';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { EnumsWizardStatus } from '../../generated/api';

export enum ROUTES {
  NOT_FOUND = 'routesNotFound',
}

const routes: Array<RouteRecordRaw> = [
  // Public routes
  {
    path: '/:lang?',
    meta: {
      layout: PublicLayout,
    },
    children: [
      // Login
      {
        path: '',
        name: PUBLIC_ROUTES.LOGIN_PAGE,
        meta: {
          guest: true,
          client: false,
        },
        component: LoginPage,
      },

      // Register
      {
        path: 'register',
        name: PUBLIC_ROUTES.REGISTER_PAGE,
        meta: {
          guest: true,
          client: false,
        },
        component: RegisterPage,
      },
      // Forgotten password
      {
        path: 'forgotten-password',
        name: PUBLIC_ROUTES.FORGOTTEN_PASSWORD_PAGE,
        meta: {
          guest: true,
          client: false,
        },
        component: ForgottenPasswordPage,
      },

      // Reset password
      {
        path: 'reset-password/callback',
        name: PUBLIC_ROUTES.RESET_PASSWORD_PAGE,
        meta: {
          guest: true,
          client: false,
        },
        component: ResetPasswordCallbackPage,
      },

      // Change email
      {
        path: 'change-email/callback',
        name: PUBLIC_ROUTES.CHANGE_EMAIL_PAGE,
        meta: {
          guest: true,
          client: true,
        },
        component: ChangeEmailCallbackPage,
      },

      // From Admin Auth
      {
        path: 'dbvhdsjpodhevfpaodsvhnpauisdh',
        name: PUBLIC_ROUTES.LOGIN_ADMIN_PAGE,
        component: LoginAdminPage,
        meta: {
          guest: true,
          client: true,
        },
      },
      // Social Callback
      {
        path: ':provider/callback',
        name: PUBLIC_ROUTES.SOCIAL_CALLBACK_PAGE,
        meta: {
          guest: true,
          client: false,
        },
        props: (route) => ({
          provider: route.params.provider,
        }),
        component: SocialCallback,
      },
    ],
  },
  // Wizard routes
  {
    path: '/:lang?/wizard',
    meta: {
      layout: WizardLayout,
      guest: false,
      client: true,
    },
    beforeEnter: async (to, from, next) => {
      const wizardHelperStore = useWizardHelperStore();
      const eshopStore = useEshopsStore();
      if (eshopStore.managedEshops.length === 0 || eshopStore.managedEshops.some((eshop) => eshop.wizardStatus !== EnumsWizardStatus.AddedPixel)) {
        next();
      } else if (wizardHelperStore.isNewEshop) {
        next();
      } else {
        next({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
      }
    },
    children: [
      {
        path: 'eshop',
        name: WIZARD_ROUTES.ADD_ESHOP_PAGE,
        component: WizardAddEshopPage,
      },
      {
        path: 'billinfo',
        name: WIZARD_ROUTES.BILLINFO_PAGE,
        component: WizardBillInfoPage,
      },
      {
        path: 'xml',
        name: WIZARD_ROUTES.XML_PAGE,
        component: WizardXmlPage,
      },
      {
        path: 'pixel',
        name: WIZARD_ROUTES.PIXEL_PAGE,
        component: WizardPixelPage,
      },
    ],
  },

  // Client routes
  {
    path: '/:lang?/client',
    meta: {
      layout: ClientLayout,
      guest: false,
      client: true,
    },
    beforeEnter: (to, from, next) => {
      const eshopStore = useEshopsStore();
      // First time user
      if (eshopStore.managedEshops.length === 0) {
        next({ name: WIZARD_ROUTES.ADD_ESHOP_PAGE });
      } else {
        if (eshopStore.selectedEshop?.wizardStatus !== EnumsWizardStatus.AddedPixel) {
          next({ name: WIZARD_ROUTES.ADD_ESHOP_PAGE });
        } else {
          next();
        }
      }
    },
    children: [
      {
        path: 'dashboard',
        name: CLIENT_ROUTES.DASHBOARD_PAGE,
        component: DashboardPage,
      },
      {
        path: 'report',
        name: CLIENT_ROUTES.STATISTICS_PAGE,
        component: StatisticsPage,
        redirect() {
          return {
            name: CLIENT_ROUTES.CAMPAIGN_STATISTICS_PAGE,
          };
        },
        children: [
          {
            path: 'campaign',
            name: CLIENT_ROUTES.CAMPAIGN_STATISTICS_PAGE,
            component: CampaignStatisticsPage,
          },
          {
            path: 'orders',
            name: CLIENT_ROUTES.ORDERS_STATISTICS_PAGE,
            component: OrdersStatisticsPage,
          },
          {
            path: 'products',
            name: CLIENT_ROUTES.PRODUCTS_STATISTICS_PAGE,
            component: ProductsStatisticsPage,
          },
          {
            path: 'traffic',
            name: CLIENT_ROUTES.TRAFFIC_DIRECTORY_STATISTICS_PAGE,
            component: TrafficDirectory,
            redirect() {
              return {
                name: CLIENT_ROUTES.TRAFFIC_STATISTICS_PAGE,
              };
            },
            children: [
              {
                path: 'trafficTable',
                name: CLIENT_ROUTES.TRAFFIC_STATISTICS_PAGE,
                component: TrafficStatistics,
              },
              {
                path: 'visitSources',
                name: CLIENT_ROUTES.TRAFFIC_VISIT_SOURCES_STATISTICS_PAGE,
                component: TrafficVisitSource,
              },
            ],
          },
          {
            path: 'categories',
            name: CLIENT_ROUTES.CATEGORIES_STATISTICS_PAGE,
            component: CategoriesStatistics,
          },
          {
            path: 'competitiveMetrics',
            name: CLIENT_ROUTES.COMPETITIVE_METRICS_STATISTICS_PAGE,
            component: CompetitiveMetrics,
          }
        ],
      },
      ...campaignsRoutes,
      ...aiContentRoutes,
      {
        path: 'recharge-credit',
        name: CLIENT_ROUTES.RECHARGE_CREDIT_PAGE,
        component: RechargeCreditPage,
        redirect() {
          return {
            name: CLIENT_ROUTES.PAYMENT_PAGE,
          };
        },
        children: [
          {
            path: 'payment',
            name: CLIENT_ROUTES.PAYMENT_PAGE,
            component: PaymentPage,
            redirect() {
              return {
                name: CLIENT_ROUTES.PAYMENT_GATEWAY_PAGE,
              };
            },
            children: [
              {
                path: 'payment-gateway',
                name: CLIENT_ROUTES.PAYMENT_GATEWAY_PAGE,
                component: Checkout,
              },
              {
                path: 'bank-transfer',
                name: CLIENT_ROUTES.PAYMENT_BANK_TRANSFER_PAGE,
                component: BankTransfer,
              },
              {
                path: 'automatic-recharge',
                name: CLIENT_ROUTES.PAYMENT_AUTOMATIC_RECHARGE_PAGE,
                component: AutomaticRecharge,
              },
            ],
          },
          {
            path: 'payment-history',
            name: CLIENT_ROUTES.PAYMENT_HISTORY_PAGE,
            component: PaymentHistoryPage,
          },
          {
            path: 'billing-info',
            name: CLIENT_ROUTES.BILLING_INFO_PAGE,
            component: BillInformationPage,
          },
        ],
      },
      {
        path: 'prioritization',
        name: CLIENT_ROUTES.PRIORITIZATION_PAGE,
        component: PrioritizationPage,
      },

      // Underlined
      {
        path: 'affiliate-report',
        name: CLIENT_ROUTES.AFFILIATE_REPORT_PAGE,
        component: AffiliateReportPage,
      },
      {
        path: 'eshop-settings',
        name: CLIENT_ROUTES.ESHOP_SETTINGS_PAGE,
        component: EshopSettingsPage,
      },
      {
        path: 'support',
        name: CLIENT_ROUTES.SUPPORT_PAGE,
        component: SupportPage,
      },
      {
        path: 'contact',
        name: CLIENT_ROUTES.CONTACT_PAGE,
        component: ContactPage,
      },

      // User Menu
      {
        path: 'user-settings',
        name: CLIENT_ROUTES.USER_SETTINGS_PAGE,
        component: UserSettingsPage,
      },
    ],
  },
  // Full page routes
  {
    path: '/:lang?/preview',
    meta: {
      layout: FullPageLayout,
    },
    children: [
      {
        path: 'facebook/business/callback',
        name: CLIENT_ROUTES.FACEBOOK_BUSINESS_SHARE_CALLBACK_PAGE,
        meta: {
          guest: false,
          client: true,
        },
        component: BusinessShareCallbackPage,
      },
      {
        path: 'facebook/pageshare/callback',
        name: CLIENT_ROUTES.FACEBOOK_PAGE_SHARE_CALLBACK_PAGE,
        meta: {
          guest: false,
          client: true,
        },
        component: PageShareCallbackPage,
      },
      {
        path: 'logout',
        meta: {
          guest: false,
          client: true,
        },
        name: CLIENT_ROUTES.LOGOUT_PAGE,
        component: LogoutPage,
        props(to) {
          return {
            expiredRefreshToken: to.query.expired,
          };
        },
      },
      {
        path: 'eshops-list',
        meta: {
          guest: false,
          client: true,
        },
        name: CLIENT_ROUTES.ESHOP_LIST_PAGE,
        component: ListOfEshops,
      },
      {
        path: 'add-manager/callback',
        meta: {
          guest: true,
          client: false,
        },
        name: FULL_PAGE_ROUTES.ADD_ESHOP_MANAGER_CALLBACK_PAGE,
        component: AddEshopManagerCallback,
      },
      // Iframe login callback
      {
        path: 'iframe/login',
        name: FULL_PAGE_ROUTES.IFRAME_LOGIN_PAGE,
        meta: {
          guest: true,
          client: true,
          admin: false,
        },
        props: (route) => ({
          accessToken: route.query.access,
          refreshToken: route.query.refresh,
          selectedEshopId: route.query.selectedEshopId,
        }),
        component: IframeLogin,
      },
      {
        path: 'recharge-credit',
        name: FULL_PAGE_ROUTES.RECHARGE_CREDIT_PAGE,
        component: RechargeCreditPage,
        meta: {
          guest: false,
          client: true,
        },
        redirect() {
          return {
            name: FULL_PAGE_ROUTES.PAYMENT_PAGE,
          };
        },
        children: [
          {
            path: 'payment',
            name: FULL_PAGE_ROUTES.PAYMENT_PAGE,
            component: PaymentPage,
            redirect() {
              return {
                name: FULL_PAGE_ROUTES.PAYMENT_GATEWAY_PAGE,
              };
            },
            children: [
              {
                path: 'payment-gateway',
                name: FULL_PAGE_ROUTES.PAYMENT_GATEWAY_PAGE,
                component: Checkout,
              },
              {
                path: 'bank-transfer',
                name: FULL_PAGE_ROUTES.PAYMENT_BANK_TRANSFER_PAGE,
                component: BankTransfer,
              },
              {
                path: 'automatic-recharge',
                name: FULL_PAGE_ROUTES.PAYMENT_AUTOMATIC_RECHARGE_PAGE,
                component: AutomaticRecharge,
              },
            ],
          },
          {
            path: 'payment-history',
            name: FULL_PAGE_ROUTES.PAYMENT_HISTORY_PAGE,
            component: PaymentHistoryPage,
          },
          {
            path: 'billing-info',
            name: FULL_PAGE_ROUTES.BILLING_INFO_PAGE,
            component: BillInformationPage,
          },
        ],
      },
    ],
  },
  // Full page routes
  {
    path: '/:lang?/stripe',
    meta: {
      layout: FullPageLayout,
    },
    children: [
      {
        path: 'callback',
        name: CLIENT_ROUTES.STRIPE_SUCCESS_CALLBACK_PAGE,
        meta: {
          guest: false,
          client: true,
        },
        component: StripeCallbackPage,
      },
    ],
  },

  // 404
  {
    path: '/:pathMatch(.*)*',
    name: ROUTES.NOT_FOUND,
    redirect: {
      name: CLIENT_ROUTES.DASHBOARD_PAGE,
    },
  },
];

export default routes;
