import { CampaignEnum } from '@/modules/campaigns/enums/CampaignEnum';
import { EnumsMarketingFormat } from '../../../../generated/api';

export const loadWizardStore = async (campaign: CampaignEnum, format: EnumsMarketingFormat) => {
  try {
    const { [`use${campaign.charAt(0).toUpperCase() + campaign?.slice(1) + format?.toUpperCase() + 'WizardStore'}`]: useStore } = await import(`@/modules/campaigns/stores/${campaign}/${format}/wizard.ts`);

    return useStore();
  } catch (e) {
    console.error('Error loading wizard store');
    console.error('Path to the store file',`@/modules/campaigns/stores/${campaign}/${format}/wizard.ts`);
    console.error('Store Name',`use${campaign.charAt(0).toUpperCase() + campaign?.slice(1) + format?.toUpperCase() + 'WizardStore'}`);
    console.error('Campaign:',campaign);
    console.error('Format:',format);
    console.error(e);
  }
};
