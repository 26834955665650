<template>
  <div class="py-5" v-if="statusCallBackError">
    <p class="mt-4 text-center text-3xl font-bold tracking-tight text-red-600 sm:text-5xl">
      {{ $t('rechargeCredit.stripeCallback.error') }}
    </p>
    <p class="mt-4 text-center text-xl font-bold tracking-tight text-main sm:text-xl">
      {{ $t('wizard.pages.wizardPixel.needHelpModal.subtitle') }}
    </p>
    <p class="mt-2 text-center font-bold text-main md:text-lg">
      {{ ContactInfoEnum.EMAIL }}
    </p>
  </div>
  <div v-else>
    <div v-if="!isCardSet" class="flex flex-col p-1">
      <div class="m-auto w-full">
        <div ref="checkoutSetupRef"></div>
      </div>
    </div>
    <div v-else class="mx-auto max-w-xl">
      <div>
        <label for="amount" class="relative mb-1 block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('rechargeCredit.automaticRechargeStatus') }}</label>
        <ToggleButton
          v-model="checked"
          :onLabel="$t('rechargeCredit.automaticRechargeOn')"
          :offLabel="$t('rechargeCredit.automaticRechargeOff')"
          class="!p-2"
          :class="{
            '!border-green-500 !bg-green-500 hover:!border-green-400 hover:!bg-green-400': checked,
            '!border-gray-200 !bg-gray-200 hover:!border-gray-400 hover:!bg-gray-400': !checked,
          }"
          @change="changeStatus()"
          :pt-options="{ mergeProps: true }"
          :pt="{
            icon: 'hidden',
            input: 'absolute opacity-0 w-full h-full cursor-pointer inset-0 z-10',
            box: '!block',
          }"
        />
      </div>
      <div class="mt-5">
        <div class="mb-1 flex items-center">
          <label for="amount" class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('rechargeCredit.automaticRechargeAmount') }}</label>
          <div @click="openInformationDialog('amount')">
            <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
          </div>
        </div>
        <InputNumber class="" v-if="amount" name="amount" v-model="amount.value" mode="currency" :currency="amount.currency" :locale="userStore.getLanguageCode" :step="100" :maxFractionDigits="0" :pt-options="{ mergeProps: true }" />
      </div>
      <div class="mt-5">
        <div class="mb-1 flex items-center">
          <label for="limit" class="block text-sm font-bold leading-6 text-main first-letter:capitalize dark:text-gray-300 sm:text-base">{{ $t('rechargeCredit.automaticRechargeLimit') }}</label>
          <div @click="openInformationDialog('limit')">
            <QuestionMarkCircleIcon class="h-5 w-5 cursor-pointer text-gray-300 hover:text-main dark:text-gray-300"> </QuestionMarkCircleIcon>
          </div>
        </div>
        <InputNumber class="" v-if="limit" name="limit" v-model="limit.value" mode="currency" :currency="limit.currency" :locale="userStore.getLanguageCode" :step="100" :maxFractionDigits="0" :min="0" :pt-options="{ mergeProps: true }" />
      </div>
      <div class="ml-auto mr-0 mt-5 w-fit">
        <MainButton class="min-w-[12.5rem] hover:bg-mainhover" :hover="false" :text="$t('rechargeCredit.automaticRechargeSaveAndOn')" @click="saveOptions()"></MainButton>
      </div>
    </div>
  </div>
  <!-- INFORMATION DIALOG -->
  <Dialog v-model:visible="openInformation" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="informationDialogTitle"></h1>
    </template>
    <div class="flex flex-col items-center text-center">
      <p class="dialog-description mt-2 text-justify" v-html="informationDialogDescription"></p>
    </div>
    <template #footer> </template>
  </Dialog>

  <Dialog v-model:visible="visibleReallyOffDialog" :closable="false" modal :header="$t('rechargeCredit.automaticRechargeReallyOffHeader')" :style="{ width: '28rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <div class="px-5">
      <div>
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-200">
          <ExclamationTriangleIcon class="h-6 w-6 text-orange-700" aria-hidden="true" />
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              {{ $t('rechargeCredit.automaticRechargeReallyOffText') }}
            </p>
          </div>
        </div>
      </div>
      <fieldset class="my-4 border-y border-gray-200">
        <div class="divide-y divide-gray-200">
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="problemWithPaymentCard" class="font-medium text-gray-700">{{ $t('rechargeCredit.automaticRechargeReallyOffReason1') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input
                v-model="reallyOffDialogData.problemWithPaymentCard"
                id="problemWithPaymentCard"
                aria-describedby="problemWithPaymentCard"
                name="problemWithPaymentCard"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </div>
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="fastCreditSpend" class="font-medium text-gray-700">{{ $t('rechargeCredit.automaticRechargeReallyOffReason2') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input v-model="reallyOffDialogData.fastCreditSpend" id="fastCreditSpend" aria-describedby="fastCreditSpend" name="fastCreditSpend" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
            </div>
          </div>
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="worseCampaignPerformance" class="font-medium text-gray-700">{{ $t('rechargeCredit.automaticRechargeReallyOffReason3') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input
                v-model="reallyOffDialogData.worseCampaignPerformance"
                id="worseCampaignPerformance"
                aria-describedby="worseCampaignPerformance"
                name="worseCampaignPerformance"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </div>
          <div class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label for="notSafe" class="font-medium text-gray-700">{{ $t('rechargeCredit.automaticRechargeReallyOffReason4') }}</label>
            </div>
            <div class="ml-3 flex h-5 items-center">
              <input v-model="reallyOffDialogData.notSafe" id="notSafe" aria-describedby="notSafe" name="notSafe" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
            </div>
          </div>
        </div>
      </fieldset>
      <div>
        <label for="reason" class="block text-sm font-medium text-gray-700">{{ $t('campaigns.modalOffCampaingChannel.answer4') }}</label>
        <div class="mt-1">
          <textarea v-model="reallyOffDialogData.reason" rows="2" name="reason" id="reason" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="align-items-center justify-content-center inline-flex gap-2">
        <Button @click="reallyOff(false)" type="button" severity="success" :label="$t('campaigns.modalOffCampaingChannel.campaignOn')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
        <Button @click="reallyOff(true)" type="button" severity="warning" :label="$t('campaigns.modalOffCampaingChannel.campaignOff')" size="small" aria-haspopup="true" aria-controls="overlay_menu" />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/solid';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useBroadcastChannel } from '@vueuse/core';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import ToggleButton from 'primevue/togglebutton';
import { useToast } from 'primevue/usetoast';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ContactInfoEnum } from '@/enums/ContactInfoEnum';
import { CREDIT_CHECKOUT_TYPES } from '@/modules/rechargeCredit/enums/CreditCheckoutTypes';
import { getStripeRedirectURL } from '@/modules/rechargeCredit/utils/getStripeRedirectURL';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const gtm = useGtm();

const { t } = useI18n();
const toast = useToast();
const eshopsStore = useEshopsStore();
const loadingStore = useLoadingStore();
const userStore = useUserStore();

const amount = ref();
const limit = ref();
const checked = ref();
const isCardSet = ref(false);

const checkoutSetupRef = ref();
const stripeInstance = ref();
const checkout = ref();

const statusCallBackError = ref(false);
const openInformation = ref(false);
const informationDialogTitle = ref('');
const informationDialogDescription = ref('');

const visibleReallyOffDialog = ref(false);
const reallyOffDialogData = ref({
  problemWithPaymentCard: false,
  fastCreditSpend: false,
  worseCampaignPerformance: false,
  notSafe: false,
  reason: '',
});

const { post } = useBroadcastChannel({ name: 'credit' });

onMounted(async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');
  if (sessionId) {
    await getCheckoutStatus(sessionId);
  }
  if (!statusCallBackError.value) {
    await init();
  }
});

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await init();
  }
);

const init = async () => {
  await hasCardSet();
  if (isCardSet.value) {
    await getOptions();
  } else {
    await initCheckout();
  }
};

const initCheckout = async () => {
  loadingStore.updateLoading(true);
  try {
    const { data } = await api.clientRechargeCreditCreateSetup({
      countryBilling: eshopsStore.getSelectedEshop?.country ? eshopsStore.getSelectedEshop?.country : 'CZ',
      callbackUrl: getStripeRedirectURL(CREDIT_CHECKOUT_TYPES.AUTOMATIC_RECHARGE),
    });

    if (data.clientSecret === '') {
      handleError();
      return;
    }

    // Init Stripe instance
    if (!stripeInstance.value) {
      stripeInstance.value = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
        apiVersion: import.meta.env.VITE_STRIPE_API_VERSION,
      });
    }

    if (!stripeInstance.value) {
      handleError();
      return;
    }

    // Destroy old checkout
    if (checkout.value) {
      checkout.value.destroy();
    }

    checkout.value = await stripeInstance.value.initEmbeddedCheckout({
      clientSecret: data.clientSecret,
    });

    checkout.value.mount(checkoutSetupRef.value);
  } catch (error) {
    handleError();
  } finally {
    loadingStore.updateLoading(false);
  }
};
const hasCardSet = async () => {
  try {
    const { data } = await api.clientRechargeCreditHasSavedCard();
    isCardSet.value = data.hasCard ? data.hasCard : false;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

const getOptions = async () => {
  loadingStore.updateLoading(true);
  try {
    const { data } = await api.clientRechargeCreditGetAutomaticCreditRechargeOptions();
    checked.value = (await api.clientRechargeCreditGetAutomaticCreditRechargeStatus()).data.status;
    amount.value = data.amount;
    limit.value = data.limit;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const getCheckoutStatus = async (sessionId) => {
  try {
    const { data: checkoutStatus } = await api.clientRechargeCreditGetSessionStatus({
      sessionId: sessionId,
    });

    if (checkoutStatus.status === 'open') {
      statusCallBackError.value = true;
    } else {
      statusCallBackError.value = false;
      post(sessionId);
    }
  } catch (error) {
    console.warn(error);
    statusCallBackError.value = true;
  }
};

const saveOptions = async () => {
  loadingStore.updateLoading(true);
  try {
    await api.clientRechargeCreditSetAutomaticCreditRechargeOptions({
      amount: amount.value.value,
      limit: limit.value.value,
    });
    await api.clientRechargeCreditSetAutomaticCreditRechargeStatus({
      desiredStatus: true,
    });
    checked.value = true;
    toast.add({
      severity: 'success',
      summary: t('rechargeCredit.automaticRechargeSuccessChange'),
      detail: '',
      life: 5000,
    });
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 10000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const changeStatus = async () => {
  if (!checked.value) {
    visibleReallyOffDialog.value = true;
    return;
  }
  loadingStore.updateLoading(true);
  try {
    await api.clientRechargeCreditSetAutomaticCreditRechargeStatus({
      desiredStatus: checked.value,
    });
    if (checked.value) {
      toast.add({
        severity: 'success',
        summary: t('rechargeCredit.automaticRechargeSuccess'),
        detail: '',
        life: 5000,
      });
    } else {
      toast.add({
        severity: 'success',
        summary: t('rechargeCredit.automaticRechargeSuccessStop'),
        detail: '',
        life: 5000,
      });
    }
  } catch (error: any) {
    checked.value = !checked.value;
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 10000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const reallyOff = async (reallyOff) => {
  if (reallyOff) {
    loadingStore.updateLoading(true);
    try {
      await api.clientRechargeCreditSetAutomaticCreditRechargeStatus({
        desiredStatus: checked.value,
      });
      gtm?.trackEvent({
        event: 'gtm.reasonOffAutomaticCreditRecharge',
        type: 'PRX',
        value: {
          reason: reallyOffDialogData,
          eshopId: eshopsStore.selectedEshop.id,
          domainName: eshopsStore.selectedEshop.domainName,
        },
      });

      toast.add({
        severity: 'success',
        summary: t('rechargeCredit.automaticRechargeSuccessStop'),
        detail: '',
        life: 5000,
      });
    } catch (error: any) {
      if (error.response) {
        if (error.response.data.status >= 500) {
          toast.add({
            severity: 'error',
            summary: t('serverErrorTitle'),
            detail: error.response.data.requestId,
            life: 20000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: error.response.data.detail,
            life: 10000,
          });
        }
      } else {
        console.warn(error);
      }
    } finally {
      loadingStore.updateLoading(false);
    }
  } else {
    checked.value = !checked.value;
  }
  visibleReallyOffDialog.value = false;
};

const handleError = () => {
  toast.add({
    severity: 'error',
    summary: t('rechargeCredit.errorCheckoutTitle'),
    detail: '',
    life: 5000,
  });
};

const openInformationDialog = (type) => {
  if (type === 'amount') {
    informationDialogTitle.value = t('rechargeCredit.automaticRechargeAmount');
    informationDialogDescription.value = t('rechargeCredit.automaticRechargeAmountDescription');
  } else if (type === 'limit') {
    informationDialogTitle.value = t('rechargeCredit.automaticRechargeLimit');
    informationDialogDescription.value = t('rechargeCredit.automaticRechargeLimitDescription');
  }
  openInformation.value = true;
};

onUnmounted(() => {
  if (checkout.value) {
    checkout.value.destroy();
  }
});
</script>
